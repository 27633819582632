import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";

interface IProps {
  status: string;
}

const Status: FC<IProps> = ({ status }) => {
  const { t } = useTranslation();
  switch (status) {
    case "new":
      return <Tag color="blue">{t("New")}</Tag>;
    case "pending":
      return <Tag color="warning">{t("Pending")}</Tag>;
    case "completed":
    case "complete":
      return <Tag color="success">{t("Completed")}</Tag>;
    case "active":
      return <Tag color="success">{t("Active")}</Tag>;
    case "inactive":
      return <Tag color="error">{t("Inactive")}</Tag>;
    case "expired":
      return <Tag color="error">{t("Expired")}</Tag>;
    case "reject":
      return <Tag color="error">{t("Rejected")}</Tag>;
    case "accept":
      return <Tag color="success">{t("Accepted")}</Tag>;
    default:
      return <Tag color="green">{status}</Tag>;
  }
};

export default Status;
