import { Loader } from "@/components/ui";
import { Col, Row } from "antd";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  children: React.ReactNode;
  extra?: React.ReactNode;
  loading?: boolean;
}

const Page: FC<Props> = ({ title, children, extra, loading = false }) => {
  const { t } = useTranslation();

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title={t(title)} />
      <Row justify="space-between" className="mb-10">
        <Col>
          <h2>{t(title)}</h2>
        </Col>
        <Col>{extra}</Col>
      </Row>
      <div className="card">
        <div className="card-body">{children}</div>
      </div>
    </>
  );
};

export default Page;
