import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Popconfirm, Row, Tooltip } from "antd";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import Loader from "../Loader";
import { NoteDto } from "@models";
import { useDispatch } from "react-redux";
import * as RULES from "@constants/validation";

import styles from "./styles.module.scss";
import { Modal } from "@/components/common";

interface Props {
  getinProgress: boolean;
  addinProgress: boolean;
  updateinProgress: boolean;
  notes: NoteDto[];
  get: any;
  add: any;
  edit: any;
  deleteNote: any;
  id: number;
  idKey: string;
}

const Notes: FC<Props> = ({
  getinProgress,
  addinProgress,
  updateinProgress,
  notes,
  get,
  add,
  edit,
  deleteNote,
  id,
  idKey,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [note, setNote] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const showModal = (item: object) => {
    setNote(item);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    const formData = new FormData();
    formData.append("notes", note?.notes);

    await dispatch(
      edit({
        id: note?.id,
        data: formData,
        callback: () => {
          dispatch(
            get({
              data: { [idKey]: id },
            })
          );
          setIsModalVisible(false);
        },
      })
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onAddNote = async (values: { notes: string }) => {
    const formData = new FormData();
    formData.append(idKey, `${id}`);
    formData.append("notes", values.notes);

    await dispatch(
      add({
        data: formData,
        callback: () => {
          form.resetFields();
          dispatch(
            get({
              data: { [idKey]: id },
            })
          );
        },
      })
    );
  };

  return (
    <div className="card">
      <div className="card-head">
        <h3 className="card-title">{t("Notes")}</h3>
      </div>
      <div className="card-body pa-15">
        {getinProgress && <Loader isCard />}
        {notes?.map((item: any) => (
          <Row key={item.id} className="mb-15" gutter={10}>
            <Col lg={2}></Col>
            <Col lg={20}>
              <div className={`bg-gray pa-10 ${styles.text}`}>{item.notes}</div>
            </Col>
            <Col lg={2}>
              <div className="bg-gray pa-10 d-inline-flex flex-column align-items-center">
                <Tooltip placement="right" title={t("Edit")}>
                  <Button
                    onClick={() => showModal(item)}
                    className="border-0 link-primary"
                    icon={<FormOutlined />}
                  />
                </Tooltip>
                <Tooltip placement="right" title={t("Delete")}>
                  <Popconfirm
                    placement="right"
                    title={t("AreYouSureYouWantToDelete?")}
                    onConfirm={() =>
                      new Promise((resolve) => {
                        dispatch(
                          deleteNote({
                            data: item.id,
                            callback: () => {
                              dispatch(
                                get({
                                  data: { [idKey]: id },
                                })
                              );
                              resolve(true);
                            },
                          })
                        );
                      })
                    }
                  >
                    <Button
                      className="border-0 btn-delete"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                </Tooltip>
              </div>
            </Col>
          </Row>
        ))}
        <Form
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
          onFinish={onAddNote}
          form={form}
        >
          <Form.Item label={t("Note")} name="notes" rules={RULES.Note}>
            <Input.TextArea size="large" placeholder={t("WriteHere...")} />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 4 }} className="mx-auto">
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={addinProgress}
            >
              {t("AddNote")}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        title={t("UpdateNote")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t("Save")}
        okButtonProps={{ loading: updateinProgress }}
      >
        <Input.TextArea
          size="large"
          placeholder={t("WriteHere...")}
          value={note?.notes}
          onChange={(e) => setNote({ ...note, notes: e.target.value })}
        />
      </Modal>
    </div>
  );
};

export default Notes;
