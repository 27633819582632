import { RouteDto } from "@models";

// query ancestor to get the parent from child
export function queryAncestors(
  array: RouteDto[],
  current: RouteDto,
  parentId: string,
  id: string = "id"
) {
  const result = [current];
  const hashMap = new Map();
  array.forEach((item: any) => hashMap.set(item[id], item));

  const getPath = (current: typeof array[number]) => {
    const currentParentId = hashMap.get(
      current[id as keyof typeof array[number]]
    )[parentId];
    if (currentParentId) {
      result.push(hashMap.get(currentParentId));
      getPath(hashMap.get(currentParentId));
    }
  };

  getPath(current);
  return result;
}
