import { API_ENDPOINTS } from "@constants";
import { RequestPayloadDto } from "@models";
import { axiosClient } from "@constants";
import { loadingActions } from "@store/loading/loadingSlice";
import { Modal } from "antd";
import { AxiosResponse } from "axios";
import { t } from "i18next";
import { all, call, Effect, put, takeLatest } from "redux-saga/effects";
import { reportActions } from "./reportSlice";

const { ownerManagement, ownerManagementSuccess } = reportActions;

// Get Owner Management Report
function* ownerManagementSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const res: AxiosResponse = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.reports.ownerManagement,
      { params: payload.params }
    );
    console.log("🚀 ~ file: reportSaga.ts ~ line 24 ~ res", res);

    yield put(ownerManagementSuccess({ ...res.data }));

    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

export default function* reportSaga() {
  yield all([takeLatest(ownerManagement.type, ownerManagementSaga)]);
}
