import { useEffect } from "react";
import useDeepCompareMemoize from "./useDeepCompareMemoize";

const useDeepCompareEffectForMaps = (
  callback: React.EffectCallback,
  dependencies: any[]
) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

export default useDeepCompareEffectForMaps;
