import { StrictEffect, all, takeLatest } from "redux-saga/effects";

import {
  allPreviewRequests,
  allPreviewRequestsSuccess,
  deletePreviewRequest,
  selectTime,
  previewRequestDetails,
  previewRequestDetailsSuccess,
  updatePreviewRequest,
} from "./previewSlice";
import { request } from "@/utils";
import { API_ENDPOINTS } from "@/constants";

const allPreviewRequestsSaga = request({
  APIUrl: API_ENDPOINTS.GET.REQUESTS.ALL_PREVIEW_REQUESTS,
  requestType: "get",
  successFunction: allPreviewRequestsSuccess,
});

const deletePreviewRequestSaga = request({
  APIUrl: API_ENDPOINTS.POST.REQUESTS.DELETE_PREVIEW_REQUEST,
  requestType: "post",
  showNotification: true,
});

const selectTimeSaga = request({
  APIUrl: API_ENDPOINTS.POST.REQUESTS.SELECT_TIME,
  requestType: "post",
  showNotification: true,
});

const previewRequestDetailsSaga = request({
  APIUrl: API_ENDPOINTS.GET.REQUESTS.PREVIEW_REQUESTS_BY_ID,
  requestType: "get",
  successFunction: previewRequestDetailsSuccess,
});

const updatePreviewRequestSaga = request({
  APIUrl: API_ENDPOINTS.POST.REQUESTS.UPDATE_PREVIEW_REQUEST,
  requestType: "post",
  showNotification: true,
});

export default function* previewSaga(): Generator<StrictEffect> {
  yield all([
    takeLatest(allPreviewRequests.type, allPreviewRequestsSaga),
    takeLatest(deletePreviewRequest.type, deletePreviewRequestSaga),
    takeLatest(selectTime.type, selectTimeSaga),
    takeLatest(previewRequestDetails.type, previewRequestDetailsSaga),
    takeLatest(updatePreviewRequest.type, updatePreviewRequestSaga),
  ]);
}
