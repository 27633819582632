import { API_ENDPOINTS } from "@constants";
import { MainRequestDto, RequestPayloadDto, SuccessPayload } from "@models";
import { BillDto } from "@models/bills";
import { axiosClient } from "@constants";
import { loadingActions } from "@store/loading/loadingSlice";
import { Modal, notification } from "antd";
import { AxiosResponse } from "axios";
import { t } from "i18next";
import { all, call, Effect, put, takeLatest } from "redux-saga/effects";
import { billActions } from "./billSlice";

const {
  addBill,
  getAllBills,
  getAllBillsSuccess,
  deleteBill,
  getSingleBill,
  getSingleBillSuccess,
} = billActions;

// Add Bill Saga
function* addBillSaga({ type, payload }: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(axiosClient.post, API_ENDPOINTS.POST.expense.add, payload.data);

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });

    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get All Specefic Bill Saga
function* getAllBillsSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const res: AxiosResponse<SuccessPayload<BillDto>> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.expense.all,
      {
        params: {
          ...payload.params,
        },
      }
    );

    yield put(getAllBillsSuccess({ ...res.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Single Bill Saga
function* getSingleBillSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const res: AxiosResponse<BillDto> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.expense.single(Number(payload.data))
    );

    yield put(getSingleBillSuccess({ ...res.data }));

    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Delete Single Bill Saga
function* deleteBillSaga({ type, payload }: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.expense.delete(Number(payload.data))
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });

    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

export default function* billSaga() {
  yield all([
    takeLatest(addBill.type, addBillSaga),
    takeLatest(getAllBills.type, getAllBillsSaga),
    takeLatest(getSingleBill.type, getSingleBillSaga),
    takeLatest(deleteBill.type, deleteBillSaga),
  ]);
}
