import { FC } from "react";
import { Menu, Dropdown, Button } from "antd";
import i18next from "i18next";
import { SaFlag, EnFlag } from "@components/common";

import styles from "./index.module.scss";

export interface MenuInfo {
  key: string;
  keyPath: string[];
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}
const LangDropDown: FC = () => {
  function handleMenuClick(e: MenuInfo) {
    i18next.changeLanguage(e.key);
    window.location.reload();
  }

  const langItems = [
    {
      label: "العربية",
      key: "ar",
      icon: <SaFlag />,
      disabled: i18next.language === "ar",
    },
    {
      label: "English",
      key: "en",
      icon: <EnFlag />,
      disabled: i18next.language === "en",
    },
  ];

  const langMenu = <Menu onClick={handleMenuClick} items={langItems} />;

  const selectedLang = langItems.find((item) => item.key === i18next.language);

  return (
    <Dropdown
      overlayClassName={styles.langMenu}
      placement="bottomLeft"
      overlay={langMenu}
    >
      <Button className={`${styles.btn}`}>{selectedLang?.icon}</Button>
    </Dropdown>
  );
};

export default LangDropDown;
