import { useEffect, FC } from "react";
import { Spin } from "antd";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import styles from "./styles.module.scss";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const Loading = ({ isCard }: { isCard?: boolean }): JSX.Element => {
  useEffect(() => {
    NProgress.start();
    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div
      className={`${styles.loader} ${
        isCard && styles["card-style"]
      } app-container`}
    >
      <Spin />
    </div>
  );
};

export default Loading;
