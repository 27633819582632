import i18n from "i18next";
import moment from "moment";

import { localization } from "./localization";

export const TimingHelper = {
  defaultDateTimeFormat: "D/MM/YYYY - h:mm A",
  defaultTimeFormat: "hh:mm A",
  defaultDateFormat: "YYYY-MM-DD",
  backendDateFormat: "YYYY-MM-DD",

  initTiming: () =>
    moment.updateLocale(i18n.language, localization.getMomentLocals()),

  getRelativeTime: (date: string) =>
    moment(date).isBefore(moment().add(-12, "hours"))
      ? moment(date).fromNow()
      : moment(date).format(TimingHelper.defaultTimeFormat),
};
