import { FC } from "react";
import { useLocation, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { queryAncestors } from "@utils";
import { routesList } from "@constants";
import { pathToRegexp } from "path-to-regexp";
import s from "./index.module.scss";
import i18next, { t } from "i18next";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const generateBreadcrumbs: FC = (paths: any) => {
  return paths.map((item: any, key: any) => {
    const content = item && <>{t(item.title)}</>;
    return (
      item && (
        <Breadcrumb.Item key={key}>
          {paths.length - 1 !== key ? (
            <Link to={item.path || "#"}>{content}</Link>
          ) : (
            content
          )}
        </Breadcrumb.Item>
      )
    );
  });
};

const BreadCrumb: FC = () => {
  const location = useLocation();
  const breadCrumbView = () => {
    const { pathname } = location;
    // Find the current route that match the current path name
    const currentRoute = routesList.find(
      (_) => _.path && pathToRegexp(_.path).exec(pathname)
    );
    // get paths
    const paths = currentRoute
      ? queryAncestors(routesList, currentRoute, "breadcrumbParentId").reverse()
      : [
          routesList[0],
          {
            id: 404,
            title: t("NotFound"),
          },
        ];
    return (
      <div className={s.breadContainer}>
        <Breadcrumb
          separator={
            i18next.language === "ar" ? <LeftOutlined /> : <RightOutlined />
          }
        >
          {generateBreadcrumbs(paths)}
        </Breadcrumb>
      </div>
    );
  };
  return <>{breadCrumbView()}</>;
};
export default BreadCrumb;
