import { FC, useState } from "react";
import { Input } from "antd";

import Button from "../Button";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

interface CounterProps {
  value: number;
  increment: (number: number) => void;
  decrement: (number: number) => void;
}

const Counter: FC<CounterProps> = ({ decrement, increment, value }) => {
  const [number, setNumber] = useState<number>(value || 0);

  return (
    <div className="qty">
      <div className={`qtyInput ${number > 0 && "bg-primary"}`}>
        <Button
          onClick={() =>
            setNumber((prevState: number) => {
              const newState = prevState + 1;
              increment(newState);
              return newState;
            })
          }
        >
          <PlusOutlined />
        </Button>
        <Input value={number} />
        <Button
          onClick={() =>
            setNumber((prevState: number) => {
              if (prevState > 0) {
                const newState = prevState - 1;
                decrement(newState);
                return newState;
              }
              return prevState;
            })
          }
        >
          <MinusOutlined />
        </Button>
      </div>
    </div>
  );
};

export default Counter;
