import { Button, Modal } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

interface IProps {
  btnText?: string;
  btnIcon?: any;
  callback: any;
  isLoading?: boolean;
}

const ShowDeleteConfirm = ({
  btnText,
  btnIcon,
  callback,
  isLoading,
}: IProps): JSX.Element => {
  const { t } = useTranslation();

  const showPromiseConfirm = () => {
    confirm({
      title: `${t("AreYouSureYouWantToDelete?")}`,
      content: `${t("ThisProcessCannotBeUndone")}`,
      okType: "danger",
      icon: <ExclamationCircleOutlined />,
      onOk: () => new Promise((resolve) => resolve(callback())),
      onCancel() {},
    });
  };

  return (
    <Button
      className="mx-5"
      type="primary"
      icon={btnIcon || <DeleteOutlined />}
      danger
      onClick={showPromiseConfirm}
      loading={isLoading}
    >
      {btnText || t("Delete")}
    </Button>
  );
};

export default ShowDeleteConfirm;
