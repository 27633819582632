import { RequestPayloadDto, SuccessPayload } from "@models";
import { CatchBondDto } from "@models/catchBond";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateDto {
  allCatchBond?: SuccessPayload<CatchBondDto>;
  singleCatchBond?: CatchBondDto;
}

const initialState: initialStateDto = {};

const catchBondSlice = createSlice({
  name: "catchBond",
  initialState,
  reducers: {
    // Add Catch Bond Reducer
    addCatchBond(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Get All Catch Bond Reducer
    getAllCatchBond(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getAllCatchBondSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<CatchBondDto>>
    ) {
      state.allCatchBond = action.payload;
    },
    // Get Single Catch Bond Reducer
    getSingleCatchBond(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getSingleCatchBonduccess(
      state: initialStateDto,
      action: PayloadAction<CatchBondDto>
    ) {
      state.singleCatchBond = action.payload;
    },
    // Delete Catch Bond Reducer
    deleteCatchBond(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
  },
});

// Catch Bond Actions
export const catchBondActions = catchBondSlice.actions;

// Catchw Bond Reducer
const catchBondReducer = catchBondSlice.reducer;

export default catchBondReducer;
