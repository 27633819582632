import { FC, useState } from "react";
import { Card, Space, Typography } from "antd";

import { Button } from "@/components/common";
import { PlanDto } from "@/pages/Plans/planSlice";

import styles from "./styles.module.scss";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

interface PlanCardProps {
  plan?: PlanDto;
  handlePlanClick: ({
    id,
    count,
    index,
  }: {
    id: number;
    count: number;
    index: number;
  }) => void;
  isLoading?: boolean;
  index: number;
  loadingIndex: number | null;
}

const PlanCard: FC<PlanCardProps> = ({
  plan,
  handlePlanClick,
  isLoading,
  index,
  loadingIndex,
}) => {
  const [contractNumber, setContractNumber] = useState(
    plan?.contract_number || 1
  );

  return (
    <>
      <Card
        actions={[
          <Button
            type="primary"
            size="small"
            block
            className={styles.planCard__action}
            style={{
              backgroundColor: plan?.color,
              borderColor: plan?.color,
            }}
            onClick={() =>
              handlePlanClick({ id: plan?.id!, count: contractNumber, index })
            }
            loading={loadingIndex === index && isLoading}
          >
            الشراء الآن
          </Button>,
        ]}
        className={styles.planCard}
      >
        <div>
          <Typography.Title
            level={4}
            className={styles.planCard__title}
            style={{
              color: plan?.color,
            }}
          >
            {plan?.name}
          </Typography.Title>
        </div>

        <div className={styles.planCard__content}>
          {plan?.type !== "dynamic" && (
            <div className={styles.planCard__content__item}>
              {plan?.contract_number === 1 ? (
                <Typography.Text>عقد واحد فقط</Typography.Text>
              ) : (
                <Typography.Text>
                  {`${plan?.contract_number}
                عقد
                `}
                </Typography.Text>
              )}
            </div>
          )}

          {plan?.type === "dynamic" && (
            <div className={styles.planCard__content__counter}>
              <Space align="center" size={20}>
                <Button
                  type="link"
                  onClick={() =>
                    setContractNumber((prevState) => prevState + 1)
                  }
                  icon={<PlusOutlined />}
                />

                <div>
                  <Typography.Text>{contractNumber}</Typography.Text>
                </div>

                <Button
                  type="link"
                  onClick={() =>
                    setContractNumber(
                      contractNumber === 1 ? 1 : contractNumber - 1
                    )
                  }
                  icon={<MinusOutlined />}
                />
              </Space>
            </div>
          )}

          <div className={styles.planCard__content__price}>
            <Typography.Text
              className={styles.planCard__content__price__value}
              style={{
                color: plan?.color,
              }}
            >
              {plan?.type === "dynamic"
                ? plan?.price * contractNumber
                : plan?.price}
            </Typography.Text>
            <Typography.Text
              className={styles.planCard__content__price__currency}
            >
              ريال
            </Typography.Text>
          </div>
        </div>
      </Card>
      <Typography.Paragraph
        type="secondary"
        className={styles.planCard__description}
      >
        {`
        صالح لمدة ${plan?.duration} ${10 <= plan?.duration! ? "شهر" : "شهور"}
        `}
      </Typography.Paragraph>
    </>
  );
};

export default PlanCard;
