import { API_ENDPOINTS } from "@constants";
import { loadingActions } from "@store/loading/loadingSlice";
import { axiosClient } from "@constants";
import { AxiosResponse } from "axios";
import {
  all,
  call,
  Effect,
  put,
  StrictEffect,
  takeLatest,
} from "redux-saga/effects";
import { settingAction } from "./settingSlice";
import { RequestPayloadDto } from "@models";
import { Modal } from "antd";
import { t } from "i18next";
import { request } from "@/utils";

const {
  getBanks,
  getBanksSuccess,
  comfort,
  comfortSuccess,
  getEstateType,
  getEstateTypeSuccess,
  getOperationType,
  getOperationTypeSuccess,
  getCustomerByType,
  getCustomerByTypeSuccess,
  getGoogleMapAddress,
  getGoogleMapAddressSuccess,
  getEstates,
  getEstatesSuccess,
  getGroupEstates,
  getGroupEstatesSuccess,
  getContracts,
  getContractsSuccess,
  getEmployees,
  getEmployeesSuccess,
  getContractExpense,
  getContractExpenseSuccess,
  getContractInvoice,
  getContractInvoiceSuccess,
  getDashboard,
  getDashboardSuccess,
  getCities,
  getCitiesSuccess,
  getDistricts,
  getDistrictsSuccess,
  getRegions,
  getRegionsSuccess,
  refreshFirebaseToken,
  getNewCities,
  getNewCitiesSuccess,
  getNewDistricts,
  getNewDistrictsSuccess,
  getNewRegions,
  getNewRegionsSuccess,
  getPropertyAge,
  getPropertyAgeSuccess,
  getPropertyFace,
  getPropertyFaceSuccess,
  getPropertyUsages,
  getPropertyUsagesSuccess,
  iamAuthenticate,
} = settingAction;

// Get ALl Banks Saga
function* getBanksSaga({ type }: Effect) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<typeof getBanks> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.settings.banks
    );

    yield put(
      getBanksSuccess({
        ...response?.data,
      })
    );
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get ALl Estate Types Saga
function* getEstateTypeSaga({ type }: Effect) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );
    const response: AxiosResponse<typeof getEstateType> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.settings.estateType
    );

    yield put(
      getEstateTypeSuccess({
        ...response?.data,
      })
    );
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Operations Types Saga
function* getOperationTypeSaga({ type }: Effect) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );
    const response: AxiosResponse<typeof getOperationType> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.settings.advertisement_type
    );

    yield put(
      getOperationTypeSuccess({
        ...response?.data,
      })
    );
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Comfort Saga
function* comfortSaga({ type }: Effect) {
  console.log(
    "🚀 ~ file: settingSaga.ts ~ line 94 ~ function*comfortSaga ~ type",
    type
  );
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );
    const response: AxiosResponse<typeof comfort> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.settings.comfort
    );

    yield put(
      comfortSuccess({
        ...response?.data,
      })
    );
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Client By Type Saga
function* getCustomerByTypeSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.settings.customerByType,
      {
        params: {
          ...Object(payload.data),
        },
      }
    );
    yield put(getCustomerByTypeSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Find Google Map Address After Marking the map
function* getGoogleAddressSaga({ type, payload }: Effect<string>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.settings.googleAddress(payload)
    );
    yield put(getGoogleMapAddressSuccess({ ...response }));
  } catch (error) {
    // Modal.error({
    //   title: `${t("SomethingWentWrong")}`,
    //   content: (error as Error)?.message,
    // });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Estates Depend On Type (pay or tent)
function* getEstatesSaga({ type, payload }: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.settings.estates,
      { params: { ...payload?.params } }
    );
    yield put(getEstatesSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Group Estates Select
function* getGroupEstatesSaga({ type }: Effect<string>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.settings.groupEstates
    );
    yield put(getGroupEstatesSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Contracts Select
function* getContractsSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.settings.contractsSelect
    );
    yield put(getContractsSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Employees Select
function* getEmployeesSaga({ type }: Effect<string>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.settings.employeesSelect
    );
    yield put(getEmployeesSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Contract Expense Select
function* getContractExpenseSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.settings.contractExpenseSelect,
      { params: { ...payload.params } }
    );
    yield put(getContractExpenseSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Contract Invoice Select
function* getContractInvoiceSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.settings.contractInvoiceSelect,
      { params: { ...payload.params } }
    );
    yield put(getContractInvoiceSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Dashboard Data
function* getDashboardDataSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.settings.dashboard,
      { params: { ...payload?.params } }
    );
    yield put(getDashboardSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

/********************************** New Regions, Cities, and Districts APIs **********************************/
const getNewCitiesSaga = request({
  APIUrl: API_ENDPOINTS.GET.settings.city_by_region,
  successFunction: getNewCitiesSuccess,
  requestType: "get",
});

const getNewDistrictsSaga = request({
  APIUrl: API_ENDPOINTS.GET.settings.districts_by_city,
  successFunction: getNewDistrictsSuccess,
  requestType: "get",
});

const getNewRegionsSaga = request({
  APIUrl: API_ENDPOINTS.GET.settings.regions,
  successFunction: getNewRegionsSuccess,
  requestType: "get",
});

const getPropertyFaceSaga = request({
  APIUrl: API_ENDPOINTS.GET.settings.property_face,
  successFunction: getPropertyFaceSuccess,
  requestType: "get",
});

const getPropertyAgeSaga = request({
  APIUrl: API_ENDPOINTS.GET.settings.property_age,
  successFunction: getPropertyAgeSuccess,
  requestType: "get",
});

const getPropertyUsagesSaga = request({
  APIUrl: API_ENDPOINTS.GET.settings.property_usages,
  successFunction: getPropertyUsagesSuccess,
  requestType: "get",
});

/********************************** Ejar APIs **********************************/
const getCitiesSaga = request({
  APIUrl: API_ENDPOINTS.GET.settings.EJAR.CITIES,
  successFunction: getCitiesSuccess,
  requestType: "get",
});

const getDistrictsSaga = request({
  APIUrl: API_ENDPOINTS.GET.settings.EJAR.DISTRICTS,
  successFunction: getDistrictsSuccess,
  requestType: "get",
});

const getRegionsSaga = request({
  APIUrl: API_ENDPOINTS.GET.settings.EJAR.REGIONS,
  successFunction: getRegionsSuccess,
  requestType: "get",
});

const refreshFirebaseTokenSaga = request({
  APIUrl: API_ENDPOINTS.POST.settings.refreshFirebaseToken,
  requestType: "post",
});

// Iam Authenticate
const iamAuthenticateSaga = request({
  APIUrl: API_ENDPOINTS.GET.settings.IamLogin,
  requestType: "get",
});

export default function* settingSaga(): Generator<StrictEffect> {
  yield all([
    takeLatest(getBanks.type, getBanksSaga),
    takeLatest(getEstateType.type, getEstateTypeSaga),
    takeLatest(getOperationType.type, getOperationTypeSaga),
    takeLatest(comfort.type, comfortSaga),
    takeLatest(getCustomerByType.type, getCustomerByTypeSaga),
    takeLatest(getGoogleMapAddress.type, getGoogleAddressSaga),
    takeLatest(getEstates.type, getEstatesSaga),
    takeLatest(getGroupEstates.type, getGroupEstatesSaga),
    takeLatest(getContracts.type, getContractsSaga),
    takeLatest(getEmployees.type, getEmployeesSaga),
    takeLatest(getContractExpense.type, getContractExpenseSaga),
    takeLatest(getContractInvoice.type, getContractInvoiceSaga),
    takeLatest(getDashboard.type, getDashboardDataSaga),
    // New Regions, Cities, and Districts APIs
    takeLatest(getNewCities.type, getNewCitiesSaga),
    takeLatest(getNewDistricts.type, getNewDistrictsSaga),
    takeLatest(getNewRegions.type, getNewRegionsSaga),

    takeLatest(getPropertyFace.type, getPropertyFaceSaga),
    takeLatest(getPropertyAge.type, getPropertyAgeSaga),
    takeLatest(getPropertyUsages.type, getPropertyUsagesSaga),

    // Ejar Settings
    takeLatest(getCities.type, getCitiesSaga),
    takeLatest(getDistricts.type, getDistrictsSaga),
    takeLatest(getRegions.type, getRegionsSaga),
    // Firebase
    takeLatest(refreshFirebaseToken.type, refreshFirebaseTokenSaga),
    // Iam Authenticate
    takeLatest(iamAuthenticate.type, iamAuthenticateSaga),
  ]);
}
