import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Typography } from "antd";

import { Image } from "@/components/common";
import { RealEstateDto } from "@/models";

import styles from "./styles.module.scss";

interface Props {
  item: RealEstateDto;
}

const HorizontalEstateCard: FC<Props> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]} className={styles.container}>
      <Col span={6} className={styles.imageContainer}>
        <Image
          src={item?.first_image}
          alt={item?.estate_type_name}
          style={{ height: 100 }}
        />
      </Col>

      <Col span={18}>
        <Row gutter={[16, 5]}>
          <Col span={24}>
            <Typography.Text type="secondary">
              {item?.estate_type_name} , {item?.operation_type_name}
            </Typography.Text>
          </Col>

          <Col span={24}>
            <Typography.Text strong>
              {`${item?.total_price} ${t("SAR")}`}
            </Typography.Text>
          </Col>

          <Col span={24}>
            <Typography.Text type="secondary">
              {item?.full_address}
            </Typography.Text>
          </Col>

          <Col span={24}>
            <Row gutter={[16, 3]}>
              {item?.include_number.map((number) => (
                <Col
                  key={`
                ${number?.img} 
                `}
                >
                  <Row gutter={[2, 0]}>
                    <Col>
                      <Image
                        src={number?.img}
                        alt="bed"
                        preview={false}
                        width={20}
                        className={styles.icon}
                      />
                    </Col>
                    <Col>
                      <Typography.Text type="secondary" strong>
                        {number.count}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HorizontalEstateCard;
