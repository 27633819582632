import { FC, useEffect } from "react";
import moment from "moment";
import { size } from "lodash";
import { Col, DatePicker, Form, Input, Row, Select, Typography } from "antd";

import { useAppParams } from "@hooks";
import { TimingHelper } from "@utils";

import styles from "./index.module.scss";

const { Option } = Select;

interface FilterInputProps {
  value: any;
  label: string;
  name: string;
  placeHolder?: string;
  visible?: boolean;
  options?: any[];
  optionValueName?: string;
  optionName?: string;
  inputType?: "single" | "multi" | "date";
  disabled?: boolean;
}

const FilterInput: FC<FilterInputProps> = ({
  label,
  name,
  value,
  disabled,
  inputType,
  optionName,
  optionValueName,
  options,
  placeHolder,
}) => {
  const [form] = Form.useForm();
  const { handleSearch, UrlParams } = useAppParams({});

  useEffect(() => {
    if (size(UrlParams) === 0) {
      form.resetFields();
    }
  }, [UrlParams, form]);

  const getInputType = () => {
    switch (inputType) {
      case "multi":
        return (
          <Select
            disabled={disabled}
            allowClear
            onClear={() =>
              handleSearch({
                deleteFields: [name],
              })
            }
            placeholder={placeHolder}
            defaultValue={isNaN(Number(value)) ? value : Number(value)}
            onChange={(val) =>
              handleSearch({
                fields: {
                  [name]: val,
                },
              })
            }
            style={{ width: "100%" }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children as unknown as string)?.includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.children as unknown as string)
                ?.toLowerCase()
                ?.localeCompare(
                  (optionB?.children as unknown as string)?.toLowerCase()
                )
            }
          >
            {options?.map((option: any) => (
              <Option
                key={option[optionValueName as string]}
                value={option[optionValueName as string]}
              >
                {option[optionName as string]}
              </Option>
            ))}
          </Select>
        );
      case "date":
        // Date filter
        return (
          <DatePicker
            style={{ width: "100%" }}
            disabled={disabled}
            allowClear
            onChange={(_val, dateString: string | string[] | null) => {
              if (moment(dateString as unknown as string).isValid()) {
                return handleSearch({
                  fields: {
                    [name]: moment(dateString as unknown as string).format(
                      TimingHelper.backendDateFormat
                    ),
                  },
                });
              }
              return handleSearch({
                deleteFields: [name],
              });
            }}
            // defaultValue={moment(value)}
            placeholder={placeHolder}
          />
        );

      default:
        // TODO: fix ref
        // Input filter Text
        return (
          <Input
            disabled={disabled}
            // ref={inputRef}
            placeholder={placeHolder}
            defaultValue={value}
            onPressEnter={(e) =>
              handleSearch({
                fields: {
                  [name]: e.currentTarget.value,
                },
              })
            }
            onChange={(e) => {
              if (!e.target.value) {
                return handleSearch({
                  deleteFields: [name],
                });
              }
            }}
            allowClear
          />
        );
    }
  };

  return (
    <Row gutter={30}>
      <Col span={24}>
        <Typography.Text className={styles.label}>{label}</Typography.Text>
      </Col>
      <Form
        form={form}
        style={{
          width: "100%",
        }}
        autoComplete="off"
      >
        <Col span={24}>
          <Form.Item name={name}>{getInputType()}</Form.Item>
        </Col>
      </Form>
    </Row>
  );
};

export default FilterInput;
