import { lazy, Suspense, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ConfigProvider, notification } from "antd";
import arEG from "antd/lib/locale/ar_EG";
import enUS from "antd/lib/locale/en_US";

import { ROUTE, NOT_FOUND, AUTH_ROUTE, RouteDto } from "@models";
import { Loader } from "@components/ui";
import Layout from "@components/Layout";
import { RequireAuth } from "@components/common";
import { useAuth } from "@hooks";
import { routesList } from "@constants";
import { helmetConfig, firebase } from "@lib";
import i18n from "./i18n";
import { CookiesHelper } from "@utils/CookiesHelper";
import { theme, TimingHelper } from "./utils";

import "antd/dist/reset.css";
import "./styles/main.scss";

const HomePage = lazy(() => import("@pages/Home"));
const NotFound = lazy(() => import("@pages/NotFound"));
const Login = lazy(() => import("@pages/User/Login"));
const UnAuthorized = lazy(() => import("@pages/UnAuthorized"));

function App() {
  const user = useAuth();
  const currentLanguage = CookiesHelper.get("i18next") || "ar";
  useEffect(() => {
    document.body.dir = currentLanguage === "en" ? "ltr" : "rtl";
    document.documentElement.setAttribute("lang", currentLanguage);

    TimingHelper.initTiming();
  }, [currentLanguage]);

  // @desc Refresh Firebase Token If Session Expired
  // const refreshToken = async () => {
  //   const values = {
  //     device_type:"web",
  //     device_token: await firebase.requestForToken(),
  //   };
  //   dispatch(
  //     settingsActions.refreshFirebaseToken({
  //       data: objectToFormData(values),
  //       callback: () => {
  //         CookiesHelper.set("firebase_token", values.device_token!);
  //       },
  //     })
  //   );
  // };

  // useEffect(() => {
  //   if (!CookiesHelper.get("firebase_token") && !!user.api_token) {
  //     refreshToken();
  //   }
  // }, [user]);

  // @desc firebase messaging listener for new messages
  firebase
    .onMessageListener()
    .then((payload: any) => {
      notification.info({
        message: payload.notification.title,
        description: payload.notification.body,
        placement: "topLeft",
        duration: 10,
      });
    })
    .catch((err) => console.log("🚀 ~ file: index.tsx ~ line 56 ~ err", err));

  return (
    <div>
      <ConfigProvider
        locale={i18n.language === "en" ? enUS : arEG}
        direction={i18n.dir(i18n.language)}
        theme={theme}
      >
        <Helmet {...helmetConfig.config.APP} />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={ROUTE.HOME} element={<HomePage />} />
            <Route path={ROUTE.HOME} element={<Layout />}>
              {/* private Routes */}
              {routesList.map((route: RouteDto) => (
                <Route
                  key={route.id}
                  element={<RequireAuth allowedRoles={route.roles} />}
                >
                  <Route path={route.path} element={route.component} />
                </Route>
              ))}
              {/* public Routes */}
              <Route
                path={NOT_FOUND.NOT_FOUND}
                element={
                  user?.api_token ? (
                    <NotFound />
                  ) : (
                    <Navigate to={AUTH_ROUTE.LOGIN} />
                  )
                }
              />
              <Route
                path={NOT_FOUND.UNAUTHORIZED}
                element={
                  user?.api_token ? (
                    <UnAuthorized />
                  ) : (
                    <Navigate to={AUTH_ROUTE.LOGIN} />
                  )
                }
              />
            </Route>
            <Route path={AUTH_ROUTE.LOGIN} element={<Login />} />
          </Routes>
        </Suspense>
      </ConfigProvider>
    </div>
  );
}

export default App;
