import { t } from "i18next";
import { RuleDto, ValidationDto } from "@models/validation";

const requireRule: ValidationDto = {
  required: true,
  message: t("ThisFieldIsRequired!"),
};

const numbersRegex: ValidationDto = {
  pattern: /^[0-9]*$/,
  message: t("PleaseAddAValidNumber"),
};

const arabicOrEnglishName: ValidationDto = {
  pattern:
    /^(?:[a-zA-Z0-9\s@,=%$#&_\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){4,30}$/,
  message: t("PleaseAddValidName"),
};

export const phoneRule: ValidationDto = {
  pattern: /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/,
  message: t("PleaseEnterSaudiArabiaPhone!"),
};

export const numberRule: ValidationDto = {
  // Number Regex
  pattern: /^\d+$/,
  message: t("PleaseAddAValidNumber!"),
};

export const numberWithCommaRule: ValidationDto = {
  pattern: /^[0-9,;]+$/,
  message: t("PleaseAddAValidNumber!"),
};

// General IBAN Validation
export const IBANValidation: ValidationDto = {
  pattern: /^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}$/,
  message: t("PleaseAddAValidIBAN!"),
};

// Saudi Arabia IBAN Validation
export const SaudiArabiaIBANValidation: ValidationDto = {
  pattern: /^SA[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}$/,
  message: t("PleaseAddAValidSaudiArabiaIBAN!"),
};

export const IdNumberValidation: ValidationDto = {
  // ID Number Regex
  pattern: /^[0-9]{10}$/,
  message: t("PleaseEnterValidIDNumber!"),
};

// Field Validation 4 characters only
export const fieldValidation4CharactersOnly: ValidationDto = {
  pattern: /^[a-zA-Z0-9]{4}$/,
  message: t("PleaseAdd4CharactersOnly!"),
};

// Estate Name Validation
export const EstateName: RuleDto = [arabicOrEnglishName];

// Estate Status Validation
export const EstateStatus: RuleDto = [];

// Operation Type Validation
export const OperationType: RuleDto = [requireRule];

// Estate Type Validation
export const EstateType: RuleDto = [requireRule];

// Estate Use Type Validation
export const EstateUseType: RuleDto = [requireRule];

// Total Area Validation
export const TotalArea: RuleDto = [requireRule, numbersRegex];

// Floor Number Validation
export const FloorNumber: RuleDto = [numbersRegex];

// Pace Number Validation
export const PaceNumber: RuleDto = [];

// Interface Validation
export const Interface: RuleDto = [requireRule];

// Unit Number Validation
export const UnitNumber: RuleDto = [];

// Estate Age Validation
export const EstateAge: RuleDto = [requireRule];

// Estate Dimensions Validation
export const EstateDimensions: RuleDto = [requireRule];

// Street View Validation
export const StreetView: RuleDto = [requireRule, numbersRegex];

// Street Name Validation
export const StreetName: RuleDto = [arabicOrEnglishName];

// Advertiser License Number Validation
export const AdvertiserLicenseNumber: RuleDto = [numbersRegex];

// Authorization Number Validation
export const AuthorizationNumber: RuleDto = [numbersRegex];

// Authorization Number Validation
export const EstateSocialStatus: RuleDto = [];

// Estate Finish Type Validation
export const EstateFinishType: RuleDto = [];

// Owner Management Commission Validation
export const OwnerManagementCommission: RuleDto = [];

// Owner Management Commission Type Validation
export const OwnerManagementCommissionType: RuleDto = [];

// Real Estate Group Validation
export const RealEstateGroup: RuleDto = [];

// About Estate Validation
export const AboutEstate: RuleDto = [];

// Estate Comforts Validation
export const EstateComforts: RuleDto = [];

// Warranties and their duration Validation
export const WarrantiesAndTheirDuration: RuleDto = [];

// Owner Name Validation
export const OwnerName: RuleDto = [requireRule, arabicOrEnglishName];

// Owner Number Validation
export const OwnerNumber: RuleDto = [requireRule, phoneRule];

// Building Number Validation
export const BuildingNumber: RuleDto = [requireRule];

// Neighborhood Validation
export const Neighborhood: RuleDto = [];

// City Validation
export const City: RuleDto = [];

// Name Of The Building Guard Validation
export const NameOfTheBuildingGuard: RuleDto = [arabicOrEnglishName];

// Number Of The Building Guard Validation
export const BuildingGuardMobileNumber: RuleDto = [numbersRegex];

// The Identity Of The Building Guard Guard Validation
export const TheIdentityOfTheBuildingGuard: RuleDto = [];

// Bank Validation
export const Bank: RuleDto = [requireRule];

// Note Validation
export const Note: RuleDto = [requireRule];

// Estate Group Name Validation
export const EstateGroupName: RuleDto = [requireRule, arabicOrEnglishName];

// Require Validation
export const required: RuleDto = [requireRule];
