import { FC, RefObject, useEffect } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { PrinterOutlined } from "@ant-design/icons";

import styles from "./styles.module.scss";

interface Props {
  ComponentToPrint: RefObject<HTMLDivElement>;
}

const Print: FC<Props> = ({ ComponentToPrint }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.print}>
      <ReactToPrint
        trigger={() => (
          <Button className="mx-5 btn btn-info" icon={<PrinterOutlined />}>
            {t("Print")}
          </Button>
        )}
        documentTitle={t("Print")}
        content={() => ComponentToPrint.current}
      />
    </div>
  );
};

export default Print;
