import { API_ENDPOINTS } from "@constants";
import { RequestPayloadDto, SuccessPayload } from "@models";
import { CustomerDto } from "@models/customers";
import { axiosClient } from "@constants";
import { loadingActions } from "@store/loading/loadingSlice";
import { Modal, notification } from "antd";
import { AxiosResponse } from "axios";
import { t } from "i18next";
import { all, call, Effect, put, takeLatest } from "redux-saga/effects";
import { customersActions } from "./customerSlice";

const {
  getCustomers,
  getCustomersSuccess,
  addCustomer,
  updateCustomer,
  deleteCustomer,
  getSingleCustomer,
  getSingleCustomerSuccess,
  addCustomerNote,
  updateCustomerNote,
  getSingleCustomerNote,
  getSingleCustomerNoteSuccess,
  getAllCustomerNote,
  getAllCustomerNoteSuccess,
  deleteCustomerNote,
} = customersActions;

// Get All Customers
function* getAllCustomersSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<SuccessPayload<CustomerDto>> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.customer.all,
      { params: { ...payload.params } }
    );

    yield put(getCustomersSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Add Customer
function* addCustomerSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(axiosClient.post, API_ENDPOINTS.POST.customer.add, payload.data);

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Update Customer
function* updateCustomerSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.customer.update(payload.id!),
      payload.data
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Delete Customer
function* deleteCustomerSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.customer.delete(Number(payload.data))
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Single Customer
function* getSingleCustomerSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.customer.single(Number(payload.data))
    );

    yield put(getSingleCustomerSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Add Customer Note
function* addCustomerNoteSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.customer.addNote,
      payload.data
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Update Customer Note
function* updateCustomerNoteSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.customer.updateNote(Number(payload.id)),
      payload.data
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Single Customer Note
function* getSingleCustomerNoteSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.customer.singleNote(Number(payload.data))
    );
    console.log(
      "🚀 ~ file: cutomerSaga.ts ~ line 12 ~ function*getAllCustomersSaga ~ response",
      response
    );
    yield put(getSingleCustomerNoteSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get All Customer Notes
function* getAllCustomerNoteSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.customer.notes,
      { params: { ...Object(payload.data) } }
    );

    yield put(getAllCustomerNoteSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Delete Customer Note
function* deleteCustomerNoteSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.customer.deleteNote(Number(payload.data))
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

export default function* customersSaga() {
  yield all([
    takeLatest(getCustomers.type, getAllCustomersSaga),
    takeLatest(addCustomer.type, addCustomerSaga),
    takeLatest(updateCustomer.type, updateCustomerSaga),
    takeLatest(deleteCustomer.type, deleteCustomerSaga),
    takeLatest(getSingleCustomer.type, getSingleCustomerSaga),
    takeLatest(addCustomerNote.type, addCustomerNoteSaga),
    takeLatest(updateCustomerNote.type, updateCustomerNoteSaga),
    takeLatest(getSingleCustomerNote.type, getSingleCustomerNoteSaga),
    takeLatest(getAllCustomerNote.type, getAllCustomerNoteSaga),
    takeLatest(deleteCustomerNote.type, deleteCustomerNoteSaga),
  ]);
}
