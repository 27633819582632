// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDFHd_QaWqgMU5PhssXsjUEDEOVAfChBJc",
  authDomain: "aqarz-60c1f.firebaseapp.com",
  projectId: "aqarz-60c1f",
  storageBucket: "aqarz-60c1f.appspot.com",
  messagingSenderId: "959323108330",
  appId: "1:959323108330:web:19a656dbdafcbb6ee6b893",
  measurementId: "G-2CFCJM1P40",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });
    return currentToken;
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
