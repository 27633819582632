import {
  HomeOutlined,
  AppstoreOutlined,
  PieChartOutlined,
  FundOutlined,
  UserOutlined,
  FileTextOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import {
  CONTRACTS,
  CUSTOMERS,
  EMPLOYEES,
  FINANCIAL_SYSTEM,
  MenuItem,
  REAL_ESTATE_AND_UNITS,
  REPORTS,
  REQUESTS,
  ROUTE,
  SUBSCRIPTIONS,
} from "@models";

const menuList: MenuItem[] = [
  {
    title: "indications",
    path: ROUTE.INDICATIONS,
    icon: <AppstoreOutlined style={{ fontSize: "13px" }} />,
    roles: ["admin"],
  },
  {
    title: "RealEstateAndUnits",
    path: REAL_ESTATE_AND_UNITS.REAL_ESTATE_AND_UNITS,
    icon: <HomeOutlined />,
    roles: ["admin", "editor", "guest"],
    children: [
      {
        title: "Statistics",
        path: REAL_ESTATE_AND_UNITS.STATISTICS,
        roles: ["admin"],
      },
      {
        title: "RealEstateGroups",
        path: REAL_ESTATE_AND_UNITS.REAL_ESTATE_GROUPS,
        roles: ["admin"],
      },
      {
        title: "RealEstates",
        path: REAL_ESTATE_AND_UNITS.REAL_ESTATES,
        roles: ["admin"],
      },
    ],
  },
  {
    title: "Requests",
    path: REQUESTS.REQUESTS,
    icon: <FileTextOutlined />,
    roles: ["admin"],
    children: [
      {
        title: "RateRequests",
        path: REQUESTS.RATE_REQUESTS,
        roles: ["admin"],
      },
      {
        title: "PreviewRequests",
        path: REQUESTS.PREVIEW_REQUESTS,
        roles: ["admin"],
      },
      {
        title: "MarketDemands",
        path: REQUESTS.MARKET_DEMANDS,
        roles: ["admin"],
      },
    ],
  },
  {
    title: "Contracts",
    path: CONTRACTS.CONTRACTS,
    icon: <FileTextOutlined />,
    roles: ["admin", "editor"],
    children: [
      {
        title: "RentContracts",
        path: CONTRACTS.RENT_CONTRACTS,
        roles: ["admin"],
      },
      {
        title: "SalesContracts",
        path: CONTRACTS.SALES_CONTRACTS,
        roles: ["admin"],
      },
    ],
  },
  {
    title: "FinancialSystem",
    path: FINANCIAL_SYSTEM.FINANCIAL_SYSTEM,
    icon: <PieChartOutlined />,
    children: [
      {
        title: "LatestBonds",
        path: FINANCIAL_SYSTEM.LATEST_BONDS,
        roles: ["admin"],
      },
      {
        title: "Receipts",
        path: FINANCIAL_SYSTEM.RECEIPT,
        roles: ["admin"],
      },
      {
        title: "BillsOfExchange",
        path: FINANCIAL_SYSTEM.BILLS_OF_EXCHANGE,
        roles: ["admin"],
      },
      {
        title: "FinancialStatement",
        path: FINANCIAL_SYSTEM.FINANCIAL_STATEMENT,
        roles: ["admin"],
      },
      {
        title: "ExpenseManagement",
        path: FINANCIAL_SYSTEM.EXPENSE_MANAGEMENT,
        roles: ["admin"],
      },
    ],
  },
  {
    title: "Reports",
    path: REPORTS.REPORTS,
    icon: <FundOutlined />,
    roles: [],
    children: [
      {
        title: "PropertyManagementReports",
        path: REPORTS.PROPERTY_MANAGEMENT_REPORTS,
        roles: ["admin"],
      },
      {
        title: "ReceivablesReports",
        path: REPORTS.RECEIVABLES_REPORTS,
        roles: ["admin"],
      },
      {
        title: "ReceiptReports",
        path: REPORTS.RECEIPT_REPORTS,
        roles: ["admin"],
      },
      {
        title: "CollectionReports",
        path: REPORTS.COLLECTION_REPORTS,
        roles: ["admin"],
      },
      {
        title: "ContractAndUnitReports",
        path: REPORTS.CONTRACT_AND_UNIT_REPORTS,
        roles: ["admin"],
      },
      {
        title: "RealEstateStatusReports",
        path: REPORTS.REAL_ESTATE_STATUS_REPORTS,
        roles: ["admin"],
      },
    ],
  },
  {
    title: "Customers",
    path: CUSTOMERS.CUSTOMERS,
    icon: <UserOutlined />,
    roles: ["admin"],
  },
  {
    title: "EmployeesManagement",
    path: EMPLOYEES.EMPLOYEES,
    icon: <TeamOutlined />,
    roles: ["admin"],
  },
  {
    title: "Subscriptions",
    path: SUBSCRIPTIONS.SUBSCRIPTIONS,
    icon: <AppstoreOutlined />,
    roles: ["admin"],
    children: [
      {
        title: "MySubscriptions",
        path: SUBSCRIPTIONS.SUBSCRIPTIONS,
        roles: ["admin"],
      },
      {
        title: "Plans",
        path: SUBSCRIPTIONS.PLANS,
        roles: ["admin"],
      },
    ],
  },
];
export default menuList;
