import { MetaResponseDto } from "@/models";
import { PaginationProps } from "antd";
import { useDispatch } from "react-redux";

interface IProps {
  meta: MetaResponseDto;
  get: any;
  params?: object;
}

const usePagination = ({ meta, get, params }: IProps) => {
  const dispatch = useDispatch();
  return {
    hideOnSinglePage: true,
    showSizeChanger: true,
    current: meta?.current_page,
    total: meta?.total,
    onChange: (page, pageNumber) => {
      dispatch(
        get({
          params: { ...params, page, size: pageNumber },
        })
      );
    },
  } as PaginationProps;
};

export default usePagination;
