import { useTypedSelector } from "@hooks";

const useAuth = () => {
  const authState = useTypedSelector((state) => state.auth);
  const { currentUser } = authState;
  return {
    ...currentUser,
    role: currentUser?.role || "admin",
  };
};

export default useAuth;
