export enum RequestsEnum {
  login = "auth/login",
  getMyEstates = "myEstates/getMyEstates",
  changeStatus = "myEstates/changeStatus",
  getEstateType = "Setting/getEstateType",
  // Settings Requests
  getBanks = "Setting/getBanks",
  getOperationType = "Setting/getOperationType",
  comfort = "Setting/comfort",
  getContractInvoices = "Setting/getContractInvoice",
  getContractExpense = "Setting/getContractExpense",
  getDashboard = "Setting/getDashboard",
  // Real Estate Requests
  addEstate = "myEstates/addEstate",
  updateEstate = "myEstates/updateEstate",
  deleteEstate = "myEstates/deleteEstate",
  singleEstate = "myEstates/getDetails",
  getNotes = "myEstates/getNotes",
  addNotes = "myEstates/addNote",
  updateNotes = "myEstates/updateNote",
  getAllEstateGroups = "estateGroup/getEstateGroup",
  addEstateGroup = "estateGroup/addEstateGroup",
  updateEstateGroup = "estateGroup/updateEstateGroup",
  getSingleGroup = "estateGroup/getDetails",
  addGroupNote = "estateGroup/addNote",
  updateGroupNote = "estateGroup/updateNote",
  getGroupNote = "estateGroup/getNotes",
  deleteEstateGroup = "estateGroup/deleteEstateGroup",
  addRentContract = "rentContract/addContract",
  getAllRentContract = "rentContract/getAllRentContract",
  getSingleRentContract = "rentContract/getSingleRentContract",
  getRentContractNotes = "rentContract/getRentContractNotes",
  addRentContractNote = "rentContract/addRentContractNote",
  updateRentContractNote = "rentContract/updateRentContractNote",
  getAllCustomers = "customers/getCustomers",
  getSingleCustomers = "customers/getSingleCustomer",
  addCustomer = "customers/addCustomer",
  updateCustomer = "customers/updateCustomer",
  getAllCustomerNote = "customers/getAllCustomerNote",
  addCustomerNote = "customers/addCustomerNote",
  updateCustomerNote = "customers/updateCustomerNote",
  getSalesContracts = "salesContracts/getAllSalesContracts",
  getSingleSalesContracts = "salesContracts/getSingleSalesContract",
  addSalesContract = "salesContracts/addSalesContract",
  updateSalesContract = "salesContracts/updateSalesContract",
  addSalesContractNote = "salesContracts/addSalesContractNote",
  updateSalesContractNote = "salesContracts/updateSalesContractNote",
  getSalesContractNotes = "salesContracts/getSalesContractNotes",
  addBill = "billSlice/addBill",
  getAllBills = "billSlice/getAllBills",
  getSingleBill = "billSlice/getSingleBill",
  addEmployee = "employees/addEmployee",
  getEmployees = "employees/getEmployee",
  deleteEmployee = "employees/deleteEmployee",
  deleteCatchBond = "catchBond/deleteCatchBond",
  getAllCatchBond = "catchBond/getAllCatchBond",
  getSingleCatchBond = "catchBond/getSingleCatchBond",
  addCatchBond = "catchBond/addCatchBond",
  statement = "statement/getAccountStatement",
  verifyCode = "auth/verifyCode",
  resetPass = "auth/resetPass",
  ownerManagement = "reports/ownerManagement",
  checkEntityId = "rentContract/checkEjarEntityId",
  addEjarProperty = "rentContract/addEjarProperty",
  addEjarUnit = "rentContract/addEjarUnit",
  addEjarContract = "rentContract/addEjarContract",
  addEjarPropertyAndUnit = "rentContract/addEjarPropertyAndUnit",
  ejarContractUnitServices = "rentContract/ejarContractUnitServices",
  ejarRentalFees = "rentContract/ejarRentalFees",
  ejarContractTermsContract = "rentContract/ejarContractTermsContract",
  ejarSubmitContract = "rentContract/ejarSubmitContract",
  getRateRequests = "rate/getRateRequests",
  allPreviewRequests = "preview/allPreviewRequests",
  allMarketRequests = "marketDemands/allMarketRequests",
  checkPlan = "plans/checkPlan",
  subscribePlan = "plans/subscribePlan",
  getPlanRequests = "plans/getPlanRequests",
  getSubscribtionList = "plans/getSubscribtionList",
  selectTime = "preview/selectTime",
  getNotification = "auth/getNotifications",
  getMessages = "auth/getMessages",
  messageDetail = "auth/messageDetail",
  resetPassword = "auth/resetPassword",
  forgotPassword = "auth/forgotPassword",
  updatePreviewRequest = "preview/updatePreviewRequest",
  getRelatedEstates = "marketDemands/getRelatedEstates",
  sendOffer = "marketDemands/sendOffer",
  getSubscribtionDetails = "plans/getSubscribtionDetails",
  advertisementValidator = "myEstates/advertisementValidator",
  addEstateViaValidator = "myEstates/addEstateViaValidator",
  payForLicense = "plans/payForLicense",
  updateFiles = "myEstates/updateFiles",
}

export interface RequestPayloadDto {
  data?: FormData | string | object | number;
  callback?: any;
  id?: number;
  params?: any;
}
export interface LinkResponseDto {
  first: string;
  last: string;
  prev: unknown;
  next: unknown;
}

export interface MetaResponseLinkDto {
  url: string;
  label: string;
  active: boolean;
}

export interface MetaResponseDto {
  current_page: number;
  from: number;
  last_page: number;
  links: Array<MetaResponseLinkDto>;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export interface SuccessPayload<T> {
  data?: T[];
  links?: LinkResponseDto;
  meta?: MetaResponseDto;
}

export interface MainRequestDto<T> {
  status: boolean;
  code: number;
  message: string;
  data: T;
}

export interface ListParams {
  _page?: number;
  _limit?: number;
  _sort?: string;
  _order?: "asc" | "desc";

  [key: string]: any;
}
