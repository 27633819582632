import { RequestPayloadDto, SuccessPayload } from "@models";
import { salesContractDto } from "@models/salesContracts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateDto {
  allContractsResult?: SuccessPayload<salesContractDto>;
  singleContractsResult?: salesContractDto;
  noteResult?: any;
}
const initialState: initialStateDto = {};

const salesContractSlice = createSlice({
  name: "salesContracts",
  initialState,
  reducers: {
    // Get All Sales Contracts
    getAllSalesContracts(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getAllSalesContractsSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<salesContractDto>>
    ) {
      state.allContractsResult = action.payload;
    },
    // Add Sales Contract
    addSalesContract(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Update Sales Contract
    updateSalesContract(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getSingleSalesContract(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getSingleSalesContractSuccess(
      state: initialStateDto,
      action: PayloadAction<salesContractDto>
    ) {
      state.singleContractsResult = action.payload;
    },
    addSalesContractNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    updateSalesContractNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getSalesContractNotes(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getSalesContractNotesSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<salesContractDto>>
    ) {
      state.noteResult = action.payload;
    },
    deleteSalesContractNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Delete Sales Contract
    deleteSalesContract(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
  },
});

// Sales Contracts Actions
export const salesContractsActions = salesContractSlice.actions;

// Sales Contracts Reducer
const salesContractsReducer = salesContractSlice.reducer;

export default salesContractsReducer;
