import { lazy } from "react";
import {
  ROLES,
  ROUTE,
  REAL_ESTATE_AND_UNITS,
  CONTRACTS,
  FINANCIAL_SYSTEM,
  REPORTS,
  CUSTOMERS,
  EMPLOYEES,
  REQUESTS,
  SUBSCRIPTIONS,
  AUTH_ROUTE,
} from "@models";
import { t } from "i18next";

const IndicationsPage = lazy(() => import("@pages/Indications"));

// Estates Components
const StatisticsPage = lazy(() => import("@pages/Statistics/List"));
const RealEstateGroupsPage = lazy(() => import("@pages/RealEstateGroups/List"));
const CreateEstateGroup = lazy(
  () => import("@pages/RealEstateGroups/CreateOrUpdateEstateGroup")
);
const EstateGroupDetails = lazy(
  () => import("@pages/RealEstateGroups/Details")
);
const RealEstatesPage = lazy(() => import("@pages/RealEstates/List"));
const EstateServices = lazy(() => import("@pages/RealEstates/EstateServices"));
const AdvertisingLicense = lazy(
  () => import("@pages/RealEstates/AdvertisingLicense")
);
const PublishingAndLicensingAdvertising = lazy(
  () => import("@pages/RealEstates/PublishingAndLicensingAdvertising")
);
const AdvertisingDetails = lazy(
  () => import("@pages/RealEstates/Details/advertisement")
);

const CreateEstate = lazy(
  () => import("@pages/RealEstates/CreateOrUpdateEstate")
);
const AddMedia = lazy(
  () => import("@pages/RealEstates/CreateOrUpdateEstate/AddMedia")
);
const EstateDetails = lazy(() => import("@pages/RealEstates/Details"));

const RentContractsPage = lazy(() => import("@pages/RentContracts/List"));
const AddRentContractsPage = lazy(
  () => import("@pages/RentContracts/CreateOrUpdateContract")
);
const RentContractsDetailsPage = lazy(
  () => import("@pages/RentContracts/Detials")
);
const SalesContractsPage = lazy(() => import("@pages/SalesContracts/List"));
const AddSalesContractPage = lazy(
  () => import("@pages/SalesContracts/CreateOrUpdateContract")
);
const SalesContractDetailsPage = lazy(
  () => import("@pages/SalesContracts/Details")
);
const LatestBondsPage = lazy(() => import("@pages/LatestBonds/List"));
const ReceiptPage = lazy(() => import("@pages/Receipt/List"));
const AddReceiptPage = lazy(
  () => import("@pages/Receipt/CreateOrUpdateReceipt")
);
const ReceiptDetailsPage = lazy(() => import("@pages/Receipt/Details"));

const FinancialStatementPage = lazy(
  () => import("@pages/FinancialStatement/List")
);
const ExpenseManagementPage = lazy(
  () => import("@pages/ExpenseManagement/List")
);
const ExpenseManagementDetailsPage = lazy(
  () => import("@pages/ExpenseManagement/Details")
);
const AddExpenseManagementPage = lazy(
  () => import("@pages/ExpenseManagement/CreateOrUpdateExpense")
);
const PropertyManagementReportsPage = lazy(
  () => import("@pages/PropertyManagementReports/List")
);
const ReceivablesReportsPage = lazy(() => import("@pages/ReceivablesReports"));
const ReceiptReportsPage = lazy(() => import("@pages/ReceiptReports"));
const CollectionReportsPage = lazy(() => import("@pages/CollectionReports"));
const ContractAndUnitReportsPage = lazy(
  () => import("@pages/ContractAndUnitReports")
);
const RealEstateStatusReportsPage = lazy(
  () => import("@pages/RealEstateStatusReports")
);
const CustomersPage = lazy(() => import("@pages/Customers/List"));
const AddCustomerPage = lazy(
  () => import("@pages/Customers/CreateOrUpdateCustomer")
);
const CustomerDetailsPage = lazy(() => import("@pages/Customers/Details"));
const EmployeesPage = lazy(() => import("@pages/EmployeesManagement/List"));
const EmployeeDetailsPage = lazy(
  () => import("@pages/EmployeesManagement/Details")
);

const RateRequestsPage = lazy(() => import("@pages/RateRequests/List"));
const RateRequestDetailsPage = lazy(
  () => import("@pages/RateRequests/Details")
);
const PreviewRequestsPage = lazy(() => import("@pages/PreviewRequests/List"));
const MarketDemandsPage = lazy(() => import("@pages/MarketDemands/List"));
const MarketDemandDetailsPage = lazy(
  () => import("@pages/MarketDemands/Details")
);

const SubscriptionsPage = lazy(() => import("@pages/Plans/Subscriptions"));
const PlansPage = lazy(() => import("@pages/Plans/List"));

const ProfilePage = lazy(() => import("@pages/User/Profile"));
const MessagesPage = lazy(() => import("@pages/User/Messages"));
const NotificationsPage = lazy(() => import("@pages/User/Notifications"));

export const routesList = [
  {
    path: ROUTE.INDICATIONS,
    roles: [ROLES.admin],
    component: <IndicationsPage />,
    title: "Indications",
    id: "1",
  },
  {
    path: REAL_ESTATE_AND_UNITS.STATISTICS,
    roles: [ROLES.admin],
    id: "2",
    component: <StatisticsPage />,
    title: "Statistics",
    breadcrumbParentId: "1",
  },
  {
    path: REAL_ESTATE_AND_UNITS.REAL_ESTATE_GROUPS,
    roles: [ROLES.admin],
    id: "3",
    component: <RealEstateGroupsPage />,
    title: "RealEstateGroups",
    breadcrumbParentId: "2",
  },
  {
    path: REAL_ESTATE_AND_UNITS.CREATE_REAL_ESTATE_GROUP,
    roles: [ROLES.admin],
    id: "4",
    component: <CreateEstateGroup />,
    title: "CreateRealEstateGroup",
    breadcrumbParentId: "3",
  },
  {
    path: REAL_ESTATE_AND_UNITS.UPDATE_REAL_ESTATE_GROUP,
    roles: [ROLES.admin],
    id: "5",
    component: <CreateEstateGroup />,
    title: "UpdateRealEstateGroup",
    breadcrumbParentId: "3",
  },
  {
    path: REAL_ESTATE_AND_UNITS.REAL_ESTATE_GROUP_DETAILS,
    roles: [ROLES.admin],
    id: "6",
    component: <EstateGroupDetails />,
    title: "RealEstateGroupDetails",
    breadcrumbParentId: "3",
  },
  {
    path: REAL_ESTATE_AND_UNITS.REAL_ESTATES,
    roles: [ROLES.admin],
    id: "7",
    component: <RealEstatesPage />,
    title: "RealEstates",
    breadcrumbParentId: "2",
  },
  {
    path: REAL_ESTATE_AND_UNITS.CREATE_REAL_ESTATE,
    roles: [ROLES.admin],
    id: "8",
    component: <CreateEstate />,
    title: "CreateRealEstate",
    breadcrumbParentId: "7",
  },
  {
    path: REAL_ESTATE_AND_UNITS.UPDATE_REAL_ESTATE,
    roles: [ROLES.admin],
    id: "9",
    component: <CreateEstate />,
    title: "UpdateRealEstate",
    breadcrumbParentId: "7",
  },
  {
    path: REAL_ESTATE_AND_UNITS.REAL_ESTATE_DETAILS,
    roles: [ROLES.admin],
    id: "10",
    component: <EstateDetails />,
    title: "RealEstateDetails",
    breadcrumbParentId: "7",
  },
  {
    path: CONTRACTS.RENT_CONTRACTS,
    roles: [ROLES.admin],
    id: "11",
    component: <RentContractsPage />,
    title: "RentContracts",
    breadcrumbParentId: "1",
  },
  {
    path: CONTRACTS.ADD_RENT_CONTRACTS,
    roles: [ROLES.admin],
    id: "12",
    component: <AddRentContractsPage />,
    title: "AddRentContracts",
    breadcrumbParentId: "11",
  },
  {
    path: CONTRACTS.RENT_CONTRACTS_DETAILS,
    roles: [ROLES.admin],
    id: "13",
    component: <RentContractsDetailsPage />,
    title: "RentContractsDetails",
    breadcrumbParentId: "11",
  },
  {
    path: CONTRACTS.SALES_CONTRACTS,
    roles: [ROLES.admin],
    id: "14",
    component: <SalesContractsPage />,
    title: "SalesContracts",
    breadcrumbParentId: "1",
  },
  {
    path: CONTRACTS.ADD_SALES_CONTRACTS,
    roles: [ROLES.admin],
    id: "15",
    component: <AddSalesContractPage />,
    title: "AddSalesContract",
    breadcrumbParentId: "14",
  },
  {
    path: CONTRACTS.SALES_CONTRACTS_DETAILS,
    roles: [ROLES.admin],
    id: "16",
    component: <SalesContractDetailsPage />,
    title: "SalesContractsDetails",
    breadcrumbParentId: "14",
  },
  {
    path: FINANCIAL_SYSTEM.LATEST_BONDS,
    roles: [ROLES.admin],
    id: "17",
    component: <LatestBondsPage />,
    title: "LatestBonds",
    breadcrumbParentId: "1",
  },
  {
    path: FINANCIAL_SYSTEM.RECEIPT,
    roles: [ROLES.admin],
    id: "18",
    component: <ReceiptPage />,
    title: "Receipts",
    breadcrumbParentId: "17",
  },
  {
    path: FINANCIAL_SYSTEM.ADD_RECEIPT,
    roles: [ROLES.admin],
    id: "19",
    component: <AddReceiptPage />,
    title: "AddReceipt",
    breadcrumbParentId: "18",
  },
  {
    path: FINANCIAL_SYSTEM.RECEIPT_DETAILS,
    roles: [ROLES.admin],
    id: "20",
    component: <ReceiptDetailsPage />,
    title: "ReceiptDetails",
    breadcrumbParentId: "18",
  },
  {
    path: FINANCIAL_SYSTEM.BILLS_OF_EXCHANGE,
    roles: [ROLES.admin],
    id: "21",
    component: <ReceiptPage />,
    title: "BillsOfExchange",
    breadcrumbParentId: "17",
  },
  {
    path: FINANCIAL_SYSTEM.BILLS_OF_EXCHANGE_DETAILS,
    roles: [ROLES.admin],
    id: "22",
    component: <ReceiptDetailsPage />,
    title: "BillsOfExchangeDetails",
    breadcrumbParentId: "21",
  },
  {
    path: FINANCIAL_SYSTEM.ADD_BILLS_OF_EXCHANGE,
    roles: [ROLES.admin],
    id: "23",
    component: <AddReceiptPage />,
    title: "AddBillsOfExchange",
    breadcrumbParentId: "21",
  },
  {
    path: FINANCIAL_SYSTEM.FINANCIAL_STATEMENT,
    roles: [ROLES.admin],
    id: "24",
    component: <FinancialStatementPage />,
    title: "FinancialStatement",
    breadcrumbParentId: "17",
  },
  {
    path: FINANCIAL_SYSTEM.EXPENSE_MANAGEMENT,
    roles: [ROLES.admin],
    id: "25",
    component: <ExpenseManagementPage />,
    title: "ExpenseManagement",
    breadcrumbParentId: "17",
  },
  {
    path: FINANCIAL_SYSTEM.ADD_EXPENSE_MANAGEMENT,
    roles: [ROLES.admin],
    id: "26",
    component: <AddExpenseManagementPage />,
    title: "AddExpenseManagement",
    breadcrumbParentId: "25",
  },
  {
    path: FINANCIAL_SYSTEM.EXPENSE_MANAGEMENT_DETAILS,
    roles: [ROLES.admin],
    id: "27",
    component: <ExpenseManagementDetailsPage />,
    title: "ExpenseManagementDetails",
    breadcrumbParentId: "25",
  },
  {
    path: REPORTS.PROPERTY_MANAGEMENT_REPORTS,
    roles: [ROLES.admin],
    id: "28",
    component: <PropertyManagementReportsPage />,
    title: "PropertyManagementReports",
  },
  {
    path: REPORTS.RECEIVABLES_REPORTS,
    roles: [ROLES.admin],
    id: "29",
    component: <ReceivablesReportsPage />,
    title: "ReceivablesReports",
  },
  {
    path: REPORTS.RECEIPT_REPORTS,
    roles: [ROLES.admin],
    id: "30",
    component: <ReceiptReportsPage />,
    title: "ReceiptReports",
  },
  {
    path: REPORTS.COLLECTION_REPORTS,
    roles: [ROLES.admin],
    id: "31",
    component: <CollectionReportsPage />,
    title: "CollectionReports",
  },
  {
    path: REPORTS.CONTRACT_AND_UNIT_REPORTS,
    roles: [ROLES.admin],
    id: "32",
    component: <ContractAndUnitReportsPage />,
    title: "ContractAndUnitReports",
  },
  {
    path: REPORTS.REAL_ESTATE_STATUS_REPORTS,
    roles: [ROLES.admin],
    id: "33",
    component: <RealEstateStatusReportsPage />,
    title: "RealEstateStatusReports",
  },
  {
    path: CUSTOMERS.CUSTOMERS,
    roles: [ROLES.admin],
    id: "34",
    component: <CustomersPage />,
    title: "Customers",
    breadcrumbParentId: "1",
  },
  {
    path: CUSTOMERS.ADD_CUSTOMER,
    roles: [ROLES.admin],
    id: "35",
    component: <AddCustomerPage />,
    title: "AddCustomer",
    breadcrumbParentId: "34",
  },
  {
    path: CUSTOMERS.UPDATE_CUSTOMER,
    roles: [ROLES.admin],
    id: "36",
    component: <AddCustomerPage />,
    title: "UpdateCustomer",
    breadcrumbParentId: "34",
  },
  {
    path: CUSTOMERS.CUSTOMER_DETAILS,
    roles: [ROLES.admin],
    id: "37",
    component: <CustomerDetailsPage />,
    title: "CustomerDetails",
    breadcrumbParentId: "34",
  },
  {
    path: EMPLOYEES.EMPLOYEES,
    roles: [ROLES.admin],
    id: "38",
    component: <EmployeesPage />,
    title: "Employees",
    breadcrumbParentId: "1",
  },
  {
    path: EMPLOYEES.EMPLOYEE_DETAILS,
    roles: [ROLES.admin],
    id: "39",
    component: <EmployeeDetailsPage />,
    title: "EmployeeDetails",
    breadcrumbParentId: "38",
  },
  {
    path: REQUESTS.RATE_REQUESTS,
    roles: [ROLES.admin],
    id: "40",
    component: <RateRequestsPage />,
    title: "RateRequests",
    breadcrumbParentId: "1",
  },
  {
    path: REQUESTS.PREVIEW_REQUESTS,
    roles: [ROLES.admin],
    id: "41",
    component: <PreviewRequestsPage />,
    title: "PreviewRequests",
    breadcrumbParentId: "1",
  },
  {
    path: REQUESTS.MARKET_DEMANDS,
    roles: [ROLES.admin],
    id: "42",
    component: <MarketDemandsPage />,
    title: "MarketDemands",
    breadcrumbParentId: "1",
  },
  {
    path: SUBSCRIPTIONS.SUBSCRIPTIONS,
    roles: [ROLES.admin],
    id: "43",
    component: <SubscriptionsPage />,
    title: "Subscriptions",
    breadcrumbParentId: "1",
  },
  {
    path: SUBSCRIPTIONS.PLANS,
    roles: [ROLES.admin],
    id: "44",
    component: <PlansPage />,
    title: "Plans",
    breadcrumbParentId: "1",
  },
  {
    path: REQUESTS.RATE_REQUESTS_DETAILS,
    roles: [ROLES.admin],
    id: "45",
    component: <RateRequestDetailsPage />,
    title: "RateRequestsDetails",
    breadcrumbParentId: "40",
  },
  {
    path: AUTH_ROUTE.PROFILE,
    roles: [ROLES.admin],
    id: "46",
    component: <ProfilePage />,
    title: "Profile",
    breadcrumbParentId: "1",
  },
  {
    path: AUTH_ROUTE.MESSAGES,
    roles: [ROLES.admin],
    id: "47",
    component: <MessagesPage />,
    title: "Messages",
    breadcrumbParentId: "1",
  },
  {
    path: AUTH_ROUTE.NOTIFICATIONS,
    roles: [ROLES.admin],
    id: "48",
    component: <NotificationsPage />,
    title: "Notifications",
    breadcrumbParentId: "1",
  },
  {
    path: REQUESTS.MARKET_DEMANDS_DETAILS,
    roles: [ROLES.admin],
    id: "49",
    component: <MarketDemandDetailsPage />,
    title: "MarketDemandsDetails",
    breadcrumbParentId: "42",
  },
  {
    path: CONTRACTS.UPDATE_SALES_CONTRACTS,
    roles: [ROLES.admin],
    id: "50",
    component: <AddSalesContractPage />,
    title: "AddSalesContract",
    breadcrumbParentId: "14",
  },
  {
    path: REAL_ESTATE_AND_UNITS.REAL_ESTATE_SERVICES,
    roles: [ROLES.admin],
    id: "51",
    component: <EstateServices />,
    title: "EstateServices",
    breadcrumbParentId: "2",
  },
  {
    path: REAL_ESTATE_AND_UNITS.ADVERTISING_LICENSE,
    roles: [ROLES.admin],
    id: "52",
    component: <AdvertisingLicense />,
    title: "AdvertisingLicense",
    breadcrumbParentId: "51",
  },
  {
    path: REAL_ESTATE_AND_UNITS.PUBLISHING_AND_LICENSING_ADVERTISING,
    roles: [ROLES.admin],
    id: "53",
    component: <PublishingAndLicensingAdvertising />,
    title: "PublishingAndLicensingAdvertising",
    breadcrumbParentId: "51",
  },
  {
    path: REAL_ESTATE_AND_UNITS.ADVERTISING_DETAILS,
    roles: [ROLES.admin],
    id: "54",
    component: <AdvertisingDetails />,
    title: "AdvertisingDetails",
    breadcrumbParentId: "52",
  },
  {
    path: REAL_ESTATE_AND_UNITS.ADD_MEDIA,
    roles: [ROLES.admin],
    id: "55",
    component: <AddMedia />,
    title: "AddMedia",
    breadcrumbParentId: "54",
  },
];
