import { RequestPayloadDto, SuccessPayload } from "@models";
import { rentContractDto } from "@models/contracts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateDto {
  rentResult?: SuccessPayload<rentContractDto>;
  singleRentResult?: rentContractDto;
  noteResult?: any;
  entityId?: any;
}

const initialState: initialStateDto = {
  rentResult: {},
};
const rentContractSlice = createSlice({
  name: "rentContract",
  initialState,
  reducers: {
    // Get All Rent Contracts
    getAllRentContract(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getAllRentContractSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<rentContractDto>>
    ) {
      state.rentResult = action.payload;
    },
    // Add Rent Contract
    addContract(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Get Single Rent Contract
    getSingleRentContract(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getSingleRentContractSuccess(
      state: initialStateDto,
      action: PayloadAction<rentContractDto>
    ) {
      state.singleRentResult = action.payload;
    },
    addRentContractNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    updateRentContractNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getRentContractNotes(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getRentContractNotesSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<rentContractDto>>
    ) {
      state.noteResult = action.payload;
    },
    deleteRentContractNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Add Invoice
    addInvoice(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Check Ejar Entity Id
    checkEjarEntityId(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    checkEjarEntityIdSuccess(
      state: initialStateDto,
      action: PayloadAction<any>
    ) {
      state.entityId = action.payload?.data?.id;
    },

    // Check Ejar Organization Id And Entity Id
    checkEjarOrganizationIdAndEntityId(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Ejar Add Properties
    addEjarProperty(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Ejar Add Unit
    addEjarUnit(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Ejar Add Contract
    addEjarContract(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Ejar Add Property And Unit
    addEjarPropertyAndUnit(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Ejar Select Parties
    selectEjarParties(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Ejar Financial Information Contract
    ejarFinancialInformationContract(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Ejar Contract Unit Services
    ejarContractUnitServices(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Ejar Rental Fees
    ejarRentalFees(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Ejar Contract Terms Contract
    ejarContractTermsContract(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Ejar Submit Contract
    ejarSubmitContract(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
  },
});

// Rent Contract Actions
export const rentContractActions = rentContractSlice.actions;

// Rent Contract Reducer
const rentContractReducer = rentContractSlice.reducer;

export default rentContractReducer;
