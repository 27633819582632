import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RequestPayloadDto,
  RealEstateDto,
  SuccessPayload,
  NoteDto,
} from "@models";

interface initialStateDto {
  myEstateResult?: {
    data?: Array<RealEstateDto>;
    link?: any;
    meta?: any;
  };
  addEstateResult?: {};
  getDetailsResult?: RealEstateDto;
  noteResult?: SuccessPayload<NoteDto>;
  advertisementValidatorResult?: any;
}
const initialState: initialStateDto = {
  myEstateResult: {
    data: [],
  },
};
const myEstateSlice = createSlice({
  name: "myEstates",
  initialState,
  reducers: {
    getMyEstates(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getMyEstatesSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<RealEstateDto>>
    ) {
      state.myEstateResult = action.payload;
    },
    // Add Estate Slice Reducer
    addEstate(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    addEstateSuccess(state: initialStateDto, action: PayloadAction<any>) {
      state.addEstateResult = action.payload;
    },
    // Update Estate Slice Reducer
    updateEstate(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Update Estate Files Slice Reducer
    updateFiles(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Delete Estate Slice Reducer
    deleteEstate(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Get Estate Details Slice Reducer
    getDetails(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getDetailsSuccess(
      state: initialStateDto,
      action: PayloadAction<RealEstateDto>
    ) {
      state.getDetailsResult = action.payload;
    },
    // Get All Estate Notes Slice Reducer
    getNotes(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getNotesSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<NoteDto>>
    ) {
      state.noteResult = action.payload;
    },
    // Delete Estate Note Slice Reducer
    deleteNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Add Estate Note Slice Reducer
    addNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Update Estate Note Slice Reducer
    updateNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Change Estate Status
    changeStatus(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Delete Image
    deleteImage(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // CHECK Google Map Point
    checkGoogleMapPoint(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},

    // Advertisement Validator
    advertisementValidator(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    advertisementValidatorSuccess(
      state: initialStateDto,
      action: PayloadAction<any>
    ) {
      state.advertisementValidatorResult = action.payload;
    },

    // Add Estate Via Validator
    addEstateViaValidator(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
  },
});

// Actions
export const myEstateActions = myEstateSlice.actions;

// Reducer
const myEstateReducer = myEstateSlice.reducer;
export default myEstateReducer;
