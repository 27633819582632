import { API_ENDPOINTS } from "@constants";
import { RequestPayloadDto, SuccessPayload } from "@models";
import { CatchBondDto } from "@models/catchBond";
import { axiosClient } from "@constants";
import { loadingActions } from "@store/loading/loadingSlice";
import { Modal, notification } from "antd";
import { AxiosResponse } from "axios";
import { t } from "i18next";
import { all, call, Effect, put, takeLatest } from "redux-saga/effects";
import { catchBondActions } from "./receiptSlice";

// Catch Bound Actions
const {
  addCatchBond,
  deleteCatchBond,
  getAllCatchBond,
  getAllCatchBondSuccess,
  getSingleCatchBond,
  getSingleCatchBonduccess,
} = catchBondActions;

// Add Catch Bond Saga
function* addCatchBondSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.catchBond.add,
      payload.data
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });

    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Delete Single Catch Bond Saga
function* deleteCatchBondSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.catchBond.delete(Number(payload.data))
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });

    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get All Specefic Catch Bond Saga
function* getAllCatchBondSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const res: AxiosResponse<SuccessPayload<CatchBondDto>> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.catchBond.all,
      {
        params: {
          ...payload.params,
        },
      }
    );

    yield put(getAllCatchBondSuccess({ ...res.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Single Catch Bond Saga
function* getSingleCatchBondaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const res: AxiosResponse<CatchBondDto> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.catchBond.single(Number(payload.data))
    );

    yield put(getSingleCatchBonduccess({ ...res.data }));

    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

export default function* catchBondSaga() {
  yield all([
    takeLatest(addCatchBond.type, addCatchBondSaga),
    takeLatest(deleteCatchBond.type, deleteCatchBondSaga),
    takeLatest(getAllCatchBond.type, getAllCatchBondSaga),
    takeLatest(getSingleCatchBond.type, getSingleCatchBondaga),
  ]);
}
