import { FC } from "react";
import { Navigate } from "react-router-dom";
import { Layout } from "antd";

import Sider from "./Sider";
import Header from "./Header";
import BreadCrumb from "./BreadCrumb";
import Content from "./Content";
import { useAuth } from "@hooks";
import { AUTH_ROUTE, createPath } from "@/models";

const Main: FC = () => {
  const auth = useAuth();

  if (!auth.api_token) {
    return (
      <Navigate
        to={createPath({
          path: AUTH_ROUTE.LOGIN,
          query: { redirect: window.location.href },
        })}
        replace={true}
      />
    );
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider />
      <Layout>
        <Header />
        <BreadCrumb />
        <Content />
      </Layout>
    </Layout>
  );
};

export default Main;
