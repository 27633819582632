import { RequestPayloadDto, SuccessPayload } from "@models";
import { CustomerDto } from "@models/customers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateDto {
  allCustomersResult?: SuccessPayload<CustomerDto>;
  singleCustomerResult?: CustomerDto;
  singleNoteResult?: any;
  allNoteResult?: any;
}

const initialState: initialStateDto = {};

const customerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    getCustomers(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getCustomersSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<CustomerDto>>
    ) {
      state.allCustomersResult = action.payload;
    },
    addCustomer(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    updateCustomer(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    deleteCustomer(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getSingleCustomer(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getSingleCustomerSuccess(
      state: initialStateDto,
      action: PayloadAction<any>
    ) {
      state.singleCustomerResult = action.payload;
    },
    addCustomerNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    updateCustomerNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getSingleCustomerNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getSingleCustomerNoteSuccess(
      state: initialStateDto,
      action: PayloadAction<any>
    ) {
      state.singleNoteResult = action.payload;
    },
    getAllCustomerNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getAllCustomerNoteSuccess(
      state: initialStateDto,
      action: PayloadAction<any>
    ) {
      state.allNoteResult = action.payload;
    },
    deleteCustomerNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
  },
});

// Customers Actions
export const customersActions = customerSlice.actions;

// Customers Reducer
const customersReducer = customerSlice.reducer;

export default customersReducer;
