import { NoteDto, RequestPayloadDto, SuccessPayload } from "@models";
import { RealEstateGroupDto } from "@models/realEstateGroups";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateDto {
  getEstateGroups?: SuccessPayload<RealEstateGroupDto>;
  getDetailsResult?: RealEstateGroupDto;
  noteResult?: SuccessPayload<NoteDto>;
}

const initialState: initialStateDto = {};

const estateGroupSlice = createSlice({
  name: "estateGroup",
  initialState,
  reducers: {
    getEstateGroup(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getEstateGroupSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<RealEstateGroupDto>>
    ) {
      state.getEstateGroups = action.payload;
    },
    addEstateGroup(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    updateEstateGroup(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Get Estate Group Details Slice Reducer
    getDetails(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getDetailsSuccess(
      state: initialStateDto,
      action: PayloadAction<RealEstateGroupDto>
    ) {
      state.getDetailsResult = action.payload;
    },
    // Get All Estate Group Notes Slice Reducer
    getNotes(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getNotesSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<NoteDto>>
    ) {
      state.noteResult = action.payload;
    },
    // Delete Estate Group
    deleteEstateGroup(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Delete Estate Group Note Slice Reducer
    deleteNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Add Estate Group Note Slice Reducer
    addNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Update Estate Group Note Slice Reducer
    updateNote(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
  },
});

// Estate Group Action
export const estateGroupActions = estateGroupSlice.actions;

// Estate Group Reducer
const estateGroupReducer = estateGroupSlice.reducer;

export default estateGroupReducer;
