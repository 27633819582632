import { all, takeLatest } from "redux-saga/effects";

import { API_ENDPOINTS } from "@constants";
import { salesContractsActions } from "./salesContractSlice";
import { request } from "@/utils";

const {
  getAllSalesContracts,
  getAllSalesContractsSuccess,
  addSalesContract,
  updateSalesContract,
  getSingleSalesContract,
  getSingleSalesContractSuccess,
  addSalesContractNote,
  updateSalesContractNote,
  getSalesContractNotes,
  getSalesContractNotesSuccess,
  deleteSalesContractNote,
  deleteSalesContract,
} = salesContractsActions;

// Get All Sales Contracts Saga
const getAllSalesConractsSaga = request({
  APIUrl: API_ENDPOINTS.GET.salesContract.all,
  requestType: "get",
  successFunction: getAllSalesContractsSuccess,
});

// Add Sales Contract Saga
const addSalesContractSaga = request({
  APIUrl: API_ENDPOINTS.POST.salesContract.add,
  requestType: "post",
  showNotification: true,
});

// Update Sales Contract Saga
const updateSalesContractSaga = request({
  APIUrl: API_ENDPOINTS.POST.salesContract.update,
  requestType: "post",
  showNotification: true,
});

// Get Single Sales Contract Saga
const getSingleSalesContractSaga = request({
  APIUrl: API_ENDPOINTS.GET.salesContract.single,
  requestType: "get",
  successFunction: getSingleSalesContractSuccess,
});

// Add Sales Contract Note
const addSalesContractNoteSaga = request({
  APIUrl: API_ENDPOINTS.POST.salesContract.addNote,
  requestType: "post",
  showNotification: true,
});

// Update Sales Contract Note
const updateSalesContractNoteSaga = request({
  APIUrl: API_ENDPOINTS.POST.salesContract.updateNote,
  requestType: "post",
  showNotification: true,
});

// Get All/Single Sales Contract Notes
const getAllSalesContractNoteSaga = request({
  APIUrl: API_ENDPOINTS.GET.salesContract.notes,
  requestType: "get",
  successFunction: getSalesContractNotesSuccess,
});

// Delete Sales Contract Note
const deleteSalesContractNoteSaga = request({
  APIUrl: API_ENDPOINTS.POST.salesContract.deleteNote,
  requestType: "post",
  showNotification: true,
});

// Delete Sales Contract
const deleteSalesContractSaga = request({
  APIUrl: API_ENDPOINTS.POST.salesContract.delete,
  requestType: "post",
  showNotification: true,
});

export default function* salesContractSaga() {
  yield all([
    takeLatest(getAllSalesContracts.type, getAllSalesConractsSaga),
    takeLatest(addSalesContract.type, addSalesContractSaga),
    takeLatest(updateSalesContract.type, updateSalesContractSaga),
    takeLatest(getSingleSalesContract.type, getSingleSalesContractSaga),
    takeLatest(addSalesContractNote.type, addSalesContractNoteSaga),
    takeLatest(updateSalesContractNote.type, updateSalesContractNoteSaga),
    takeLatest(getSalesContractNotes.type, getAllSalesContractNoteSaga),
    takeLatest(deleteSalesContractNote.type, deleteSalesContractNoteSaga),
    takeLatest(deleteSalesContract.type, deleteSalesContractSaga),
  ]);
}
