import {
  Action,
  combineReducers,
  configureStore,
  Middleware,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import {
  ActionToString,
  ErrorToString,
  ReduxLoggerOptions,
  StateToString,
  createLogger,
} from "redux-logger";

import authReducer from "@pages/User/authSlice";
import loadingReducer from "@store/loading/loadingSlice";
import myEstateReducer from "@pages/RealEstates/estateSlice";

import rootSaga from "./rootSaga";
import { settingReducer } from "./settings/settingSlice";
import appReducer from "./loading/appSlice";
import estateGroupReducer from "@pages/RealEstateGroups/estateGroupSlice";
import rentContractReducer from "@pages/RentContracts/rentContractSlice";
import customersReducer from "@pages/Customers/customerSlice";
import salesContractsReducer from "@pages/SalesContracts/salesContractSlice";
import billReducer from "@pages/ExpenseManagement/billSlice";
import employeesReducer from "@pages/EmployeesManagement/empSlice";
import catchBondReducer from "./catchBond/receiptSlice";
import statementReducer from "@pages/FinancialStatement/statementSlice";
import reportReducer from "./reports/reportSlice";
import rateReducer from "@pages/RateRequests/rateSlice";
import previewReducer from "@pages/PreviewRequests/previewSlice";
import marketReducer from "@pages/MarketDemands/marketSlice";
import planReducer from "@pages/Plans/planSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  loading: loadingReducer,
  myEstate: myEstateReducer,
  estateGroups: estateGroupReducer,
  settings: settingReducer,
  rentContract: rentContractReducer,
  salesContract: salesContractsReducer,
  customers: customersReducer,
  bills: billReducer,
  employees: employeesReducer,
  catchBond: catchBondReducer,
  statement: statementReducer,
  reports: reportReducer,
  rate: rateReducer,
  previews: previewReducer,
  market: marketReducer,
  plans: planReducer,
});

const sagaMiddleware = createSagaMiddleware();

// Redux logger config
const logger: Middleware<ReduxLoggerOptions> = createLogger({
  level: "info",
  collapsed: true,
  duration: true,
  timestamp: true,
  logger: process.env.NODE_ENV === "development" ? console : undefined,
  colors: {
    title: (action: PayloadAction<ActionToString>) =>
      action.type === "error" ? "#F20404" : "#4CAF50",
    prevState: (state: StateToString) => "#9E9E9E",
    action: (action: ActionToString) => "#03A9F4",
    nextState: (state: StateToString) => "#4CAF50",
    error: (error: ErrorToString) => "#F20404",
  },
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
    sagaMiddleware,
    logger,
  ],
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
