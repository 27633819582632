import { MenuItem } from "@models";
import { t } from "i18next";
export const capitalizeString = (str: string): string => {
  if (!str) return "";

  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

//Find the menuItem with the attribute value from the MenuList based on the attribute value
export function getRouteItemInRoutesListByProperty(
  menuList: Array<MenuItem>,
  key: string,
  value: string
) {
  let stack: Array<any> = [];
  stack = stack.concat(menuList);
  let res;
  while (stack.length) {
    let cur = stack.shift();
    if (cur.children && cur.children.length > 0) {
      stack = cur.children.concat(stack);
    }
    if (value === cur[key]) {
      res = cur;
    }
  }
  return res;
}

// Get Notification Type Text
export function getNotificationTypeText(type: string) {
  switch (type) {
    case "request":
      return t("Request");
    case "chat":
      return t("Chat");
    case "fund_request":
      return t("FundRequest");
    case "fund_offer":
      return t("FundOffer");
    case "rate_offer":
      return t("RateOffer");
    case "rate_estate":
      return t("RateEstate");
    case "employee":
      return t("Employee");
    case "estate":
      return t("Estate");
    default:
      return t("Offer");
  }
}
