import { CityDto, DistrictDto, RegionDto, RequestPayloadDto } from "@models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SettingState {
  banksResult: any;
  estateTypeResult: any;
  operationTypeResult: {
    id: number;
    advertising_purpose: string;
  }[];
  comfortResult: any;
  cutomerResult: any;
  googleAddress: any;
  estates: any;
  group: any;
  contracts: any;
  employees: any;
  contractExpense: any;
  contractInvoice: any;
  dashboard: any;
  cities: Pick<CityDto, "id" | "name">[];
  regions: RegionDto[];
  districts: DistrictDto[];
  new_cities: Pick<CityDto, "id" | "name">[];
  new_regions: RegionDto[];
  new_districts: DistrictDto[];
  property_face: {
    id: number;
    direction: string;
  }[];
  property_age: {
    id: number;
    age: string;
  }[];
  property_usages: {
    id: number;
    type: string;
  }[];
}

const initialState: Partial<SettingState> = {
  cities: [],
  regions: [],
  districts: [],
  new_cities: [],
  new_regions: [],
  new_districts: [],
  estates: [],
  cutomerResult: [],
};

const setting = createSlice({
  name: "Setting",
  initialState,
  reducers: {
    // Banks Reducer
    getBanks(_state, _action: PayloadAction<any>) {},
    getBanksSuccess(state, action: PayloadAction<any>) {
      state.banksResult = action.payload;
    },
    // Estate Type Reducer
    getEstateType(_state, _action: PayloadAction<any>) {},
    getEstateTypeSuccess(state, action: PayloadAction<any>) {
      state.estateTypeResult = action.payload;
    },
    // Operation Type Reducer
    getOperationType(_state, _action: PayloadAction<any>) {},
    getOperationTypeSuccess(state, action: PayloadAction<any>) {
      state.operationTypeResult = action.payload;
    },
    // Comfort Reducer
    comfort(_state, _action: PayloadAction<any>) {},
    comfortSuccess(state, action: PayloadAction<any>) {
      state.comfortResult = action.payload;
    },
    // Get Customer By Type Reducer
    getCustomerByType(_, _action: PayloadAction<any>) {},
    getCustomerByTypeSuccess(state, action: PayloadAction<any>) {
      state.cutomerResult = action.payload;
    },
    // Get Address From Google Map Lat & Long
    getGoogleMapAddress(_, _action: PayloadAction<any>) {},
    getGoogleMapAddressSuccess(state, action: PayloadAction<any>) {
      state.googleAddress = action.payload;
    },
    // Get Estates Depend on Estate Type (pay or tent)
    getEstates(_state, _action: PayloadAction<RequestPayloadDto>) {},
    getEstatesSuccess(state, action: PayloadAction<any>) {
      state.estates = action.payload;
    },
    // Get Group Estates Select
    getGroupEstates() {},
    getGroupEstatesSuccess(state, action: PayloadAction<any>) {
      state.group = action.payload;
    },
    // Get Contract Select
    getContracts() {},
    getContractsSuccess(state, action: PayloadAction<any>) {
      state.contracts = action.payload;
    },
    // Get Employees Select
    getEmployees() {},
    getEmployeesSuccess(state, action: PayloadAction<any>) {
      state.employees = action.payload;
    },
    // Get Contract Expense Select
    getContractExpense(_state, _action: PayloadAction<RequestPayloadDto>) {},
    getContractExpenseSuccess(state, action: PayloadAction<any>) {
      state.contractExpense = action.payload;
    },
    // Get Contract Invoice Select
    getContractInvoice(_state, _action: PayloadAction<RequestPayloadDto>) {},
    getContractInvoiceSuccess(state, action: PayloadAction<any>) {
      state.contractInvoice = action.payload;
    },
    // Get Dashboard Data
    getDashboard() {},
    getDashboardSuccess(state, action: PayloadAction<any>) {
      state.dashboard = action.payload;
    },

    /*********** New Regions, Cities, and Districts APIs ************/
    getNewCities(_state, _action: PayloadAction<RequestPayloadDto>) {},
    getNewCitiesSuccess(state, action: PayloadAction<any>) {
      state.new_cities = action.payload;
    },

    getNewRegions() {},
    getNewRegionsSuccess(state, action: PayloadAction<any>) {
      state.new_regions = action.payload;
    },

    getNewDistricts(_state, _action: PayloadAction<RequestPayloadDto>) {},
    getNewDistrictsSuccess(state, action: PayloadAction<any>) {
      state.new_districts = action.payload;
    },

    // Get Property Face
    getPropertyFace() {},
    getPropertyFaceSuccess(state, action: PayloadAction<any>) {
      state.property_face = action.payload;
    },

    // Get Property Age
    getPropertyAge() {},
    getPropertyAgeSuccess(state, action: PayloadAction<any>) {
      state.property_age = action.payload;
    },

    // Get Property Usages
    getPropertyUsages() {},
    getPropertyUsagesSuccess(state, action: PayloadAction<any>) {
      state.property_usages = action.payload;
    },

    /*********** Ejar APIs ************/
    getCities(_state, _action: PayloadAction<RequestPayloadDto>) {},
    getCitiesSuccess(state, action: PayloadAction<any>) {
      state.cities = action.payload;
    },

    getRegions() {},
    getRegionsSuccess(state, action: PayloadAction<any>) {
      state.regions = action.payload;
    },

    getDistricts(_state, _action: PayloadAction<RequestPayloadDto>) {},
    getDistrictsSuccess(state, action: PayloadAction<any>) {
      state.districts = action.payload;
    },

    // Iam Authenticate
    iamAuthenticate(_state, _action: PayloadAction<RequestPayloadDto>) {},

    // Refresh Firebase Token
    refreshFirebaseToken(_state, _action: PayloadAction<RequestPayloadDto>) {},
  },
});

// Actions
export const settingAction = setting.actions;

// Reducer
export const settingReducer = setting.reducer;
