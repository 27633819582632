import { NavigateFunction } from "react-router-dom";
import { RootState } from "@store";
import { User } from "@models/user";
import { AUTH_ROUTE, RequestPayloadDto, SuccessPayload } from "@models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { localStorageHelper } from "@utils/localStorageHelper";

export interface NotificationDto {
  id: number;
  user_id: number;
  type_id: number;
  title: string;
  type: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  time: string;
}

export interface MessageDto {
  id: number;
  title: string;
  body: string;
  sender_id: number;
  receiver_id: number;
  sender_name: string;
  receiver_name: string;
  sender_photo: string;
  receiver_photo: string;
  created_at: string;
  from_me: 0 | 1;
  display_name: null;
  display_logo: string;
  display_id: number;
  time: string;
  count_not_read: number;
}

export interface LoginPayload {
  email: string;
  password: string;
}
export interface LogoutPayload {
  navigate: NavigateFunction;
}
export interface AuthState {
  isLoggedIn: boolean;
  logging?: boolean;
  currentUser?: User;
  login?: any;
  notifictions?: SuccessPayload<NotificationDto>;
  messages?: MessageDto[];
  messageDetail?: MessageDto[];
}

const initialState: AuthState = {
  isLoggedIn: false,
  logging: false,
  login: {},
  currentUser: localStorageHelper.get("user"),
  notifictions: {},
  messages: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(_state: AuthState, _action: PayloadAction<RequestPayloadDto>) {},
    loginSuccess(state: AuthState, action: PayloadAction<any>) {
      state.isLoggedIn = true;
      state.login = action.payload;
    },
    verifyCode(_state: AuthState, _action: PayloadAction<RequestPayloadDto>) {},
    updatePass(_state: AuthState, _action: PayloadAction<RequestPayloadDto>) {},
    logout(state: AuthState, action: PayloadAction<LogoutPayload>) {
      state.isLoggedIn = false;
      state.currentUser = undefined;
      action.payload.navigate(AUTH_ROUTE.LOGIN);
    },

    // Forgot Password
    forgotPassword(_state, _action: PayloadAction<RequestPayloadDto>) {},
    resetPassword(_state, _action: PayloadAction<RequestPayloadDto>) {},

    // Notifications
    getNotifications(_state, _action: PayloadAction<RequestPayloadDto>) {},
    getNotificationsSuccess(
      state,
      action: PayloadAction<SuccessPayload<NotificationDto>>
    ) {
      state.notifictions = action.payload;
    },

    // Messages
    getMessages(_state, _action: PayloadAction<RequestPayloadDto>) {},
    getMessagesSuccess(state, action: PayloadAction<MessageDto[]>) {
      state.messages = action.payload;
    },

    messageDetail(_state, _action: PayloadAction<RequestPayloadDto>) {},
    messageDetailSuccess(state, action: PayloadAction<MessageDto[]>) {
      state.messageDetail = action.payload;
    },

    sendMessage(_state, _action: PayloadAction<RequestPayloadDto>) {},
  },
});

// Actions
export const authActions = authSlice.actions;

// Selectors
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectIsLogging = (state: RootState) => state.auth.logging;

// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
