import { FC } from "react";
import Logo from "./Logo";
import { Layout } from "antd";
import { useTypedSelector } from "@hooks";
import Menu from "./Menu";
const { Sider } = Layout;

const SiderMenu: FC = () => {
  const { sidebarCollapsed } = useTypedSelector((state) => state.app);
  return (
    <Sider
      theme="light"
      trigger={null}
      collapsible
      collapsed={sidebarCollapsed}
      style={{ width: "250px", maxWidth: "250px", minWidth: "250px" }}
    >
      <Logo collapsed={sidebarCollapsed} />
      <Menu />
    </Sider>
  );
};

export default SiderMenu;
