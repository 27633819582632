import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { useAppDispatch } from "@/hooks";
import { settingAction } from "@/store/settings/settingSlice";

const { confirm } = Modal;

const IamModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isIamComplete = () => {
    confirm({
      title: t("AccountVerification"),
      icon: <ExclamationCircleOutlined />,
      content: t(
        "YouMustAuthenticateYourAccountThroughNationalAccessInAccordanceWithTheRequirementsOfTheGeneralRealEstateAuthority,AndYouWillReceiveAVerificationBadgeInYourAccountToMakeYourOffersMoreReliable."
      ),
      okText: t("Authenticate"),
      cancelText: t("Cancel"),
      onOk() {
        console.log("OK");
        dispatch(
          settingAction.iamAuthenticate({
            callback: (res: any) => {
              console.log("callback: ", res);
              // Redirect to res URL
              window.location.href = res;
            },
          })
        );
      },
      //   onCancel() {
      //     console.log("Cancel");
      //   },
    });
  };

  return isIamComplete;
};

export default IamModal;
