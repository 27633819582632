import { FC } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import s from "./index.module.scss";
interface LogoProps {
  collapsed: boolean;
}

const Logo: FC<LogoProps> = ({ collapsed }) => {
  const { t } = useTranslation();
  return (
    <div className={s.sidebarLogoCont}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/${
          collapsed ? "small-logo.png" : "logo-white.png"
        }`}
        className={cn(s.sidebarLogo, {
          [s.sidebarLogoColl]: collapsed,
        })}
        alt={t("AqarzLogo")}
        title={t("AqarzLogo")}
      />
    </div>
  );
};

export default Logo;
