import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestsEnum } from "@models";
import { RootState } from "@store";

export interface RequestDto {
  name: string;
  inProgress: boolean;
  error?: string | null;
}

export interface LoadingState {
  requests:
    | {
        [key in RequestsEnum]: RequestDto;
      }
    | {};
}

export interface MultipleRequestDto {
  [key: string]: RequestDto;
}

const initialState: LoadingState = {
  requests: {},
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    changeRequestLoading(state: any, action: PayloadAction<RequestDto>) {
      const { name, inProgress } = action.payload;
      let newRequestsState = state.requests;
      newRequestsState[name] = { name, inProgress };
      state.requests = newRequestsState;
    },
    // request finished
    requestFailed(state: any, action: PayloadAction<RequestDto>) {
      state.requests = state.requests.map((request: any) =>
        request.name === action.payload.name
          ? {
              ...request,
              error: action.payload.error,
              inProgress: false,
            }
          : request
      );
    },
  },
});

// Actions
export const loadingActions = loadingSlice.actions;

// Reducer
const loadingReducer = loadingSlice.reducer;

// Get Single Request Loading State
export const getSingleRequest = (
  { loading: { requests } }: RootState,
  requestName: RequestsEnum
) => {
  const req = requests[requestName as keyof typeof requests];
  if (req === undefined) {
    return { name: requestName, inProgress: false };
  }
  return req;
};

// Get Multiple Request Loading State
export const getRequests = (
  { loading: { requests } }: RootState,
  requestNames: RequestsEnum[]
) => {
  let reqs: MultipleRequestDto = {};

  requestNames.forEach((requestName) => {
    const req = requests[requestName as keyof typeof requests];
    if (req === undefined) {
      return (reqs[requestName] = { name: requestName, inProgress: false });
    }
    return (reqs[requestName] = req);
  });

  return reqs;
};

export default loadingReducer;
