import { FC } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "@hooks";
import { AUTH_ROUTE, NOT_FOUND } from "@models";

interface RequireAuthProps {
  allowedRoles: string[];
}

const RequireAuth: FC<RequireAuthProps> = ({ allowedRoles = ["admin"] }) => {
  const auth = useAuth();
  const location = useLocation();

  return auth?.role && allowedRoles?.includes(auth?.role) ? (
    <Outlet />
  ) : auth && auth!.api_token ? (
    <Navigate to={NOT_FOUND.UNAUTHORIZED} state={{ from: location }} replace />
  ) : (
    <Navigate to={AUTH_ROUTE.LOGIN} state={{ from: location }} replace />
  );
};

export default RequireAuth;
