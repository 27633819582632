import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "@store";
import App from "./App";

import "./i18n";

const el = document.getElementById("root");
const root = ReactDOM.createRoot(el as HTMLElement);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Routes>
        <Route path="/*" element={<App />}></Route>
      </Routes>
    </Provider>
  </BrowserRouter>
);
