import { FC, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Scrollbar } from "react-scrollbars-custom";
import { Menu } from "antd";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { MenuItem } from "@models";
import { useAuth } from "@hooks";
import menuList from "@constants/menuList";
import { CookiesHelper } from "@utils/CookiesHelper";

import s from "./index.module.scss";

const { SubMenu } = Menu;

const SiderMenu: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname;

  const [menuTreeNode, setMenuTreeNode] = useState<any>();
  const [openKey, setMenuOpenKey] = useState<string[]>([]);

  const language = CookiesHelper.get("i18next");

  const user = useAuth();
  const role = user?.role ?? "";

  // add menu items to menu list
  useEffect(() => {
    setMenuTreeNode(getMenuNodes(menuList));
  }, []);

  // filter menu item
  const filterMenuItem = (item: MenuItem) => {
    const { roles } = item;
    if (!roles || roles.includes(role)) {
      return true;
    } else if (item.children) {
      //If the current user has permission to a sub-item of this item
      return !!item.children.find((child: any) => roles.includes(child.role));
    }
    return false;
  };

  // menu rendering
  const getMenuNodes = (menuItems: typeof menuList) => {
    // Get the routing path of the current request
    return menuItems.reduce((pre: Array<any>, item) => {
      if (filterMenuItem(item)) {
        if (!item.children) {
          pre.push(
            <Menu.Item key={item.path} icon={item.icon ?? <></>}>
              <Link to={item.path}>
                <span>{t(item.title)}</span>
              </Link>
            </Menu.Item>
          );
        } else {
          // Find a child item that matches the current request path
          const cItem = item.children.find(
            (cItem) => path.indexOf(cItem.path) === 0
          );
          //  If it exists, it means that the sublist of the current item needs to be opened

          if (cItem) {
            setMenuOpenKey((pre) => [...pre, item.path]);
          }

          // Add <SubMenu> to pre
          pre.push(
            <SubMenu
              key={item.path}
              icon={item.icon ?? <></>}
              title={<span>{t(item.title)}</span>}
              className={`${i18next.language === "ar" && s.subMenu}`}
            >
              {getMenuNodes(item.children)}
            </SubMenu>
          );
        }
      }
      return pre;
    }, []);
  };

  return (
    <div className={s.siderContainer}>
      <Scrollbar removeTrackYWhenNotUsed rtl={language === "ar"}>
        {menuTreeNode?.map((item: JSX.Element) => (
          <Menu
            key={`${item.key}-1`}
            mode="inline"
            selectedKeys={[path]}
            defaultOpenKeys={openKey}
          >
            {item}
          </Menu>
        ))}
      </Scrollbar>
    </div>
  );
};

export default SiderMenu;
