import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RealEstateDto, RequestPayloadDto, SuccessPayload } from "@/models";

export interface RateRequestDto {
  id: number;
  estate_type_id: number;
  name: string;
  email: string;
  mobile: string;
  note: string;
  lat: string;
  lan: string;
  address: string;
  status: string;
  operation_type_id: number;
  user_id: number;
  estate_id: number;
  purpose_evaluation: string;
  entity_evaluation: string;
  area: string;
  estate_type_name: string;
  operation_type_name: string;
  estate_use_type: string;
  time: string;
  estate: RealEstateDto;
}

interface InitialStateDto {
  rateRequests?: SuccessPayload<RateRequestDto>;

  singleRateRequest?: RateRequestDto;
}

const initialState: InitialStateDto = {};

const rateSlice = createSlice({
  name: "rate",
  initialState,
  reducers: {
    getRateRequests: (_state, _action: PayloadAction<RequestPayloadDto>) => {},
    getRateRequestsSuccess: (
      state,
      action: PayloadAction<SuccessPayload<RateRequestDto>>
    ) => {
      state.rateRequests = action.payload;
    },

    getSingleRateRequest: (
      _state,
      _action: PayloadAction<RequestPayloadDto>
    ) => {},
    getSingleRateRequestSuccess: (
      state,
      action: PayloadAction<RateRequestDto>
    ) => {
      state.singleRateRequest = action.payload;
    },

    deleteRateRequest: (
      _state,
      _action: PayloadAction<RequestPayloadDto>
    ) => {},
  },
});

export const rateActions = rateSlice.actions;

export default rateSlice.reducer;
