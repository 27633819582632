import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RequestPayloadDto, SuccessPayload } from "@/models";

export interface PreviewRequestDto {
  id: number;
  user_id: number;
  user_name: string;
  user_mobile: string;
  estate_name: string;
  estate_id: string;
  estate_link: string;
  time: string;
  created_at: string;
  times: string[];
  accept_time: any;
  address: string;
  area: string;
  city_name: string;
  estate_comforts: any;
  estate_type_name: string;
  estate_user_id: number;
  neighborhood_name: string;
  operation_type_name: string;
  status: "pending" | "reject" | "accept" | "complete" | "cancel";
  total_price: string;
}

interface InitialStateDto {
  previewRequests?: SuccessPayload<PreviewRequestDto>;
  singlePreviewRequest?: PreviewRequestDto;
}

const initialState: InitialStateDto = {};

const previewSlice = createSlice({
  name: "preview",
  initialState,
  reducers: {
    // Get all preview requests
    allPreviewRequests: (
      _state,
      _action: PayloadAction<RequestPayloadDto>
    ) => {},
    allPreviewRequestsSuccess: (
      state,
      action: PayloadAction<SuccessPayload<PreviewRequestDto>>
    ) => {
      state.previewRequests = action.payload;
    },

    // Delete preview request
    deletePreviewRequest: (
      _state,
      _action: PayloadAction<RequestPayloadDto>
    ) => {},

    // Select time from preview requests
    selectTime: (_state, _action: PayloadAction<RequestPayloadDto>) => {},

    // Get Preview Request Details
    previewRequestDetails: (
      _state,
      _action: PayloadAction<RequestPayloadDto>
    ) => {},
    previewRequestDetailsSuccess: (
      state,
      action: PayloadAction<PreviewRequestDto>
    ) => {
      state.singlePreviewRequest = action.payload;
    },

    // Update Preview Request
    updatePreviewRequest: (
      _state,
      _action: PayloadAction<RequestPayloadDto>
    ) => {},
  },
});

export const {
  allPreviewRequests,
  allPreviewRequestsSuccess,
  deletePreviewRequest,
  selectTime,
  previewRequestDetails,
  previewRequestDetailsSuccess,
  updatePreviewRequest,
} = previewSlice.actions;

export default previewSlice.reducer;
