import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  MessageOutlined,
  BellOutlined,
} from "@ant-design/icons";
import {
  Menu,
  Dropdown,
  Modal,
  Layout,
  Avatar,
  Space,
  Divider,
  QRCode,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { useAuth, useTypedSelector } from "@hooks";
import { authActions } from "@pages/User/authSlice";
import { AUTH_ROUTE, createPath } from "@/models";
import Hamburger from "../Hamburger";
import LangDropDown from "../Language";

import s from "./index.module.scss";
import { download } from "@/utils";

const { Header } = Layout;

const LayoutHeader: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sidebarCollapsed } = useTypedSelector((state) => state.app);
  const currentUser = useAuth();

  // handle logout
  const handleLogout = () => {
    Modal.confirm({
      title: t("logout"),
      content: t("areYouSureToLogout"),
      okText: t("yes"),
      cancelText: t("cancel"),
      onOk: () => {
        dispatch(authActions.logout({ navigate }));
      },
    });
  };

  // user menu
  const UserMenu: FC = () => (
    <Menu>
      <Menu.Item key="Profile">
        <Link
          to={createPath({
            path: AUTH_ROUTE.PROFILE,
          })}
        >
          <UserOutlined className={s.userMenuIcon} /> {t("Profile")}
        </Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        <LogoutOutlined className={s.userMenuIcon} /> {t("logout")}
      </Menu.Item>
      <Divider />
      <Menu.Item key="QRcode" disabled>
        {/* <Typography.Text type="secondary">{t("DownloadApp")}</Typography.Text> */}
        <QRCode
          errorLevel="H"
          value={"https://aqarz.sa/download"}
          icon={currentUser?.company_logo}
          iconSize={30}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Header
      className={cn(s.header, {
        [s.headerLayoutColl]: sidebarCollapsed,
        [s.headerLayoutNotColl]: !sidebarCollapsed,
      })}
    >
      <Hamburger />
      <div className={s.rightMenu}>
        <div className="dropdown-wrap space-align-container">
          <Space align="center" size={20}>
            <Link
              to={createPath({
                path: AUTH_ROUTE.NOTIFICATIONS,
              })}
              className={s.notification}
            >
              <BellOutlined />
            </Link>
            <Link
              to={createPath({
                path: AUTH_ROUTE.MESSAGES,
              })}
              className={s.notification}
            >
              <MessageOutlined />
            </Link>
            <Dropdown overlay={<UserMenu />}>
              <div className={s.userWrap}>
                <Avatar
                  shape="square"
                  size={30}
                  src={currentUser?.logo}
                  icon={<UserOutlined />}
                />
                <span className={s.userName}>
                  {currentUser &&
                    currentUser?.role &&
                    (currentUser?.name || currentUser?.mobile)}
                </span>
                <DownOutlined />
              </div>
            </Dropdown>
            <LangDropDown />
          </Space>
        </div>
      </div>
    </Header>
  );
};

export default LayoutHeader;
