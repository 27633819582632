import { authActions } from "./authSlice";
import { CookiesHelper } from "@utils/CookiesHelper";
import {
  Effect,
  StrictEffect,
  takeLatest,
  call,
  all,
  delay,
  put,
} from "redux-saga/effects";
import { axiosClient } from "@constants";
import { API_ENDPOINTS } from "@constants";
import { RequestPayloadDto } from "@models";
import { Modal } from "antd";
import { t } from "i18next";
import { loadingActions } from "@store/loading/loadingSlice";
import { AxiosResponse } from "axios";
import { localStorageHelper, request } from "@/utils";

const {
  login,
  loginSuccess,
  verifyCode,
  updatePass,
  logout,
  getNotifications,
  getNotificationsSuccess,
  getMessages,
  getMessagesSuccess,
  messageDetail,
  messageDetailSuccess,
  sendMessage,
  forgotPassword,
  resetPassword,
} = authActions;

function* handleLoginSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const res: AxiosResponse = yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.auth.login,
      payload.data
    );

    yield put(
      loginSuccess({
        ...res,
      })
    );

    const user = {
      id: res.data.id,
      logo: res.data.logo,
      user_name: res.data.user_name,
      name: res.data.name,
      mobile: res.data.mobile,
      api_token: res.data.api_token,
      role: "admin",
    };

    localStorageHelper.set("user", res.data);

    // if ((payload.data as FormData).get("rememberMe")) {
    //   CookiesHelper.set("user", JSON.stringify(user), {
    //     expires: 365,
    //   });
    // } else {
    //   CookiesHelper.set("user", JSON.stringify(user));
    // }

    payload?.callback?.();
  } catch (error: any) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

function* verifyCodeSaga({ type, payload }: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const res: AxiosResponse = yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.auth.verify,
      payload.data
    );
    console.log("🚀 ~ file: authSaga.ts ~ line 87 ~ res", res);

    const user = {
      id: res.data.id,
      logo: res.data.logo,
      user_name: res.data.user_name,
      name: res.data.name,
      mobile: res.data.mobile,
      api_token: res.data.api_token,
      role: "admin",
    };

    localStorageHelper.set("user", res.data);

    // if ((payload.data as FormData).get("rememberMe")) {
    //   CookiesHelper.set("user", JSON.stringify(user), {
    //     expires: 365,
    //   });
    // } else {
    //   CookiesHelper.set("user", JSON.stringify(user));
    // }

    payload?.callback?.();
  } catch (error: any) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

function* updatePassSaga({ type, payload }: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.auth.updatePass,
      payload.data
    );

    payload?.callback?.();
  } catch (error: any) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

function* handleLogout() {
  yield delay(500);
  localStorageHelper.clearItem("user");
  localStorageHelper.clearItem("token");
}

const getNotificationsSaga = request({
  APIUrl: API_ENDPOINTS.GET.settings.notifications,
  requestType: "get",
  successFunction: getNotificationsSuccess,
});

const getMessagesSaga = request({
  APIUrl: API_ENDPOINTS.GET.settings.MESSAGES,
  requestType: "get",
  successFunction: getMessagesSuccess,
});

const messageDetailSaga = request({
  APIUrl: API_ENDPOINTS.GET.settings.SINGLE_MESSAGE,
  requestType: "get",
  successFunction: messageDetailSuccess,
});

const sendMessageSaga = request({
  APIUrl: API_ENDPOINTS.POST.settings.SEND_MESSAGE,
  requestType: "post",
  successFunction: messageDetailSuccess,
});

const forgotPasswordSaga = request({
  APIUrl: API_ENDPOINTS.POST.auth.forgetPass,
  requestType: "post",
});

const resetPasswordSaga = request({
  APIUrl: API_ENDPOINTS.POST.auth.resetPass,
  requestType: "post",
  showNotification: true,
});

export default function* authSaga(): Generator<StrictEffect> {
  yield all([
    takeLatest(login.type, handleLoginSaga),
    takeLatest(verifyCode.type, verifyCodeSaga),
    takeLatest(updatePass.type, updatePassSaga),
    takeLatest(logout.type, handleLogout),
    takeLatest(getNotifications.type, getNotificationsSaga),
    takeLatest(getMessages.type, getMessagesSaga),
    takeLatest(messageDetail.type, messageDetailSaga),
    takeLatest(sendMessage.type, sendMessageSaga),
    takeLatest(forgotPassword.type, forgotPasswordSaga),
    takeLatest(resetPassword.type, resetPasswordSaga),
  ]);
}
