import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MainRequestDto, RequestPayloadDto, SuccessPayload } from "@models";
import { BillDto } from "@models/bills";

interface initialStateDto {
  allBills?: SuccessPayload<BillDto>;
  singleBill?: BillDto;
}
const initialState: initialStateDto = {};

const billSlice = createSlice({
  name: "billSlice",
  initialState,
  reducers: {
    // Add Bill Reducer
    addBill(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    // Get All Bills Reducer
    getAllBills(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getAllBillsSuccess(
      state: initialStateDto,
      action: PayloadAction<SuccessPayload<BillDto>>
    ) {
      state.allBills = action.payload;
    },
    // Get Single Bill Reducer
    getSingleBill(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getSingleBillSuccess(
      state: initialStateDto,
      action: PayloadAction<BillDto>
    ) {
      state.singleBill = action.payload;
    },
    // Delete Bill Reducer
    deleteBill(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
  },
});

// Bill Actions
export const billActions = billSlice.actions;

// Bill Reducer
const billReducer = billSlice.reducer;

export default billReducer;
