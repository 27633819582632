/* eslint-disable import/no-anonymous-default-export */
import { InputNumber, InputNumberProps } from "antd";

interface Props extends InputNumberProps {}

export default (props: Props) => {
  return (
    <InputNumber
      {...props}
      style={{ width: "100%" }}
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      parser={(value) => value?.replace(/\$\s?|(,*)/g, "") as string}
    />
  );
};
