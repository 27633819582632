import i18n from "../i18n";

const { t } = i18n;

export const config = {
  HOST: "localhost",
  PORT: 3000,
  API_URL: "",
  APP: {
    htmlAttributes: {
      lang: i18n.language,
      dir: i18n.dir(i18n.language),
    },
    title: t("Title"),
    titleTemplate: `${t("Aqarz")} - %s`,
    meta: [
      {
        name: "description",
        content: "App Description.",
      },
    ],
  },
};
