import { FC } from "react";
import { useTranslation } from "react-i18next";
import arabic from "react-date-object/calendars/arabic";
import arabic_ar from "react-date-object/locales/arabic_ar";
import arabic_en from "react-date-object/locales/arabic_en";
import DatePicker from "react-multi-date-picker";

import i18n from "@/i18n";

interface IDatePickerProps extends React.ComponentProps<typeof DatePicker> {}

const HijriDatePicker: FC<IDatePickerProps> = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <DatePicker
      {...props}
      locale={i18n.language === "en" ? arabic_en : arabic_ar}
      calendar={arabic}
      digits={arabic_en.digits}
      placeholder={t("SelectHijriDate")}
      format="YYYY-MM-DD"
    />
  );
};

export default HijriDatePicker;
