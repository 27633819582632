import { EstateComfortDto, RequestPayloadDto, SuccessPayload } from "@/models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface MarketDemandDto {
  id: number;
  operation_type_id: number;
  user_id: number;
  request_type: string;
  estate_type_id: number;
  area_from: number;
  area_to: number;
  price_from: number;
  price_to: number;
  room_numbers: number;
  owner_name: string;
  owner_mobile: string;
  display_owner_mobile: string;
  note: string;
  seen_count: number;
  lat: string;
  lan: string;
  city_id: number;
  neighborhood_id: number;
  address: string;
  status: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  state_id: number;
  bathroom_numbers: number;
  has_my_offer: number;
  in_fav: number;
  operation_type_name: string;
  estate_type_name: string;
  city_name: string;
  neighborhood_name: string;
  time: string;
  link: string;
  estate_comforts: EstateComfortDto[];
}

interface InitialStateDto {
  marketDemandsList?: SuccessPayload<MarketDemandDto>;
  reltedEstates?: any;
}

const initialState: InitialStateDto = {};

const marketSlice = createSlice({
  name: "marketDemands",
  initialState,
  reducers: {
    // Get all market requests
    allMarketRequests: (
      _state,
      _action: PayloadAction<RequestPayloadDto>
    ) => {},
    allMarketRequestsSuccess: (
      state,
      action: PayloadAction<SuccessPayload<MarketDemandDto>>
    ) => {
      state.marketDemandsList = action.payload;
    },

    // Get Related Estates and add offer
    getRelatedEstates: (
      _state,
      _action: PayloadAction<RequestPayloadDto>
    ) => {},
    getRelatedEstatesSuccess: (
      state,
      action: PayloadAction<SuccessPayload<MarketDemandDto>>
    ) => {
      state.reltedEstates = action.payload;
    },

    // Send Offer
    sendOffer: (_state, _action: PayloadAction<RequestPayloadDto>) => {},
  },
});

export const {
  allMarketRequests,
  allMarketRequestsSuccess,
  getRelatedEstates,
  getRelatedEstatesSuccess,
  sendOffer,
} = marketSlice.actions;

export default marketSlice.reducer;
