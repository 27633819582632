/* eslint-disable import/no-anonymous-default-export */
import { Select, SelectProps } from "antd";
import { valueType } from "antd/lib/statistic/utils";
import { useTranslation } from "react-i18next";

interface ISelectProps extends SelectProps<valueType> {
  options: SelectProps["options"];
  allowSearch?: boolean;
  placeholder?: string;
}

export default (props: ISelectProps) => {
  const { options = [], allowSearch, placeholder, ...restProps } = props;
  const { t } = useTranslation();

  const canSearch = allowSearch !== undefined ? allowSearch : true;
  const placeholderText =
    placeholder !== undefined ? placeholder : t("Choose...");

  return (
    <Select
      {...restProps}
      showSearch={canSearch}
      placeholder={placeholderText}
      optionFilterProp="children"
      allowClear={canSearch}
    >
      {options.map((option) => (
        <Select.Option value={option.value} key={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};
