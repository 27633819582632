/* eslint-disable import/no-anonymous-default-export */
import { Modal } from "antd";

import styles from "./index.module.scss";

interface IModalProps extends React.ComponentProps<typeof Modal> {
  title: string;
  visible: boolean;
  onOk?: () => void;
  onCancel: () => void;
  okText?: string;
  cancelText?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

export default (props: IModalProps) => {
  const {
    title,
    visible,
    onOk,
    onCancel,
    okText,
    cancelText,
    children,
    footer = undefined,
    ...rest
  } = props;

  return (
    <Modal
      title={title}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      footer={footer}
      className={`responsive-modal ${styles.modal}`}
      {...rest}
    >
      {children}
    </Modal>
  );
};
