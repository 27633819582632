import { RequestPayloadDto } from "@models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateDto {
  report?: any;
}
const initialState: initialStateDto = {};
const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    ownerManagement(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    ownerManagementSuccess(state: initialStateDto, action: PayloadAction<any>) {
      state.report = action.payload;
    },
  },
});

// Report Actions
export const reportActions = reportSlice.actions;

// Reducer
const reportReducer = reportSlice.reducer;

export default reportReducer;
