/* eslint-disable import/no-anonymous-default-export */
import { Button } from "antd";

interface IButtonProps extends React.ComponentProps<typeof Button> {
  type?: "primary" | "default" | "link" | "text" | "dashed" | undefined;
  icon?: React.ReactNode;
  danger?: boolean;
  children?: React.ReactNode;
}

export default (props: IButtonProps) => {
  const { type, icon, danger, children, ...rest } = props;

  return (
    <Button
      type={type}
      icon={icon}
      danger={danger}
      {...rest}
      children={children}
    />
  );
};
