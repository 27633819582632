import { AxiosResponse } from "axios";
import { Modal, notification } from "antd";
import { t } from "i18next";
import {
  all,
  call,
  Effect,
  put,
  StrictEffect,
  takeLatest,
} from "redux-saga/effects";

import { API_ENDPOINTS } from "@constants";
import { axiosClient } from "@constants";
import { myEstateActions } from "./estateSlice";
import { loadingActions } from "../../store/loading/loadingSlice";
import {
  MainRequestDto,
  NoteDto,
  RealEstateDto,
  RequestPayloadDto,
  SuccessPayload,
} from "@models";
import { request } from "@/utils";

// Get All My Estates Saga
// function* getMyEstatesSaga({
//   type,
//   payload,
// }: Effect<string, RequestPayloadDto>) {
//   try {
//     yield put(
//       loadingActions.changeRequestLoading({ name: type, inProgress: true })
//     );

//     const response: AxiosResponse<SuccessPayload<RealEstateDto>> = yield call(
//       axiosClient.get,
//       API_ENDPOINTS.GET.realEstate.all,
//       { params: { ...payload.params } }
//     );

//     yield put(myEstateActions.getMyEstatesSuccess({ ...response.data }));
//   } finally {
//     yield put(
//       loadingActions.changeRequestLoading({ name: type, inProgress: false })
//     );
//   }
// }
const getMyEstatesSaga = request({
  APIUrl: API_ENDPOINTS.GET.realEstate.all,
  requestType: "get",
  successFunction: myEstateActions.getMyEstatesSuccess,
});

// Add Estate Saga
function* addEstateSaga({ type, payload }: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<MainRequestDto<any>> = yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstate.add,
      payload.data
    );

    yield put(myEstateActions.addEstateSuccess({ ...response.data }));
    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (err) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (err as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Update Estate Saga
function* updateEstateSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstate.update(payload.id!),
      payload.data
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (err) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (err as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Update Estate Files Saga
const updateFilesSaga = request({
  APIUrl: API_ENDPOINTS.POST.realEstate.update_files,
  requestType: "post",
  showNotification: true,
});

// Get Single Estate Details
function* getEstateDetailsSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<RealEstateDto> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.realEstate.single(Number(payload.data))
    );

    yield put(myEstateActions.getDetailsSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get All Estate Notes
function* getEstateNotesSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<SuccessPayload<NoteDto>> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.realEstate.notes,
      { params: { ...Object(payload?.data) } }
    );

    yield put(myEstateActions.getNotesSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Delete Estate  Saga
function* deleteEstateSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstate.delete(Number(payload.data))
    );
    payload?.callback?.();
    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Delete Estate Note Saga
function* deleteEstateNotesSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstate.deleteNote(Number(payload.data))
    );
    payload?.callback?.();
    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Add Estate Note Saga
function* addEstateNotesSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstate.addNote,
      payload.data
    );
    payload?.callback?.();
    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Update Estate Note Saga
function* updateEstateNotesSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstate.updateNote(Number(payload.id)),
      payload.data
    );
    payload?.callback?.();
    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Change Estate Status
function* changeStatusSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstate.changeStatus(payload.id!),
      payload.data
    );
    payload?.callback?.();
    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Delete Image Estate Saga
function* deleteImageEstateSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstate.deleteImage,
      payload.data
    );
    payload?.callback?.();
    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Check Google Map Point
const checkGoogleMapPointSaga = request({
  APIUrl: API_ENDPOINTS.GET.realEstate.CHECK_POINT,
  requestType: "get",
});

// Advertisemet Validator
function* advertisementValidatorSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<RealEstateDto> = yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstate.advertisement_validator,
      payload.data
    );

    yield put(myEstateActions.advertisementValidatorSuccess({ ...response }));

    payload?.callback?.(response);
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Add Estate Via Validator
const addEstateViaValidatorSaga = request({
  APIUrl: API_ENDPOINTS.POST.realEstate.add_estate_via_validator,
  requestType: "post",
  showNotification: true,
});

export default function* estateSaga(): Generator<StrictEffect> {
  yield all([
    takeLatest(myEstateActions.getMyEstates.type, getMyEstatesSaga),
    takeLatest(myEstateActions.addEstate.type, addEstateSaga),
    takeLatest(myEstateActions.updateEstate.type, updateEstateSaga),
    takeLatest(myEstateActions.updateFiles.type, updateFilesSaga),
    takeLatest(myEstateActions.deleteEstate.type, deleteEstateSaga),
    takeLatest(myEstateActions.getDetails.type, getEstateDetailsSaga),
    takeLatest(myEstateActions.getNotes.type, getEstateNotesSaga),
    takeLatest(myEstateActions.deleteNote.type, deleteEstateNotesSaga),
    takeLatest(myEstateActions.addNote.type, addEstateNotesSaga),
    takeLatest(myEstateActions.updateNote.type, updateEstateNotesSaga),
    takeLatest(myEstateActions.changeStatus.type, changeStatusSaga),
    takeLatest(myEstateActions.deleteImage.type, deleteImageEstateSaga),
    takeLatest(
      myEstateActions.checkGoogleMapPoint.type,
      checkGoogleMapPointSaga
    ),
    takeLatest(
      myEstateActions.advertisementValidator.type,
      advertisementValidatorSaga
    ),
    takeLatest(
      myEstateActions.addEstateViaValidator.type,
      addEstateViaValidatorSaga
    ),
  ]);
}
