import { RequestPayloadDto } from "@/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateDto {
  statement?: any;
}

const initialState: initialStateDto = {};

const statement = createSlice({
  name: "statement",
  initialState,
  reducers: {
    getAccountStatement(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getAccountStatementSuccess(
      state: initialStateDto,
      action: PayloadAction<any>
    ) {
      state.statement = action.payload;
    },
  },
});

// Statement Actions
export const statementActions = statement.actions;

const statementReducer = statement.reducer;

export default statementReducer;
