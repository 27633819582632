// Main Routes
export enum ROUTE {
  HOME = "/",
  INDICATIONS = "/indications",
}

// Auth Routes
export enum AUTH_ROUTE {
  LOGIN = "/login",
  PROFILE = "/profile",
  MESSAGES = "/messages",
  NOTIFICATIONS = "/notifications",
}

// Not Found Routes
export enum NOT_FOUND {
  UNAUTHORIZED = "/unauthorized",
  NOT_FOUND = "*",
}

// Real Estate And Units Routes
export enum REAL_ESTATE_AND_UNITS {
  REAL_ESTATE_AND_UNITS = "/realEstateAndUnits",
  STATISTICS = "/realEstateAndUnits/statistics",
  REAL_ESTATE_GROUPS = "/realEstateAndUnits/realEstateGroups",
  CREATE_REAL_ESTATE_GROUP = "/realEstateAndUnits/realEstateGroups/addEstateGroup",
  UPDATE_REAL_ESTATE_GROUP = "/realEstateAndUnits/realEstateGroups/update/:id",
  REAL_ESTATE_GROUP_DETAILS = "/realEstateAndUnits/realEstateGroups/details/:id",
  REAL_ESTATES = "/realEstateAndUnits/realEstates",
  REAL_ESTATE_SERVICES = "/realEstateAndUnits/realEstateServices",
  ADVERTISING_LICENSE = "/realEstateAndUnits/realEstateServices/advertisingLicense",
  PUBLISHING_AND_LICENSING_ADVERTISING = "/realEstateAndUnits/realEstateServices/publishingAndLicensingAdvertising",
  ADVERTISING_DETAILS = "/realEstateAndUnits/realEstateServices/advertisingDetails",
  CREATE_REAL_ESTATE = "/realEstateAndUnits/realEstates/addEstate",
  ADD_MEDIA = "/realEstateAndUnits/realEstates/addMedia/:id",
  UPDATE_REAL_ESTATE = "/realEstateAndUnits/realEstates/update/:id",
  REAL_ESTATE_DETAILS = "/realEstateAndUnits/realEstates/details/:id",
}

// Contracts Routes
export enum CONTRACTS {
  CONTRACTS = "/contracts",
  RENT_CONTRACTS = "/contracts/rentContracts",
  ADD_RENT_CONTRACTS = "/contracts/rentContracts/addRentContracts",
  RENT_CONTRACTS_DETAILS = "/contracts/rentContracts/details/:id",
  SALES_CONTRACTS = "/contracts/salesContracts",
  ADD_SALES_CONTRACTS = "/contracts/salesContracts/addsalesContract",
  UPDATE_SALES_CONTRACTS = "/contracts/salesContracts/update/:id",
  SALES_CONTRACTS_DETAILS = "/contracts/salesContracts/details/:id",
}

// Financial System Routes
export enum FINANCIAL_SYSTEM {
  FINANCIAL_SYSTEM = "/financialSystem",
  LATEST_BONDS = "/financialSystem/latestBonds",
  RECEIPT = "/financialSystem/receipt",
  RECEIPT_DETAILS = "/financialSystem/receipt/details/:id",
  ADD_RECEIPT = "/financialSystem/receipt/addReceipt",
  BILLS_OF_EXCHANGE = "/financialSystem/billsOfExchange",
  ADD_BILLS_OF_EXCHANGE = "/financialSystem/billsOfExchange/addBill",
  BILLS_OF_EXCHANGE_DETAILS = "/financialSystem/billsOfExchange/details/:id",
  FINANCIAL_STATEMENT = "/financialSystem/financialStatement",
  EXPENSE_MANAGEMENT = "/financialSystem/expenseManagement",
  EXPENSE_MANAGEMENT_DETAILS = "/financialSystem/expenseManagement/details/:id",
  ADD_EXPENSE_MANAGEMENT = "/financialSystem/expenseManagement/addExpenseManagenent",
}

// Reports Routes
export enum REPORTS {
  REPORTS = "/reports",
  PROPERTY_MANAGEMENT_REPORTS = "/reports/propertyManagementReports",
  RECEIVABLES_REPORTS = "/reports/receivablesReports",
  RECEIPT_REPORTS = "/reports/receiptReports",
  COLLECTION_REPORTS = "/reports/collectionReports",
  CONTRACT_AND_UNIT_REPORTS = "/reports/contractAndUnitReports",
  REAL_ESTATE_STATUS_REPORTS = "/reports/realEstateStatusReports",
}

// Customers Routes
export enum CUSTOMERS {
  CUSTOMERS = "/customers",
  ADD_CUSTOMER = "/customers/add-customer",
  UPDATE_CUSTOMER = "/customers/update-customer/:id",
  CUSTOMER_DETAILS = "/customers/details/:id",
}

// Employees Routes
export enum EMPLOYEES {
  EMPLOYEES = "/employees",
  EMPLOYEE_DETAILS = "/employees/details/:emp_name",
}

// Requests Routes
export enum REQUESTS {
  REQUESTS = "/requests",
  RATE_REQUESTS = "/requests/rate-requests",
  RATE_REQUESTS_DETAILS = "/requests/rate-requests/details/:id",
  PREVIEW_REQUESTS = "/requests/preview-requests",
  MARKET_DEMANDS = "/requests/market-demands",
  MARKET_DEMANDS_DETAILS = "/requests/market-demands/details/:id",
}

// Subscriptions Routes
export enum SUBSCRIPTIONS {
  SUBSCRIPTIONS = "/subscriptions",
  PLANS = "/subscriptions/plans",
}

export interface RouteDto {
  path: string;
  roles: string[];
  id: string;
  component: JSX.Element;
}
export interface BasicMenuItem {
  title: string;
  icon?: JSX.Element;
  path: string;
  roles?: string[];
}
export type MenuItemChildren = (
  | BasicMenuItem
  | (BasicMenuItem & { children?: BasicMenuItem[] })
  | (BasicMenuItem & {
      children: BasicMenuItem[] & { children?: BasicMenuItem[] }[];
    })
)[];
export interface MenuItem extends BasicMenuItem {
  children?: MenuItemChildren;
}

type TArgs =
  | { path: ROUTE.HOME }
  | { path: ROUTE.INDICATIONS }
  | { path: REAL_ESTATE_AND_UNITS.CREATE_REAL_ESTATE }
  | { path: REAL_ESTATE_AND_UNITS.UPDATE_REAL_ESTATE; params: { id: number } }
  | { path: REAL_ESTATE_AND_UNITS.REAL_ESTATES }
  | { path: REAL_ESTATE_AND_UNITS.REAL_ESTATE_DETAILS; params: { id: number } }
  | { path: REAL_ESTATE_AND_UNITS.CREATE_REAL_ESTATE_GROUP }
  | {
      path: REAL_ESTATE_AND_UNITS.UPDATE_REAL_ESTATE_GROUP;
      params: { id: number };
    }
  | { path: REAL_ESTATE_AND_UNITS.REAL_ESTATE_GROUPS }
  | {
      path: REAL_ESTATE_AND_UNITS.REAL_ESTATE_GROUP_DETAILS;
      params: { id: number };
    }
  | { path: CONTRACTS.RENT_CONTRACTS }
  | {
      path: CONTRACTS.ADD_RENT_CONTRACTS;
      query?: { estate_id: number; current_step?: number };
    }
  | { path: CONTRACTS.RENT_CONTRACTS_DETAILS; params: { id: number } }
  | { path: CONTRACTS.SALES_CONTRACTS }
  | { path: CONTRACTS.ADD_SALES_CONTRACTS; query?: { estate_id: number } }
  | { path: CONTRACTS.SALES_CONTRACTS_DETAILS; params: { id: number } }
  | { path: FINANCIAL_SYSTEM.RECEIPT }
  | { path: FINANCIAL_SYSTEM.ADD_RECEIPT }
  | { path: FINANCIAL_SYSTEM.RECEIPT_DETAILS; params: { id: number } }
  | { path: FINANCIAL_SYSTEM.BILLS_OF_EXCHANGE }
  | { path: FINANCIAL_SYSTEM.ADD_BILLS_OF_EXCHANGE }
  | { path: FINANCIAL_SYSTEM.BILLS_OF_EXCHANGE_DETAILS; params: { id: number } }
  | { path: FINANCIAL_SYSTEM.EXPENSE_MANAGEMENT }
  | {
      path: FINANCIAL_SYSTEM.EXPENSE_MANAGEMENT_DETAILS;
      params: { id: number };
    }
  | { path: FINANCIAL_SYSTEM.ADD_EXPENSE_MANAGEMENT }
  | { path: FINANCIAL_SYSTEM.FINANCIAL_STATEMENT }
  | { path: CUSTOMERS.CUSTOMERS }
  | { path: CUSTOMERS.ADD_CUSTOMER }
  | { path: CUSTOMERS.UPDATE_CUSTOMER; params: { id: number } }
  | { path: CUSTOMERS.CUSTOMER_DETAILS; params: { id: number } }
  | { path: EMPLOYEES.EMPLOYEES }
  | { path: EMPLOYEES.EMPLOYEE_DETAILS; params: { emp_name: string } }
  | { path: AUTH_ROUTE.LOGIN; query?: { redirect?: string } }
  | { path: NOT_FOUND.NOT_FOUND }
  | { path: NOT_FOUND.UNAUTHORIZED }
  | { path: REQUESTS.REQUESTS }
  | { path: REQUESTS.RATE_REQUESTS }
  | { path: REQUESTS.PREVIEW_REQUESTS }
  | { path: REQUESTS.MARKET_DEMANDS }
  | { path: REQUESTS.RATE_REQUESTS_DETAILS; params: { id: number } }
  | { path: AUTH_ROUTE.PROFILE }
  | { path: AUTH_ROUTE.MESSAGES }
  | { path: AUTH_ROUTE.NOTIFICATIONS }
  | { path: REQUESTS.MARKET_DEMANDS_DETAILS; params: { id: number } }
  | { path: CONTRACTS.UPDATE_SALES_CONTRACTS; params: { id: number } }
  | { path: REAL_ESTATE_AND_UNITS.REAL_ESTATE_SERVICES }
  | { path: REAL_ESTATE_AND_UNITS.ADVERTISING_LICENSE }
  | { path: REAL_ESTATE_AND_UNITS.PUBLISHING_AND_LICENSING_ADVERTISING }
  | { path: SUBSCRIPTIONS.PLANS }
  | { path: SUBSCRIPTIONS.SUBSCRIPTIONS }
  | { path: REAL_ESTATE_AND_UNITS.ADVERTISING_DETAILS }
  | { path: REAL_ESTATE_AND_UNITS.ADD_MEDIA; params: { id: number } }
  | { path: REPORTS.REPORTS }
  | { path: REPORTS.PROPERTY_MANAGEMENT_REPORTS }
  | { path: REPORTS.RECEIVABLES_REPORTS }
  | { path: REPORTS.RECEIPT_REPORTS }
  | { path: REPORTS.COLLECTION_REPORTS }
  | { path: REPORTS.CONTRACT_AND_UNIT_REPORTS }
  | { path: REPORTS.REAL_ESTATE_STATUS_REPORTS };

type TArgsWithParamsAndQueries = Extract<
  TArgs,
  { path: string; params: any; query: any }
>;

export function createPath(args: TArgs) {
  // Save some CPU power for routes without params
  let url: string = args.path;

  if (args.hasOwnProperty("params")) {
    // Create a path by replacing params in the route definition
    url = Object.entries((args as TArgsWithParamsAndQueries)["params"]).reduce(
      (previousValue: string, [param, value]) =>
        previousValue.replace(`:${param}`, "" + value),
      args.path
    );
  }

  // Add query params If Exists
  if (args.hasOwnProperty("query")) {
    const query = Object.entries(
      (args as TArgsWithParamsAndQueries)["query"]
    ).reduce(
      (previousValue: string, [param, value]) =>
        previousValue + `${param}=${value}&`,
      ""
    );
    return `${url}?${query}`;
  }

  return url;
}
