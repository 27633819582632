import { StrictEffect, all, takeLatest } from "redux-saga/effects";

import { rateActions } from "./rateSlice";
import { request } from "@/utils";
import { API_ENDPOINTS } from "@/constants";

const {
  getRateRequests,
  getRateRequestsSuccess,
  deleteRateRequest,
  getSingleRateRequest,
  getSingleRateRequestSuccess,
} = rateActions;

const getRateRequestsSaga = request({
  APIUrl: API_ENDPOINTS.GET.REQUESTS.ALL_RATE_REQUESTS,
  requestType: "get",
  successFunction: getRateRequestsSuccess,
});

const getSingleRateRequestSaga = request({
  APIUrl: API_ENDPOINTS.GET.REQUESTS.SINGLE_RATE_REQUEST,
  requestType: "get",
  successFunction: getSingleRateRequestSuccess,
});

const deleteRateRequestSaga = request({
  APIUrl: API_ENDPOINTS.POST.REQUESTS.DELETE_RATE_REQUEST,
  requestType: "post",
  showNotification: true,
});

export default function* rateSaga(): Generator<StrictEffect> {
  yield all([
    takeLatest(getRateRequests.type, getRateRequestsSaga),
    takeLatest(getSingleRateRequest.type, getSingleRateRequestSaga),
    takeLatest(deleteRateRequest.type, deleteRateRequestSaga),
  ]);
}
