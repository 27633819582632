import { unionSN } from "./common";

export enum FILE_TYPE {
  IMAGE = "images",
  VIDEO = "videos",
}
interface EstateFileDto {
  created_at: string;
  deleted_at: string;
  estate_id: number;
  file: string;
  id: number;
  type: FILE_TYPE;
  updated_at: string;
}

interface EstateIncludeNumberDto {
  count: string;
  img: string;
  name: string;
}

export interface RealEstateDto {
  creation_date: string;
  end_date: string;
  include_number: Array<EstateIncludeNumberDto>;
  additional_code: string;
  postal_code: string;
  id: unionSN;
  status: string;
  estate_file: Array<EstateFileDto>;
  operation_type_id: number;
  owner_estate_name: string;
  owner_estate_mobile: unionSN;
  operation_type_name: string;
  estate_type_id: number;
  estate_status: string;
  estate_type_name: string;
  instrument_number: string;
  instrument_file: unknown;
  instrument_status: string;
  pace_number: unionSN;
  planned_number: unionSN;
  total_area: unionSN;
  estate_age: number;
  floor_number: number;
  street_view: unionSN;
  total_price: unionSN;
  meter_price: unionSN;
  owner_management_commission: unionSN;
  owner_management_commission_type: string;
  lounges_number: number;
  bathrooms_number: number;
  rooms_number: number;
  boards_number: number;
  bedroom_number: number;
  kitchen_number: number;
  dining_rooms_number: number;
  finishing_type: string;
  interface: string;
  social_status: string;
  lat: unionSN;
  lan: unionSN;
  note: string;
  user_id: number;
  is_rent: unionSN;
  rent_type: string;
  is_resident: unionSN;
  is_checked: unionSN;
  is_insured: unionSN;
  exclusive_contract_file: unknown;
  neighborhood_id: number;
  city_id: number;
  exclusive_marketing: unknown;
  video: unknown;
  state_id: unknown;
  estate_use_type: string;
  estate_dimensions: string;
  is_mortgage: unionSN;
  is_obligations: unionSN;
  touching_information: string;
  is_saudi_building_code: unionSN;
  obligations_information: string;
  full_address: string;
  first_image: string;
  rent_price: number;
  is_rent_installment: unionSN;
  rent_installment_price: unknown;
  total_price_number: unionSN;
  total_area_number: unionSN;
  unit_counter: unknown;
  elevators_number: number;
  unit_number: number;
  parking_spaces_numbers: number;
  advertiser_license_number: unionSN;
  bank_id: number;
  bank_name: number;
  guard_name: string;
  guard_mobile: unionSN;
  guard_identity: unionSN;
  building_number: unionSN;
  city_name: string;
  neighborhood_name: string;
  interface_array: Array<string>;
  estate_use_name: string;
  advertiser_side_name: unknown;
  state_name: unknown;
  finishing_type_name: string;
  comfort_names: string;
  owner_birth_day?: string;
  instrument_file_react: any;
  comfort_id?: Array<number>;
  seen_count: number;
  message_count: number;
  message_whatsapp_count: number;
  share_count: number;
  screenshot_count: number;
  location_count: number;
  appearing_count: number;
  favorite_count: number;
}

export interface NoteDto {
  id: number;
  user_name: string;
  user_id: number;
  estate_id: number;
  estate_name: string;
  notes: string;
  created_at: string;
}

export interface EstateComfortDto {
  id: number;
  name: string;
  icon: string;
}
