/* eslint-disable import/no-anonymous-default-export */
import { Tooltip } from "antd";

type ITooltipProps =
  | React.ComponentProps<typeof Tooltip>
  | {
      title: string;
      children: React.ReactNode;
    };

export default (props: ITooltipProps) => {
  const { title, children, ...rest } = props;

  return (
    <Tooltip title={title} {...rest}>
      {children}
    </Tooltip>
  );
};
