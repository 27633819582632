import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Card,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Switch,
  Tag,
  Typography,
} from "antd";

import { Image } from "@components/ui";
import { CloseCircleOutlined, MoreOutlined } from "@ant-design/icons";
import {
  CONTRACTS,
  createPath,
  FINANCIAL_SYSTEM,
  ICardProps,
  REAL_ESTATE_AND_UNITS,
} from "@models";

import styles from "./styles.module.scss";

const { Meta } = Card;

const AqarCard = ({
  isEstateGroup,
  item,
  changeStatus,
  inProgress,
}: ICardProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [status, setStatus] = useState<boolean>(
    item.status === "new" || item.status === "completed"
  );

  // To Get Menu Item Depend On Card Case
  const getMenuItems = (condition?: boolean) => {
    const edit = {
      label: (
        <Link
          to={createPath({
            path: isEstateGroup
              ? REAL_ESTATE_AND_UNITS.UPDATE_REAL_ESTATE_GROUP
              : REAL_ESTATE_AND_UNITS.UPDATE_REAL_ESTATE,
            params: { id: item.id },
          })}
        >
          {t("Edit")}
        </Link>
      ),
      key: "1",
    };
    const other = [
      {
        label: (
          <Link
            to={createPath({
              path: FINANCIAL_SYSTEM.ADD_EXPENSE_MANAGEMENT,
            })}
          >
            {t("AddNewExpense")}
          </Link>
        ),
        key: "2",
      },
      {
        label: (
          <Link
            to={createPath({
              path:
                Number(item?.operation_type_id) === 2
                  ? CONTRACTS.ADD_RENT_CONTRACTS
                  : CONTRACTS.ADD_SALES_CONTRACTS,

              query: { estate_id: item?.id },
            })}
          >
            {t("AddNewContract")}
          </Link>
        ),
        key: "3",
      },
    ];

    if (condition) {
      return [edit];
    }
    return [edit, ...other];
  };

  const menu = (
    <Menu
      // onClick={handleMenuClick}
      items={getMenuItems(isEstateGroup)}
    />
  );

  const getStatus = (key: string) => {
    switch (key) {
      case "new":
        return (
          <Tag className={styles.tags} color="blue">
            {t("UnderReview")}
          </Tag>
        );
      case "completed":
        return (
          <Tag className={styles.tags} color="success">
            {t("Active")}
          </Tag>
        );
      case "expired":
        return (
          <Tag className={styles.tags} color="warning">
            {t("Expired")}
          </Tag>
        );
      case "closed":
        return (
          <Tag
            className={styles["tags"]}
            icon={<CloseCircleOutlined />}
            color="error"
          >
            {t("Rejected")}
          </Tag>
        );

      default:
        return (
          <Tag
            className={`${styles["tags"]} ${styles["unknown"]}`}
            color="default"
          >
            {t("Unknown")}
          </Tag>
        );
    }
  };

  const onStatusChange = (val: boolean) => {
    const formData = new FormData();
    formData.append("status", val ? "new" : "under_edit_publish");

    dispatch(
      changeStatus({
        id: item.id,
        data: formData,
        callback: () => setStatus(val),
      })
    );
  };

  return (
    <Card
      className={`${styles["card-int"]}`}
      cover={
        <Link
          to={createPath({
            path: isEstateGroup
              ? REAL_ESTATE_AND_UNITS.REAL_ESTATE_GROUP_DETAILS
              : REAL_ESTATE_AND_UNITS.REAL_ESTATE_DETAILS,
            params: { id: item?.id },
          })}
        >
          <Image
            src={isEstateGroup ? item?.image : item?.first_image}
            alt={isEstateGroup ? item?.group_name : item?.estate_name}
          />
        </Link>
      }
    >
      <Row gutter={5} justify={"space-between"}>
        <Col xs={12} sm={16} md={12}>
          <Meta
            title={
              <Link
                to={createPath({
                  path: isEstateGroup
                    ? REAL_ESTATE_AND_UNITS.REAL_ESTATE_GROUP_DETAILS
                    : REAL_ESTATE_AND_UNITS.REAL_ESTATE_DETAILS,
                  params: { id: item?.id },
                })}
              >
                <Row gutter={10}>
                  <Col>
                    <Typography.Text
                      className={`${styles.title}`}
                      ellipsis
                      strong
                      title={
                        isEstateGroup ? item?.group_name : item?.estate_name
                      }
                    >
                      {isEstateGroup
                        ? item?.group_name
                        : `${item?.estate_type_name}, ${item?.operation_type_name}`}
                    </Typography.Text>
                  </Col>
                  {!isEstateGroup && (
                    <Col>
                      <Typography.Text
                        className={`${styles.title}`}
                        ellipsis
                        strong
                        type="secondary"
                      >
                        {`${item?.total_price} ${t("SAR")}`}
                      </Typography.Text>
                    </Col>
                  )}
                </Row>
              </Link>
            }
            description={
              <Typography.Paragraph
                className={`${styles.full_address}`}
                ellipsis={{
                  tooltip: item?.full_address,
                  rows: 2,
                }}
                type="secondary"
                style={{ marginBlockEnd: isEstateGroup ? "15px" : 0 }}
              >
                {item?.full_address}
              </Typography.Paragraph>
            }
          />
        </Col>
        {!isEstateGroup && (
          <Col xs={6} sm={4} style={{ textAlign: "end" }} md={9}>
            <Space direction="vertical" align="center">
              {getStatus(item.status)}
              <Space align="baseline">
                <Switch
                  size="small"
                  defaultChecked
                  className={styles["switch"]}
                  onChange={onStatusChange}
                  checked={status}
                  loading={inProgress}
                />
                <p
                  style={{
                    color: status ? "#1ad598" : "#db4646",
                    fontSize: !status ? "11px" : "14px",
                  }}
                >
                  {status ? t("Published") : t("UnPublished")}
                </p>
              </Space>
            </Space>
          </Col>
        )}
        <Col
          xs={6}
          sm={4}
          md={2}
          style={{ textAlign: "end" }}
          // offset={isEstateGroup ? 4 : 1}
        >
          <Dropdown.Button
            overlay={menu}
            placement="bottomLeft"
            icon={<MoreOutlined />}
            className={styles.dropMenu}
          />
          {/* Dropdown
          </Dropdown.Button> */}
        </Col>
      </Row>
      {/* {!isEstateGroup && (
        <div className={`${styles.status} ${styles.stUnpublished}`}>
          <div className={`${styles.unpublished}`}>
            <PoweroffOutlined />
            <span className={`${styles.title}`}>غير منشور</span>
          </div>
          <div className={`${styles.published}`}>
            <CheckCircleOutlined />
            <span className={`${styles.title}`}>منشور</span>
          </div>
        </div>
      )} */}
      <div className={`${styles.number}`}>{`#${item?.id}`}</div>
      {/* <Link
        to={createPath({
          path: isEstateGroup
            ? REAL_ESTATE_AND_UNITS.UPDATE_REAL_ESTATE_GROUP
            : REAL_ESTATE_AND_UNITS.UPDATE_REAL_ESTATE,
          params: { id: Number(item?.id) },
        })}
      >
        <Button type="text" className={`${styles.link}`}>
          <FormOutlined />
        </Button>
      </Link> */}
    </Card>
  );
};

export default AqarCard;
