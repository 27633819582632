import { RequestPayloadDto } from "@/models";
import { API_ENDPOINTS } from "@constants";
import { axiosClient } from "@constants";
import { loadingActions } from "@store/loading/loadingSlice";
import { Modal } from "antd";
import { AxiosResponse } from "axios";
import { t } from "i18next";
import {
  all,
  call,
  Effect,
  put,
  StrictEffect,
  takeLatest,
} from "redux-saga/effects";
import { statementActions } from "./statementSlice";

const { getAccountStatement, getAccountStatementSuccess } = statementActions;

// Get Account Statement
function* getAccountStatementSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.accountStatement.all,
      { params: { ...payload?.params } }
    );
    yield put(getAccountStatementSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

export default function* statementSaga(): Generator<StrictEffect> {
  yield all([takeLatest(getAccountStatement.type, getAccountStatementSaga)]);
}
