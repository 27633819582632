import { EyeOutlined } from "@ant-design/icons";
import { Loader } from "@components/ui";
import { useTypedSelector } from "@hooks";
import { RequestsEnum } from "@models";
import { billActions } from "@pages/ExpenseManagement/billSlice";
import { getSingleRequest } from "@store/loading/loadingSlice";
import { Button, Col, Modal, Row, Tooltip } from "antd";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

interface Props {
  id: number;
}
const ExpenseDetails: FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { inProgress } = useTypedSelector((state) =>
    getSingleRequest(state, RequestsEnum.getSingleBill)
  );
  const { singleBill } = useTypedSelector((state) => state.bills);

  const showModal = () => {
    dispatch(
      billActions.getSingleBill({
        data: id,
      })
    );
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip placement="top" title={t("Details")}>
        <Button
          className="border-0 link-primary"
          icon={<EyeOutlined />}
          onClick={showModal}
        />
      </Tooltip>
      <Modal
        title={t("ExpenseDetails")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
      >
        {inProgress ? (
          <Loader isCard />
        ) : (
          <Row gutter={10}>
            <Col sm={24}>
              <Row>
                <Col sm={8} xs={12}>
                  <h4>{`${t("CustomerName")}:`}</h4>
                </Col>
                <Col sm={16} xs={12}>
                  <h4 className="mb-15 text-primary">{singleBill?.user_id}</h4>
                </Col>
              </Row>
            </Col>
            <Col sm={24}>
              <Row>
                <Col sm={8} xs={12}>
                  <h4>{`${t("Date")}:`}</h4>
                </Col>
                <Col sm={16} xs={12}>
                  <h4>{singleBill?.due_date}</h4>
                </Col>
              </Row>
            </Col>
            <Col sm={24}>
              <Row>
                <Col sm={8} xs={12}>
                  <h4>{`${t("BondType")}:`}</h4>
                </Col>
                <Col sm={16} xs={12}>
                  <h4>{singleBill?.type_name} </h4>
                </Col>
              </Row>
            </Col>
            <Col sm={24}>
              <Row>
                <Col sm={8} xs={12}>
                  <h4>{`${t("Amount")}:`}</h4>
                </Col>
                <Col sm={16} xs={12}>
                  <h4>{singleBill?.price}</h4>
                </Col>
              </Row>
            </Col>
            {/* TODO: Theres Not Key */}
            <Col sm={24}>
              <Row>
                <Col sm={8} xs={12}>
                  <h4>{`${t("PeriodFrom")}:`}</h4>
                </Col>
                <Col sm={16} xs={12}>
                  <h4>12-2-2021 </h4>
                </Col>
              </Row>
            </Col>
            {/* TODO: Theres Not Key */}
            <Col sm={24}>
              <Row>
                <Col sm={8} xs={12}>
                  <h4>{`${t("PeriodTo")}:`}</h4>
                </Col>
                <Col sm={16} xs={12}>
                  <h4>12-2-2021</h4>
                </Col>
              </Row>
            </Col>
            {!!singleBill?.rent_contract_id && (
              <Col sm={24}>
                <Row>
                  <Col sm={8} xs={12}>
                    <h4>{`${t("ContractNumber")}:`}</h4>
                  </Col>
                  <Col sm={16} xs={12}>
                    <h4>{singleBill?.rent_contract_id}</h4>
                  </Col>
                </Row>
              </Col>
            )}
            <Col sm={24}>
              <Row>
                <Col sm={8} xs={12}>
                  <h4>{`${t("Statement")}:`}</h4>
                </Col>
                <Col sm={16} xs={12}>
                  <h4>{singleBill?.statement_note}</h4>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default ExpenseDetails;
