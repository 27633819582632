import { API_ENDPOINTS } from "@constants";
import { RequestPayloadDto } from "@models";
import { axiosClient } from "@constants";
import { loadingActions } from "@store/loading/loadingSlice";
import { Modal, notification } from "antd";
import { AxiosResponse } from "axios";
import { t } from "i18next";
import { all, call, Effect, put, takeLatest } from "redux-saga/effects";
import { employeesActions } from "./empSlice";

const { addEmployee, deleteEmployee, getEmployee, getEmployeeSuccess } =
  employeesActions;

// Add Employee Saga
function* addEmployeeSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.employees.add,
      payload.data
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Delete Employee Saga
function* deleteEmployeeSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.employees.delete,
      payload.data
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get All Or Single Employee Saga
function* getEmployeeSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const res: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.employees.all,
      { params: { ...payload.params } }
    );

    yield put(getEmployeeSuccess({ ...res.data.data }));

    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

export default function* empSaga() {
  yield all([
    takeLatest(addEmployee.type, addEmployeeSaga),
    takeLatest(deleteEmployee.type, deleteEmployeeSaga),
    takeLatest(getEmployee.type, getEmployeeSaga),
  ]);
}
