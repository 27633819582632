import { RequestPayloadDto } from "@models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateDto {
  employees?: any;
}

const initialState: initialStateDto = {};

const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    addEmployee(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    deleteEmployee(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getEmployee(
      _state: initialStateDto,
      _action: PayloadAction<RequestPayloadDto>
    ) {},
    getEmployeeSuccess(
      state: initialStateDto,
      action: PayloadAction<RequestPayloadDto>
    ) {
      state.employees = action.payload;
    },
  },
});

// Emloyees Actions
export const employeesActions = employeesSlice.actions;

// Employees Reducer
const employeesReducer = employeesSlice.reducer;

export default employeesReducer;
