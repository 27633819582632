import { StrictEffect, all, takeLatest } from "redux-saga/effects";

import { planActions } from "./planSlice";
import { request } from "@/utils";
import { API_ENDPOINTS } from "@/constants";

const {
  getPlanRequests,
  getPlanRequestsSuccess,
  checkPlan,
  subscribePlan,
  getSubscribtionList,
  getSubscribtionListSuccess,
  getSubscribtionDetails,
  getSubscribtionDetailsSuccess,
  payForLicense,
  payForLicenseSuccess,
} = planActions;

const getPlanRequestsSaga = request({
  APIUrl: API_ENDPOINTS.GET.SUBSCRIPTION.ALL_PLANS,
  requestType: "get",
  successFunction: getPlanRequestsSuccess,
});

const checkPlanSaga = request({
  APIUrl: API_ENDPOINTS.GET.SUBSCRIPTION.CHECK_PLAN,
  requestType: "post",
});

const subscribePlanSaga = request({
  APIUrl: API_ENDPOINTS.GET.SUBSCRIPTION.SUBSCRIBE_PLAN,
  requestType: "post",
});

const getSubscribtionListSaga = request({
  APIUrl: API_ENDPOINTS.GET.SUBSCRIPTION.SUBSCRIBTION_LIST,
  requestType: "get",
  successFunction: getSubscribtionListSuccess,
});

const getSubscribtionDetailsSaga = request({
  APIUrl: API_ENDPOINTS.GET.SUBSCRIPTION.SUBSCRIBTION_DETAILS,
  requestType: "get",
  successFunction: getSubscribtionDetailsSuccess,
});

const payForLicenseSaga = request({
  APIUrl: API_ENDPOINTS.POST.PLANS.PAY_FOR_LICESNS,
  requestType: "post",
  successFunction: payForLicenseSuccess,
});

export default function* planSaga(): Generator<StrictEffect> {
  yield all([
    takeLatest(getPlanRequests.type, getPlanRequestsSaga),
    takeLatest(checkPlan.type, checkPlanSaga),
    takeLatest(subscribePlan.type, subscribePlanSaga),
    takeLatest(getSubscribtionList.type, getSubscribtionListSaga),
    takeLatest(getSubscribtionDetails.type, getSubscribtionDetailsSaga),
    takeLatest(payForLicense.type, payForLicenseSaga),
  ]);
}
