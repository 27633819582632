import { useCallback, useEffect, useState, FC } from "react";
import { ImageProps } from "@models";
import { errorImage, laodingImage } from "@constants";

export default ({
  src,
  placeholderImg = laodingImage,
  errorImg = errorImage,
  loading = "eager",
  ...props
}: ImageProps) => {
  const [imgSrc, setSrc] = useState(placeholderImg || src);

  const onLoad = useCallback(() => {
    setSrc(src);
  }, [src]);

  const onError = useCallback(() => {
    setSrc(errorImg || placeholderImg);
  }, [errorImg, placeholderImg]);

  useEffect(() => {
    const img = new Image();
    img.src = src as string;
    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
    return () => {
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    };
  }, [src, onLoad, onError]);

  return <img {...props} alt={imgSrc} loading={loading} src={imgSrc} />;
};
