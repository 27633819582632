import { FC } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

interface SectionTitleProps {
  title: string;
}

const SectionTitle: FC<SectionTitleProps> = ({ title }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={30}>
      <Col span={24}>
        <div className="d-flex align-items-center bg-light-blue mb-20">
          <span className="bullet"></span>
          <span>{t(title)}</span>
        </div>
      </Col>
    </Row>
  );
};

export default SectionTitle;
