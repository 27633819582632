import { all } from "redux-saga/effects";
import authSaga from "@pages/User/authSaga";
import settingSaga from "./settings/settingSaga";
import estateSaga from "@pages/RealEstates/estateSaga";
import estatesGroupSaga from "@pages/RealEstateGroups/estateGroupSaga";
import rentContractSaga from "@pages/RentContracts/rentContractSaga";
import customersSaga from "@pages/Customers/cutomerSaga";
import salesContractSaga from "@pages/SalesContracts/salesContractSaga";
import billSaga from "@pages/ExpenseManagement/billSaga";
import empSaga from "@pages/EmployeesManagement/empSaga";
import catchBondSaga from "./catchBond/receiptSaga";
import statementSaga from "@pages/FinancialStatement/statementSaga";
import reportSaga from "./reports/reportSaga";
import rateSaga from "@/pages/RateRequests/rateSaga";
import previewSaga from "@/pages/PreviewRequests/previewSaga";
import marketSaga from "@/pages/MarketDemands/marketSaga";
import planSaga from "@/pages/Plans/planSaga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    estateSaga(),
    settingSaga(),
    estatesGroupSaga(),
    rentContractSaga(),
    customersSaga(),
    salesContractSaga(),
    billSaga(),
    empSaga(),
    catchBondSaga(),
    statementSaga(),
    reportSaga(),
    rateSaga(),
    previewSaga(),
    marketSaga(),
    planSaga(),
  ]);
}
