import { Modal } from "@/components/common";
import { PlusOutlined } from "@ant-design/icons";
import { useTypedSelector } from "@hooks";
import { RequestsEnum } from "@models";
import { billActions } from "@pages/ExpenseManagement/billSlice";
import { getSingleRequest } from "@store/loading/loadingSlice";
import { settingAction } from "@store/settings/settingSlice";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import moment from "moment";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const { Option } = Select;

interface Props {
  id: number;
  btnText: string;
  isContract?: boolean;
}

const CreateOrUpdateExpense: FC<Props> = ({ id, btnText, isContract }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { inProgress } = useTypedSelector((state) =>
    getSingleRequest(state, RequestsEnum.addBill)
  );

  const { estates } = useTypedSelector((state) => state.settings);

  const showModal = () => {
    setIsModalVisible(true);
    if (isContract) {
      dispatch(
        settingAction.getEstates({
          params: { type: "rent" },
        })
      );
    }
  };

  const handleOk = () => {
    let values = { ...form.getFieldsValue() };
    if (!isContract) {
      values = { ...values, estate_id: id };
    } else {
      values = { ...values, rent_contract_id: id };
    }
    const formData = new FormData();

    Object.keys(values).forEach((element) => {
      if ((values[element] !== undefined || null) && values[element] !== "") {
        if (typeof values[element] === "object") {
          formData.append(
            element,
            moment(values[element]).format("YYYY-MM-DD")
          );
        } else {
          formData.append(element, values[element]);
        }
      }
    });

    dispatch(
      billActions.addBill({
        data: formData,
        callback: () => {
          form.resetFields();
          setIsModalVisible(false);
          if (!isContract)
            return dispatch(
              billActions.getAllBills({
                params: { estate_id: id },
              })
            );

          return dispatch(billActions.getAllBills({}));
        },
      })
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
        {btnText}
      </Button>
      <Modal
        title={t("AddNewExpense")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ loading: inProgress }}
      >
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          form={form}
          colon={false}
        >
          <Row gutter={30} className="mb-20">
            {isContract && (
              <Col lg={24}>
                <Form.Item
                  label={t("EstateType")}
                  name="estate_id"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Select>
                    {!!estates &&
                      Object.values(estates)?.map((item: any) => (
                        <Option key={item.id} value={item.id}>
                          {item.estate_name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col lg={24}>
              <Form.Item
                label={t("DueDate")}
                name="due_date"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item
                label={t("ExpenseType")}
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Select
                  placeholder={t("Choose...")}
                  size="large"
                  style={{ width: "100%" }}
                >
                  <Option value="rent_payment">{t("RentPayment")}</Option>
                  <Option value="electricity_expenses">
                    {t("ElectricityExpenses")}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item
                label={t("Price")}
                name="price"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  placeholder={t("WriteHere...")}
                  style={{ width: "100%" }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item label={t("Statement")} name="statement_note">
                <Input.TextArea size="large" placeholder={t("WriteHere...")} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateOrUpdateExpense;
