import { FC, useEffect } from "react";
import { Col, Row, Typography } from "antd";
import fitty from "fitty";

interface IProps {
  data: {
    key: string;
    value?: string | number | null | undefined | React.ReactNode;
    type?: "text" | "link";
    link?: string;
    copeiable?: boolean;
  }[];
}

const DisplayData: FC<IProps> = ({ data }) => {
  useEffect(() => {
    fitty(".fitty", {
      minSize: 8,
      maxSize: 15,
    });
  }, []);

  return (
    <Row gutter={[30, 10]}>
      {data.map(
        ({ key, value, link, copeiable = false, type = "text" }) =>
          !!value && (
            <Col key={key} span={12}>
              <Row>
                <Col span={12}>
                  <Typography.Text className="fitty">{`${key} : `}</Typography.Text>
                </Col>
                <Col span={12}>
                  {type === "text" ? (
                    <Typography.Text
                      ellipsis={{ tooltip: copeiable ? null : value }}
                      copyable={copeiable}
                    >
                      {value}
                    </Typography.Text>
                  ) : (
                    <Typography.Link href={link} target="_blank">
                      {value}
                    </Typography.Link>
                  )}
                </Col>
              </Row>
            </Col>
          )
      )}
    </Row>
  );
};

export default DisplayData;
