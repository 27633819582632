import { all, call, Effect, put, takeLatest } from "redux-saga/effects";
import { estateGroupActions } from "./estateGroupSlice";
import { loadingActions } from "@store/loading/loadingSlice";
import { AxiosResponse } from "axios";
import { NoteDto, RequestPayloadDto, SuccessPayload } from "@models";
import { axiosClient } from "@constants";
import { API_ENDPOINTS } from "@constants";
import { RealEstateGroupDto } from "@models/realEstateGroups";
import { t } from "i18next";
import { Modal, notification } from "antd";

const {
  getEstateGroup,
  getEstateGroupSuccess,
  addEstateGroup,
  updateEstateGroup,
  getDetails,
  getDetailsSuccess,
  addNote,
  updateNote,
  deleteEstateGroup,
  deleteNote,
  getNotes,
  getNotesSuccess,
} = estateGroupActions;

// Get All Estate Groups
function* getEstateGroupsSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<SuccessPayload<RealEstateGroupDto>> =
      yield call(axiosClient.get, API_ENDPOINTS.GET.realEstateGroup.all, {
        params: { ...payload.params },
      });

    yield put(getEstateGroupSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Add Estate Group
function* addEstateGroupSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstateGroup.add,
      payload.data
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });

    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Update Estate Group
function* updateEstateGroupSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstateGroup.update(payload.id!),
      payload.data
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });

    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Single Estate Group Details
function* getEstateGroupDetailsSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<RealEstateGroupDto> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.realEstateGroup.single(Number(payload.data))
    );

    yield put(getDetailsSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Delete Estate Group Saga
function* deleteEstateGroupSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstateGroup.delete(Number(payload.data))
    );
    payload?.callback?.();
    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get All Estate Group Notes
function* getEstateNotesSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<SuccessPayload<NoteDto>> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.realEstateGroup.notes,
      { params: { ...Object(payload.data) } }
    );

    yield put(getNotesSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Delete Estate Group Note Saga
function* deleteEstateNotesSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstateGroup.deleteNote(Number(payload.data))
    );
    payload?.callback?.();
    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Add Estate Group Note Saga
function* addEstateNotesSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstateGroup.addNote,
      payload.data
    );
    payload?.callback?.();
    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Update Estate Group Note Saga
function* updateEstateNotesSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.realEstateGroup.updateNote(Number(payload.id)),
      payload.data
    );
    payload?.callback?.();
    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

export default function* estateGroupsSaga() {
  yield all([
    takeLatest(getEstateGroup.type, getEstateGroupsSaga),
    takeLatest(addEstateGroup.type, addEstateGroupSaga),
    takeLatest(updateEstateGroup.type, updateEstateGroupSaga),
    takeLatest(deleteEstateGroup.type, deleteEstateGroupSaga),
    takeLatest(getDetails.type, getEstateGroupDetailsSaga),
    takeLatest(getNotes.type, getEstateNotesSaga),
    takeLatest(deleteNote.type, deleteEstateNotesSaga),
    takeLatest(addNote.type, addEstateNotesSaga),
    takeLatest(updateNote.type, updateEstateNotesSaga),
  ]);
}
