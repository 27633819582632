import { ThemeConfig, theme as antdTheme } from "antd";

export const theme: ThemeConfig = {
  components: {},
  token: {
    colorPrimary: "#5840BB",
    fontFamily: "din-next-lt-medium",
    colorLink: "#5840BB",
    colorInfo: "#5840BB",
    colorInfoText: "#5840BB",
    colorPrimaryText: "#5840BB",
  },
};
