export const API_ENDPOINTS = {
  BASE: process.env.REACT_APP_BASE_URL,
  GET: {
    settings: {
      IamLogin: `IAM/login`,
      notifications: `notification`,
      MESSAGES: `my/msg`,
      SINGLE_MESSAGE: (id: number) => `msg/${id}/det`,
      banks: `banks`,
      estateType: `estate/type`,
      operationType: `operation/type`,
      comfort: `comfort`,
      customerByType: `tenants/paying/users/list`,
      estates: `estate/select`,
      groupEstates: `group/select`,
      contractsSelect: `contract/select`,
      employeesSelect: `emp/select`,
      contractExpenseSelect: `contract/exprensess/select`,
      contractInvoiceSelect: `contract/invoices/select`,
      dashboard: `dashboard`,
      advertisement_type: `advertisement_type`,
      property_usages: `property_usages`,
      property_age: `property_age`,
      property_face: `property_face`,
      regions: `regions`,
      city_by_region: (id: number) => `region/${id}/cities`,
      districts_by_city: (id: number) => `city/${id}/districts`,
      // Get Google Map Address API
      googleAddress: ({ lng, lat }: { lng: number; lat: number }) =>
        `https://nominatim.openstreetmap.org/reverse?format=json&zoom=18&addressdetails=1&lat=${lat}&lon=${lng}`,
      EJAR: {
        CITIES: (id: number) => `ejar/region/${id}/cities`,
        REGIONS: `ejar/regions`,
        DISTRICTS: (id: number) => `ejar/cities/${id}/districts`,
      },
    },

    realEstate: {
      all: `my/estate`,
      single: (id: number) => `single/${id}/estate`,
      notes: `all/estate/notes`,
      CHECK_POINT: `check/point`,
    },

    realEstateGroup: {
      all: `all/estate/group`,
      single: (id: number) => `single/${id}/group/estate`,
      notes: `all/estate/group/notes`,
    },

    rentContract: {
      all: `all/rent/contract`,
      single: (id: number) => `single/${id}/rent/contract`,
      notes: `all/rent/contract/notes`,
    },

    salesContract: {
      all: `all/payer/contract`,
      single: (id: number) => `single/${id}/payer/contract`,
      notes: `all/pay/contract/notes`,
    },

    accountStatement: {
      all: `all/account/statement`,
    },

    catchBond: {
      all: `all/catch/bond`,
      single: (id: number) => `single/${id}/catch/bond`,
    },

    expense: {
      all: `all/estate/expense`,
      single: (id: number) => `single/${id}/estate/expense`,
    },

    customer: {
      all: `all/tenants/paying/users`,
      single: (id: number) => `single/${id}/tenants/paying/users`,
      singleNote: (id: number) => `single/${id}/tenants/paying/user/notes`,
      notes: `all/tenants/paying/user/notes`,
    },

    employees: {
      all: `my/employee`,
    },

    reports: {
      ownerManagement: `owner/management/report`,
    },
    SUBSCRIPTION: {
      ALL_PLANS: `plan`,
      CHECK_PLAN: `check_plan`,
      SUBSCRIBE_PLAN: `subscription`,
      SUBSCRIBTION_LIST: `my_subscription`,
      SUBSCRIBTION_DETAILS: `subscription_details`,
    },

    REQUESTS: {
      ALL_RATE_REQUESTS: `show_rate_Request`,
      ALL_PREVIEW_REQUESTS: `estate_request_preview`,
      PREVIEW_REQUESTS_BY_ID: (id: number) => `estate_request_preview/${id}`,
      ALL_MARKET_DEMANDS: `market/demands`,
      SINGLE_RATE_REQUEST: (id: number) => `show_rate_Request/${id}`,
      SHOW_ORDER: `show_order`,
    },

    getSingleEstateExpense: (id: number) => `single/${id}/estate/expense`,
    allEstateExpense: `all/estate/expense`,
    getSingleNote: (id: number) => `single/${id}/estate/notes`,

    getSingleGroupNote: (id: number) => `single/${id}/estate/group/notes`,

    getSingleSalesContractNote: (id: number) =>
      `single/${id}/pay/contract/notes`,
  },
  POST: {
    settings: {
      SEND_MESSAGE: `send/msg`,
      refreshFirebaseToken: `refresh_fcm_token`,
    },
    realEstate: {
      add: `add/estate`,
      update: (id: number) => `update/${id}/estate`,
      update_files: (id: number) => `estate/${id}/upload-photos`,
      delete: (id: number) => `delete/${id}/estate`,
      addNote: `add/estate/notes`,
      updateNote: (id: number) => `update/${id}/estate/notes`,
      deleteNote: (id: number) => `delete/${id}/estate/notes`,
      changeStatus: (id: number) => `estate/${id}/status`,
      deleteImage: `deleteImg/estate`,
      advertisement_validator: `https://api2.aqarz.sa/advertisement_validator_v2.php`,
      add_estate_via_validator: `add/estate-via-validator`,
    },

    realEstateGroup: {
      add: `add/estate/group`,
      update: (id: number) => `update/${id}/estate/group`,
      delete: (id: number) => `delete/${id}/estate/group`,
      addNote: `add/estate/group/notes`,
      updateNote: (id: number) => `update/${id}/estate/group/notes`,
      deleteNote: (id: number) => `delete/${id}/estate/group/notes`,
    },

    rentContract: {
      add: `add/rent/contract`,
      update: (id: number) => `update/${id}/rent/contract`,
      addNote: `add/rent/contract/notes`,
      updateNote: (id: number) => `update/${id}/rent/contract/notes`,
      deleteNote: (id: number) => `delete/${id}/rent/contract/notes`,
      addInvoice: `collect/rent/contract/invoice`,
    },

    salesContract: {
      add: `add/payer/contract`,
      update: (id: number) => `update/${id}/payer/contract`,
      delete: (id: number) => `delete/${id}/payer/contract`,
      addNote: `add/pay/contract/notes`,
      updateNote: (id: number) => `update/${id}/pay/contract/notes`,
      deleteNote: (id: number) => `delete/${id}/pay/contract/notes`,
    },

    catchBond: {
      add: `add/catch/bond`,
      delete: (id: number) => `delete/${id}/catch/bond`,
    },

    expense: {
      add: `add/estate/expense`,
      delete: (id: number) => `delete/${id}/estate/expense`,
    },

    customer: {
      add: `add/tenants/paying/users`,
      update: (id: number) => `update/${id}/tenants/paying/users`,
      delete: (id: number) => `delete/${id}/tenants/paying/users`,
      addNote: `add/tenants/paying/user/notes`,
      updateNote: (id: number) => `update/${id}/tenants/paying/user/notes`,
      deleteNote: (id: number) => `delete/${id}/tenants/paying/user/notes`,
    },

    employees: {
      add: `add/employee`,
      delete: `delete/employee`,
    },

    auth: {
      login: `login`,
      verify: `verify`,
      resetPass: `reset/password`,
      updatePass: `update/password`,
      forgetPass: `forget/password`,
    },

    EJAR: {
      INIVIDUAL_ENTITIES: `individual_entities/find_or_create`,
      ORGANIZATION_ENTITIES: `organization_entities/get_organization`,

      ENTITY_ENDPOINT: `entity_endpoints`,
      ADD_PROPERTIES: `ejar/add_properties`,
      ADD_UNIT: `ejar/add_unit`,
      ADD_CONTRACT: `ejar/add_contract`,
      ADD_PROPERTY_AND_UNIT: (id: number | string) =>
        `ejar/add_property_and_unit_contract/${id}`,
      SELECT_PARTIES_CONTRACT: (id: number | string) =>
        `ejar/select_parties_contract/${id}`,
      FINANCIAL_INFORMATION_CONTRACT: (id: number | string) =>
        `ejar/financial_information_contract/${id}`,
      CONTRACT_UNIT_SERVICE: (id: number | string) =>
        `ejar/contract_unit_services_contract/${id}`,
      RENTAL_FEE_CONTRACT: (id: number | string) =>
        `ejar/rental_fee_contract/${id}`,
      CONTRACT_TERMS_CONTRACT: (id: number | string) =>
        `ejar/contract_terms_contract/${id}`,
      SUBMIT_CONTRACT: (id: number | string) => `ejar/submit_contract/${id}`,
    },

    REQUESTS: {
      DELETE_RATE_REQUEST: (id: number) => `delete_rate_Request/${id}`,
      DELETE_PREVIEW_REQUEST: (id: number) => `request_preview/${id}/delete`,
      UPDATE_PREVIEW_REQUEST: (id: number) =>
        `request_preview/${id}/times_update`,
      SELECT_TIME: (id: number) => `request_preview/${id}/times`,
      SEND_OFFER: `send/offer/Request`,
    },

    PLANS: {
      PAY_FOR_LICESNS: `pay-for-license`,
    },

    addEstateExpense: `add/estate/expense`,
    updateEstateExpense: (id: number) => `update/${id}/estate/expense`,
    deleteEstateExpense: (id: number) => `delete/${id}/estate/expense`,
    addImageEstate: `addImg/estate`,
    deleteImageEstate: `deleteImg/estate`,

    updateCustomer: (id: number) => `update/${id}/tenants/paying/users`,

    updateCustomerNote: (id: number) =>
      `update/${id}/tenants/paying/user/notes`,

    updateSalesContract: (id: number) => `update/${id}/payer/contract`,

    updateSalesContractNote: (id: number) => `update/${id}/pay/contract/notes`,

    updateCatchBond: (id: number) => `update/${id}/catch/bond`,
  },
};
