import { Modal, notification } from "antd";
import { AxiosResponse } from "axios";
import { t } from "i18next";
import { all, call, Effect, put, takeLatest } from "redux-saga/effects";

import { API_ENDPOINTS } from "@constants";
import { RequestPayloadDto, SuccessPayload } from "@models";
import { rentContractDto } from "@models/contracts";
import { loadingActions } from "@store/loading/loadingSlice";
import { axiosClient } from "@constants";
import { rentContractActions } from "./rentContractSlice";
import { request } from "@utils";

// Rent Contracts Actions
const {
  getAllRentContract,
  getAllRentContractSuccess,
  addContract,
  getSingleRentContract,
  getSingleRentContractSuccess,
  addRentContractNote,
  updateRentContractNote,
  getRentContractNotes,
  getRentContractNotesSuccess,
  deleteRentContractNote,
  addInvoice,
  checkEjarEntityId,
  checkEjarEntityIdSuccess,
  addEjarProperty,
  addEjarContract,
  addEjarPropertyAndUnit,
  addEjarUnit,
  ejarContractTermsContract,
  ejarContractUnitServices,
  ejarFinancialInformationContract,
  ejarRentalFees,
  ejarSubmitContract,
  selectEjarParties,
  checkEjarOrganizationIdAndEntityId,
} = rentContractActions;

// Get All Rent Contracts Saga
function* getAllRentConractsSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );
    const response: AxiosResponse<SuccessPayload<rentContractDto>> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.rentContract.all,
      { params: { ...payload.params } }
    );
    console.log(
      "🚀 ~ file: rentContractSaga.ts ~ line 41 ~ response",
      response
    );

    yield put(getAllRentContractSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Add Rent Contract Saga
function* addRentConractSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );
    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.rentContract.add,
      payload.data
    );

    payload?.callback?.();

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get Single Rent Contract Saga
function* getSingleContractSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<rentContractDto> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.rentContract.single(Number(payload.data))
    );

    yield put(getSingleRentContractSuccess({ ...response.data }));

    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Add Rent Contract Note
function* addRentContractNoteSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.rentContract.addNote,
      payload.data
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Update Rent Contract Note
function* updateRentContractNoteSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.rentContract.updateNote(Number(payload.id)),
      payload.data
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Get All/Single Rent Contract Notes
function* getAllRentContractNoteSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    const response: AxiosResponse<any> = yield call(
      axiosClient.get,
      API_ENDPOINTS.GET.rentContract.notes,
      { params: { ...Object(payload.data) } }
    );
    yield put(getRentContractNotesSuccess({ ...response.data }));
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Delete Rent Contract Note
function* deleteRentContractNoteSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.rentContract.deleteNote(Number(payload.data))
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

// Add Rent Contract Invoice
function* addRentContractInvoiceSaga({
  type,
  payload,
}: Effect<string, RequestPayloadDto>) {
  try {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: true })
    );

    yield call(
      axiosClient.post,
      API_ENDPOINTS.POST.rentContract.addInvoice,
      payload.data
    );

    notification.success({
      message: `${t("OperationAccomplishedSuccessfully")}`,
    });
    payload?.callback?.();
  } catch (error) {
    Modal.error({
      title: `${t("SomethingWentWrong")}`,
      content: (error as Error)?.message,
    });
  } finally {
    yield put(
      loadingActions.changeRequestLoading({ name: type, inProgress: false })
    );
  }
}

const checkEjarEntityIdSaga = request({
  APIUrl: API_ENDPOINTS.POST.EJAR.INIVIDUAL_ENTITIES,
  requestType: "post",
  successFunction: checkEjarEntityIdSuccess,
  // showNotification: true,
});

const checkEjarOrganizationEntityIdSaga = request({
  APIUrl: API_ENDPOINTS.POST.EJAR.ORGANIZATION_ENTITIES,
  requestType: "post",
  successFunction: checkEjarEntityIdSuccess,
  // showNotification: true,
});

// Ejar Add Properties Saga
const addEjarPropertySaga = request({
  APIUrl: API_ENDPOINTS.POST.EJAR.ADD_PROPERTIES,
  requestType: "post",
});

// Ejar Add Unit Saga
const addEjarUnitSaga = request({
  APIUrl: API_ENDPOINTS.POST.EJAR.ADD_UNIT,
  requestType: "post",
});

// Ejar Add Contract Saga
const addEjarContractSaga = request({
  APIUrl: API_ENDPOINTS.POST.EJAR.ADD_CONTRACT,
  requestType: "post",
});

// Ejar Add Property And Unit Saga
const addEjarPropertyAndUnitSaga = request({
  APIUrl: API_ENDPOINTS.POST.EJAR.ADD_PROPERTY_AND_UNIT,
  requestType: "post",
});

// Ejar Select Parties Saga
const selectEjarPartiesSaga = request({
  APIUrl: API_ENDPOINTS.POST.EJAR.SELECT_PARTIES_CONTRACT,
  requestType: "post",
});

// Ejar Financial Information Contract Saga
const ejarFinancialInformationContractSaga = request({
  APIUrl: API_ENDPOINTS.POST.EJAR.FINANCIAL_INFORMATION_CONTRACT,
  requestType: "post",
});

// Ejar Contract Unit Services Saga
const ejarContractUnitServicesSaga = request({
  APIUrl: API_ENDPOINTS.POST.EJAR.CONTRACT_UNIT_SERVICE,
  requestType: "post",
});

// Ejar Rental Fees Saga
const ejarRentalFeesSaga = request({
  APIUrl: API_ENDPOINTS.POST.EJAR.RENTAL_FEE_CONTRACT,
  requestType: "post",
});

// Ejar Contract Terms Contract Saga
const ejarContractTermsContractSaga = request({
  APIUrl: API_ENDPOINTS.POST.EJAR.CONTRACT_TERMS_CONTRACT,
  requestType: "post",
  showNotification: true,
});

// Ejar Submit Contract Saga
const ejarSubmitContractSaga = request({
  APIUrl: API_ENDPOINTS.POST.EJAR.SUBMIT_CONTRACT,
  requestType: "post",
});

export default function* rentContractSaga() {
  yield all([
    takeLatest(getAllRentContract.type, getAllRentConractsSaga),
    takeLatest(addContract.type, addRentConractSaga),
    takeLatest(getSingleRentContract.type, getSingleContractSaga),
    takeLatest(addRentContractNote.type, addRentContractNoteSaga),
    takeLatest(updateRentContractNote.type, updateRentContractNoteSaga),
    takeLatest(getRentContractNotes.type, getAllRentContractNoteSaga),
    takeLatest(deleteRentContractNote.type, deleteRentContractNoteSaga),
    takeLatest(addInvoice.type, addRentContractInvoiceSaga),
    takeLatest(checkEjarEntityId.type, checkEjarEntityIdSaga),
    takeLatest(addEjarProperty.type, addEjarPropertySaga),
    takeLatest(addEjarUnit.type, addEjarUnitSaga),
    takeLatest(addEjarContract.type, addEjarContractSaga),
    takeLatest(addEjarPropertyAndUnit.type, addEjarPropertyAndUnitSaga),
    takeLatest(selectEjarParties.type, selectEjarPartiesSaga),
    takeLatest(
      ejarFinancialInformationContract.type,
      ejarFinancialInformationContractSaga
    ),
    takeLatest(ejarContractUnitServices.type, ejarContractUnitServicesSaga),
    takeLatest(ejarRentalFees.type, ejarRentalFeesSaga),
    takeLatest(ejarContractTermsContract.type, ejarContractTermsContractSaga),
    takeLatest(ejarSubmitContract.type, ejarSubmitContractSaga),

    takeLatest(
      checkEjarOrganizationIdAndEntityId.type,
      checkEjarOrganizationEntityIdSaga
    ),
  ]);
}
