/* eslint-disable import/no-anonymous-default-export */
import { useState, useEffect } from "react";
import { Upload, UploadProps } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadFile } from "antd/lib/upload/interface";

import Button from "../Button";
import Modal from "../Modal";

import styles from "./index.module.scss";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface IUploadProps extends UploadProps {
  onChange?: (file: any) => void;
  value?: any;
  name?: string;
  listType?: "picture" | "picture-card" | "text";
  accept?: string;
  multiple?: boolean;
  maxCount?: number;
  disabled?: boolean;
  btnText?: string;
  isFiald?: boolean;
  setFileList?: (fileList: UploadFile<any>[]) => void;
  modalWidth?: number | string;
}

export default (props: IUploadProps) => {
  const { t } = useTranslation();
  const {
    onChange,
    value,
    disabled,
    accept,
    multiple,
    maxCount,
    listType,
    btnText = t("Upload"),
    isFiald,
    fileList,
    setFileList,
    modalWidth = 800,
    ...rest
  } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  useEffect(() => {
    if (value) {
      setFileList?.(value);
    }
  }, [value]);

  const checkFileType = (file: any): boolean => {
    return (
      file.type === "video/mp4" ||
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf" ||
      file.type === "audio/mpeg" ||
      file.type === "audio/mp3" ||
      file.type === "audio/wav" ||
      file.type === "audio/ogg"
    );
  };

  const handleCustomRequest = (options: any) => {
    const { onSuccess, onError, file } = options;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result;
      const newFile: any = {
        uid: file.uid,
        name: file.name,
        status: "done",
        url: base64,
      };
      setFileList?.([newFile]);
      onSuccess("ok");
    };
    reader.onerror = (error) => {
      onError(error);
    };
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  return (
    <>
      <Upload
        accept={accept}
        multiple={multiple}
        disabled={disabled}
        fileList={fileList}
        customRequest={handleCustomRequest}
        onChange={({ fileList }) => onChange?.(fileList)}
        maxCount={maxCount}
        listType={listType}
        onPreview={handlePreview}
        className={styles.upload}
        {...rest}
      >
        <Button
          icon={<UploadOutlined />}
          className={`${styles.uploadButton} ${isFiald && styles.isFiald}`}
          disabled={disabled}
        >
          {btnText}
        </Button>
      </Upload>

      {/* Preview Image Modal */}
      <Modal
        visible={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        width={modalWidth}
      >
        <img alt={previewTitle} style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
