import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { message } from "antd";
import qs from "qs";
import { t } from "i18next";

import { API_ENDPOINTS } from "@constants";
import i18n from "../i18n";
import { localStorageHelper } from "@utils/localStorageHelper";

const axiosClient = axios.create({
  baseURL: API_ENDPOINTS.BASE,
  // timeout: 30000,
  timeoutErrorMessage: t("RequestTimeoutError,PleaseTryAgainLater!"),
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": i18n.language,
    os_type: "web",
    v: "v5",
  },
  paramsSerializer(params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

// Interceptors
axiosClient.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    const user = !!localStorageHelper.get("user")
      ? localStorageHelper.get("user")
      : undefined;
    if (user && config!.headers) {
      config!.headers["apitoken"] = `Bearer ${user.api_token}`;
    }
    return config;
  },
  function (error) {
    message.error(error?.message);
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    const { statusText, status, data } = response;
    let result: { list?: Array<any>; data?: any } = {};
    if (typeof data === "object") {
      result = data;
      if (Array.isArray(data)) {
        result.list = data;
      }
    } else {
      result.data = data;
    }

    // Return the error message if the status is 200
    if (
      status === 200 &&
      result?.data?.errors &&
      result?.data?.errors?.length > 0
    ) {
      const newData = {
        success: false,
        statusCode: result?.data?.errors?.[0].status,
        message: result?.data?.errors?.[0].detail?.[i18n.language],
        fields: result?.data?.errors?.[0].meta?.errorDetails,
      };
      return Promise.reject(newData);
    }
    if (status === 200 && result?.data?.error) {
      const newData = {
        success: false,
        statusCode: result?.data?.error?.status,
        message: result?.data?.error,
      };
      return Promise.reject(newData);
    }

    return Promise.resolve({
      success: true,
      message: statusText,
      statusCode: status,
      ...result,
    });
  },
  (error) => {
    const { response, message } = error;
    if (String(message) === "cancel request") {
      return {
        success: false,
      };
    }
    let msg;
    let statusCode;
    let fields;
    if (response && response instanceof Object) {
      const { data, statusText } = response;
      statusCode = response.status;
      msg = data?.message || statusText;
      fields = data?.feedback?.fields ?? data.fields ?? statusText;
    } else {
      statusCode = 600;
      msg = error?.message ?? "Network Error";
    }

    return Promise.reject({
      success: false,
      statusCode,
      message: msg,
      fields,
    });
  }
);
export default axiosClient;
