import { Modal } from "antd";
import { t } from "i18next";

import styles from "./index.module.scss";

const { confirm, success, error, warning, info } = Modal;

interface IConfirmProps {
  type: "confirm" | "success" | "error" | "warning" | "info";
  title?: string;
  content?: string;
  onOk: (
    resolve: (value?: void | PromiseLike<void> | undefined) => void
  ) => void;
  onCancel?: () => void;
}

const Confirm = ({
  type,
  content,
  onCancel,
  onOk,
  title,
  ...rest
}: IConfirmProps) => {
  const config = {
    title: title || t("Delete"),
    content: content || t("AreYouSure,CantBeUndone!"),
    onOk,
    onCancel,
    ...rest,
  };

  switch (type) {
    case "success":
      return success({ ...config });
    case "error":
      return error({ ...config });
    case "warning":
      return warning({ ...config });
    case "info":
      return info({ ...config });
    default:
      return confirm({
        ...config,
        className: styles.confirm,
        okButtonProps: {
          className: styles.okButton,
        },
        onOk() {
          return new Promise<void>(onOk);
        },
      });
  }
};

export default Confirm;
