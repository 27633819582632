import moment from "moment";

export const disabledDate = (key: string, current: any, date?: any) => {
  switch (key) {
    case "from":
      // Can not select days before today and today
      return current && current < moment().endOf("day");
    case "to":
      return current < moment(date, "YYYY-MM-DD").add(1, "d");
  }
};
