/* eslint-disable import/no-anonymous-default-export */
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { AvatarProps } from "antd/lib/avatar/avatar";

type AvatarSize = AvatarProps["size"];
interface IAvatarProps extends React.ComponentProps<typeof Avatar> {
  src: string;
  size?: AvatarSize;
  style?: React.CSSProperties;
  alt?: string;
}

export default (props: IAvatarProps) => {
  const { size, src, alt, ...rest } = props;
  const avatarSize: AvatarSize = size || "large";

  return (
    <Avatar
      size={avatarSize}
      src={src}
      alt={alt}
      icon={<UserOutlined />}
      {...rest}
    />
  );
};
