/* eslint-disable import/no-anonymous-default-export */
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import { useAppParams } from "@hooks";

interface ITableProps extends React.ComponentProps<typeof Table> {
  columns: any;
  dataSource: any;
  current_page: number;
  per_page: number;
  total: number;
}

export default ({
  size,
  bordered,
  current_page,
  per_page,
  total,
  ...rest
}: ITableProps): JSX.Element => {
  const { t } = useTranslation();
  const { handleTableChange, handleTablePagination } = useAppParams({});

  const tableSize: SizeType = size || "small";
  const tableBordered: boolean = bordered || true;

  return (
    <Table
      {...rest}
      bordered={tableBordered}
      size={tableSize}
      onChange={handleTableChange}
      pagination={{
        current: current_page,
        pageSize: per_page,
        total,
        showSizeChanger: true,
        hideOnSinglePage: true,
        showTotal: (total, range) => `${range[1]} ${t("Of")} ${total}`,
        onChange: handleTablePagination,
      }}
    />
  );
};
