import { isArray } from "lodash";
import moment from "moment";

interface Args {
  values: Record<string, any>;
  images?: Record<string, any>[];
  imageKey?: string;
}

export const objectToFormData: (args: Args) => FormData = ({
  values,
  images,
  imageKey,
}) => {
  const formData = new FormData();
  Object.keys(values).forEach((element) => {
    if (
      values[element] !== undefined &&
      values[element] !== null &&
      values[element] !== ""
    ) {
      if (
        typeof values[element] === "object" &&
        moment(values[element]).isValid() &&
        !isArray(values[element])
      ) {
        formData.append(element, moment(values[element]).format("YYYY-MM-DD"));
      } else if (isArray(values[element])) {
        values[element].forEach((item: any) => {
          if (typeof item === "object" && moment(item).isValid()) {
            formData.append(
              `${element}[]`,
              moment(item).format("YYYY-MM-DD hh:mm")
            );
          } else {
            formData.append(`${element}[]`, item);
          }
        });
      } else {
        formData.append(element, values[element]);
      }
    }
  });

  /************************* Handle Images & Videos *************************/
  images?.forEach((img: any) => {
    if (images.length > 0) {
      formData.append(imageKey!, img);
    }
  });

  return formData;
};
