import axios from "axios";
import * as cheerio from "cheerio";

// Scrape data from a website
export const scrapeData = async (url: string) => {
  try {
    // Make a request to the website you want to scrape
    const result = await axios.get(url);

    // Use cheerio to parse the HTML response
    const $ = cheerio.load(result.data);

    // Extract the data you want to scrape
    const data = $(".some-element").text();
    console.log("🚀 ~ file: scraping.ts:15 ~ scrapeData ~ data", data);

    return data;
  } catch (error) {
    console.error(error);
  }
};
