import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RequestPayloadDto } from "@/models";

export interface SubscriptionDto {
  contract_number: number;
  contract_number_used: number;
  duration: number;
  duration_type: string;
  end_time: string;
  id: number;
  payment_id: null;
  plan_id: number;
  price: string;
  start_time: string;
  status: string;
  time: string;
  user_id: number;
}

export interface PlanDto {
  type: "static" | "dynamic";
  color: string;
  contract_number: number;
  duration: number;
  duration_type: string;
  id: number;
  name: string;
  price: number;
  contract_number_used: number;
  end_time: null;
  payment_id: null;
  plan: PlanDto;
  plan_id: number;
  plan_name: string;
  quantity: number;
  start_time: null;
  status: string;
  time: string;
  user_id: number;
}

interface InitialStateDto {
  plans?: PlanDto[];
  subscribtionList?: SubscriptionDto[];
  details?: any;
  license_details?: any;
}

const initialState: InitialStateDto = {};

const planSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    getPlanRequests: (_state, _action: PayloadAction<RequestPayloadDto>) => {},
    getPlanRequestsSuccess: (state, action: PayloadAction<PlanDto[]>) => {
      state.plans = action.payload;
    },

    // Check if the plan is already in
    checkPlan: (_state, _action: PayloadAction<RequestPayloadDto>) => {},

    // Subscribe to a plan
    subscribePlan: (_state, _action: PayloadAction<RequestPayloadDto>) => {},

    // Subscribtion List
    getSubscribtionList: (
      _state,
      _action: PayloadAction<RequestPayloadDto>
    ) => {},
    getSubscribtionListSuccess: (
      state,
      action: PayloadAction<SubscriptionDto[]>
    ) => {
      state.subscribtionList = action.payload;
    },

    // Subscribtion Details
    getSubscribtionDetails: (
      _state,
      _action: PayloadAction<RequestPayloadDto>
    ) => {},
    getSubscribtionDetailsSuccess: (
      state,
      action: PayloadAction<SubscriptionDto>
    ) => {
      state.details = action.payload;
    },

    // Pay For License
    payForLicense: (_state, _action: PayloadAction<RequestPayloadDto>) => {},
    payForLicenseSuccess: (state, action: PayloadAction<any>) => {
      state.license_details = action.payload;
    },
  },
});

export const planActions = planSlice.actions;

export default planSlice.reducer;
