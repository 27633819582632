import { StrictEffect, all, takeLatest } from "redux-saga/effects";
import {
  allMarketRequests,
  allMarketRequestsSuccess,
  getRelatedEstates,
  getRelatedEstatesSuccess,
  sendOffer,
} from "./marketSlice";
import { request } from "@/utils";
import { API_ENDPOINTS } from "@/constants";

const allMarketRequestsSaga = request({
  APIUrl: API_ENDPOINTS.GET.REQUESTS.ALL_MARKET_DEMANDS,
  requestType: "get",
  successFunction: allMarketRequestsSuccess,
});

const getRelatedEstatesSaga = request({
  APIUrl: API_ENDPOINTS.GET.REQUESTS.SHOW_ORDER,
  requestType: "get",
  successFunction: getRelatedEstatesSuccess,
});

const sendOfferSaga = request({
  APIUrl: API_ENDPOINTS.POST.REQUESTS.SEND_OFFER,
  requestType: "post",
});

export default function* marketSaga(): Generator<StrictEffect> {
  yield all([
    takeLatest(allMarketRequests.type, allMarketRequestsSaga),
    takeLatest(getRelatedEstates.type, getRelatedEstatesSaga),
    takeLatest(sendOffer.type, sendOfferSaga),
  ]);
}
